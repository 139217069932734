import React, { useEffect, useState } from 'react';
import { Svg } from 'assets';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { authActions, httpRequestsOnSuccessActions } from 'store';
import { ButtonMain, CheckPassword, InputMain } from 'components';
import { FindError, FindSuccess, Notify, useModal } from 'utils';
import { LogIn } from './logIn';
import { ResetPasswordSuccess } from './resetPasswordSuccess';
import { checkPassword } from './constants';

const ACTION_TYPE = 'RESET_PASSWORD';
export const ResetPassword = ({ token }) => {
    const { openModal, close } = useModal();

    const [showPasswords, setShowPassword] = useState({
        newPassword: false,
        confirmation: false,
    });
    const { handleSubmit, control, setError, watch } = useForm({
        defaultValues: {
            newPassword: '',
            confirmation: '',
        },
    });
    const dispatch = useDispatch();
    const success = FindSuccess(ACTION_TYPE);
    const backError = FindError(ACTION_TYPE);

    useEffect(() => {
        if (backError?.error) {
            Notify(backError?.error);
            close();
        }
    }, [backError]);

    useEffect(() => {
        if (success) {
            openModal(<ResetPasswordSuccess />);
            dispatch(httpRequestsOnSuccessActions.removeSuccess(ACTION_TYPE));
        }
    }, [success]);

    const showingPassword = (path) => {
        setShowPassword((prev) => ({
            ...prev,
            [path]: !prev[path],
        }));
    };

    const onSubmit = (data) => {
        const { newPassword, confirmation } = data;

        if (newPassword === confirmation) {
            const params = {
                token: token,
                newPassword,
                confirmation,
            };
            dispatch(authActions.resetPassword(params));
        } else {
            setError('confirmation', {
                type: 'required',
                message: 'Passwords do not match',
            });
        }
    };

    return (
        <div className="forgot-modal-box">
            <div className="login-svg-block">
                <Svg.ResetLockSvg />
            </div>

            <h3 className="auth-modal-title">Set new password</h3>
            <p className="forgot-modal-desc">Your new password must be different to previously used password.</p>

            <form className="form-box" onSubmit={handleSubmit(onSubmit)}>
                <InputMain
                    name="newPassword"
                    type="password"
                    showPassword={showPasswords.newPassword}
                    control={control}
                    placeholder="Enter your new password"
                    labelHeader="New Password"
                    rules={{
                        required: 'This Field is required',
                    }}
                    onClickIconEnd={() => showingPassword('newPassword')}
                    startAdornment={<Svg.Lock />}
                />

                <InputMain
                    name="confirmation"
                    type="password"
                    showPassword={showPasswords.confirmation}
                    control={control}
                    placeholder="Confirm new password"
                    labelHeader="Confirm Password"
                    rules={{
                        required: 'This Field is required',
                    }}
                    onClickIconEnd={() => showingPassword('confirmation')}
                    startAdornment={<Svg.Lock />}
                />

                <CheckPassword fields={checkPassword} text={watch('newPassword')} isActive={watch('newPassword')} />

                <ButtonMain actionTye={ACTION_TYPE} text="Reset Password" type="submit" />
            </form>

            <button className="beck-to-login-btn" onClick={() => openModal(<LogIn />)}>
                <Svg.ArrowLeftLineSvg />
                <span>Back to log In</span>
            </button>
        </div>
    );
};
