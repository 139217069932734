import React from 'react';
import { ButtonMain } from 'components';
import { LogIn } from './logIn';
import { useModal } from 'utils';
import { Svg } from 'assets';
import { ForgotPassword } from './forgotPassword';

export const CheckEmail = ({ email }) => {
    const { openModal } = useModal();

    const checkIfMail = /mail/.test(email);

    return (
        <div className="forgot-modal-box">
            <div className="login-svg-block">
                <Svg.CheckEmailSvg />
            </div>

            <h3 className="auth-modal-title">Check your email</h3>
            <p className="forgot-modal-desc" style={{ marginBottom: '40px' }}>
                We sent a password reset link to <span className="check-email">{email}</span>
            </p>
            <ButtonMain
                text="Open email app"
                className="login-sign-in-button"
                type="submit"
                onClick={() => window.open(checkIfMail ? 'https://mail.ru/' : 'https://mail.google.com/', '_blank', 'noopener,noreferrer')}
            />

            <div className="dont-receive-block">
                <p className="dont-receive-block-text">Didn&apos;t receive the email?</p>
                <button className="click-resent-btn" onClick={() => openModal(<ForgotPassword />)}>
                    Click to resend
                </button>
            </div>

            <button className="beck-to-login-btn" onClick={() => openModal(<LogIn />)}>
                <Svg.ArrowLeftLineSvg />
                <span>Back to log In</span>
            </button>
        </div>
    );
};
