import * as React from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import PlacesAutocomplete from 'react-places-autocomplete';
import Popper from '@mui/material/Popper';
import axios from 'axios';
import { Svg } from 'assets';
import { authActions } from 'store';
import { FindLoad, Notify, renderLink, renderSearchIcon } from 'utils';
import { Loader, TextRow } from 'components';

const ACTION_TYPE = 'SEARCH';
export const SearchBox = ({ fromLayout }) => {
    const { searchResults } = useSelector((state) => ({
        searchResults: state.auth.searchResults,
    }));
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;
    const [anchorElAddress, setAnchorElAddress] = useState(null);
    const openAddress = Boolean(anchorElAddress);
    const idAddress = openAddress ? 'simple-popper' : undefined;
    const [inputs, setInputs] = useState({ searchField: '', address: '' });
    const [locationLoad, setLoadLocation] = useState(false);
    const load = FindLoad(ACTION_TYPE);
    const [address, setAddress] = useState('');

    const handleNavigate = () => {
        const link =
            inputs?.address?.lat && inputs?.address?.lng && inputs?.address?.address && inputs?.searchField
                ? `?searchField=${inputs?.searchField}&lat=${inputs?.address?.lat}&lng=${inputs?.address?.lng}&address=${inputs?.address?.address}`
                : inputs?.address?.lat && inputs?.address?.lng && inputs?.searchField
                    ? `?searchField=${inputs?.searchField}&lat=${inputs?.address?.lat}&lng=${inputs?.address?.lng}`
                    : inputs?.searchField
                        ? `?searchField=${inputs?.searchField}`
                        : inputs?.address?.lat && inputs?.address?.lng && inputs?.address?.address
                            ? `?address=${inputs?.address?.address}&lat=${inputs?.address?.lat}&lng=${inputs?.address?.lng}`
                            : inputs?.address?.lat && inputs?.address?.lng
                                ? `?lat=${inputs?.address?.lat}&lng=${inputs?.address?.lng}`
                                : '';

        navigate({
            pathname: '/search',
            search: link,
        });
    };
    const handleClick = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
        setAnchorElAddress(null);
    };

    const handleClickAddress = (event) => {
        setAnchorElAddress(anchorElAddress ? null : event.currentTarget);
        setAnchorEl(null);
    };

    const search = (e) => {
        const params = {};
        params[e.target.name] = e.target.value;
        dispatch(authActions.search(params));
        setInputs((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
    };

    const renderCard = (item) => {
        return (
            <a href={renderLink(item)} target={'_blank'} rel='noreferrer' className='searched-card-box-link'>
                <div className='searched-card-box'>
                    <div className='searched-card-image'>{renderSearchIcon(item.type)}</div>
                    <div className='searched-card-info'>
                        <p className='searched-card-title'>
                            <TextRow name={item?.name} textWidth={9} />
                        </p>
                        <p className='searched-card-desc'>
                            <TextRow name={item?.address?.formattedAddress} textWidth={9} />
                        </p>
                    </div>
                </div>
            </a>
        );
    };

    const currentLocation = () => {
        if (navigator.geolocation) {
            setLoadLocation(true);
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    let lat = position.coords.latitude;
                    let lng = position.coords.longitude;
                    const data = `${lat} ${lng}`;
                    axios
                        .post(`/address`, { address: data })
                        .then((res) => {
                            navigate(
                                `/search/?lat=${position.coords.latitude}&lng=${position.coords.longitude}&address=${res?.data?.formattedAddress}`,
                            );
                        })
                        .finally(() => {
                            setLoadLocation(false);
                        });

                    navigate(`/search/?lat=${position.coords.latitude}&lng=${position.coords.longitude}`);
                },
                () => {
                    Notify('Location access denied. Please enable');
                },
            );
        } else {
            Notify('Geolocation is not supported by this browser.');
        }
    };

    const handleChange = (value) => {
        setAddress(value);
        const send = { address: value };
        axios
            .post(`/address`, send)
            .then((res) => {
                const searchBody = {
                    lat: res?.data?.lat + '',
                    lng: res?.data?.lng + '',
                    address: res?.data?.formattedAddress,
                };
                setInputs((prevState) => ({ ...prevState, address: searchBody }));
            })
            .catch((err) => {
                console.log(err, 'err');
            });
    };

    return (
        <div className={`search-box ${fromLayout ? 'search-layout-box' : ''}`}>
            <div className='search-box-wrapper'>

                <div className='search-box-inputs-wrapper'>
                    <Svg.Search className='search-box-icon search-box-search' />

                    <input
                        className='search-box-input'
                        aria-describedby={id}
                        type='text'
                        placeholder={'Search'}
                        name='searchField'
                        onClick={handleClick}
                        onChange={search}
                    />
                </div>

                <div className='search-box-inputs-wrapper'>
                    <Svg.MapPinSvg className='search-box-icon search-box-map' />

                    <PlacesAutocomplete
                        searchOptions={{ types: ['(cities)'], country: ['ca', 'us'] }}
                        value={address}
                        onChange={handleChange}
                        onSelect={handleChange}>
                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                            <div style={{ cursor: 'pointer', width: '100%' }}>
                                <input
                                    style={{ border: 'none' }}
                                    onClick={handleClickAddress}
                                    {...getInputProps({
                                        placeholder: 'Enter Location',
                                    })}
                                />
                                <Popper
                                    id={idAddress}
                                    open={openAddress}
                                    anchorEl={anchorElAddress}
                                    className={'custom-select-paper-box-custom'}
                                    placement='bottom-start'
                                    style={{ position: 'absolute', zIndex: 1400 }}>
                                    <div className='search-by-name-wrapper' style={{ marginTop: '18px' }}>
                                        <button onClick={currentLocation} className='current-location-btn'>
                                            <div>{locationLoad ? <Loader color={'#CD303B'} /> :
                                                <Svg.CurrentLocation />}</div>
                                            Current Location
                                        </button>

                                        <div className='suggestion-item'>
                                            {loading && <div>Loading...</div>}
                                            {suggestions.map((suggestion, index) => {
                                                return (
                                                    <div key={index} {...getSuggestionItemProps(suggestion)}>
                                                        <div className='suggestion-text'>
                                                            <span>{suggestion.description}</span>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                </Popper>
                            </div>
                        )}
                    </PlacesAutocomplete>
                </div>

            </div>

            <button onClick={handleNavigate} className='search-button-box'>
                <Svg.ArrowUpRight />
            </button>

            <Popper
                id={id}
                open={open}
                anchorEl={anchorEl}
                className={'custom-select-paper-box-custom'}
                placement='bottom-start'
                style={{ position: 'absolute', zIndex: 1400 }}
                popperOptions={{ positionFixed: true }}>
                <div className='search-by-name-wrapper'>
                    {load?.length ? (
                        <p>Loading...</p>
                    ) : (
                        <div>
                            {searchResults?.results?.length ? (
                                searchResults?.results?.map((i, j) => (
                                    <div key={j}>
                                        <div>{renderCard(i)}</div>
                                    </div>
                                ))
                            ) : (
                                <p>No Matching Results</p>
                            )}
                        </div>
                    )}
                </div>
            </Popper>

            {/*<Popper*/}
            {/*    id={idAddress}*/}
            {/*    open={openAddress}*/}
            {/*    anchorEl={anchorElAddress}*/}
            {/*    className={'custom-select-paper-box-custom'}*/}
            {/*    placement="bottom-start"*/}
            {/*    style={{ position: 'absolute', zIndex: 20 }}>*/}
            {/*    <div className="search-by-name-wrapper">*/}
            {/*        {loader?.length ? (*/}
            {/*            <p>Loading...</p>*/}
            {/*        ) : (*/}
            {/*            <div>*/}
            {/*                <button onClick={currentLocation} className="current-location-btn">*/}
            {/*                    {locationLoad ? '...' : <Svg.CurrentLocation />}*/}
            {/*                    Current Location*/}
            {/*                </button>*/}

            {/*                <div style={{ paddingLeft: '56px' }}>/!*{loading && <div>Loading...</div>}*!/</div>*/}
            {/*            </div>*/}
            {/*        )}*/}
            {/*    </div>*/}
            {/*</Popper>*/}

            {(open || idAddress) && (
                <div
                    className='beck-drop'
                    onClick={() => {
                        setAnchorEl(null);
                        setAnchorElAddress(null);
                    }}
                />
            )}
        </div>
    );
};
