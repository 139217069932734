import { NavLink } from 'react-router-dom';

export const Breadcrumb = ({ list, className }) => {
    return (
        <div className={`breadcrumb-wrapper ${className ? className : ''}`}>
            {list?.map((i, j) =>
                i?.to ? (
                    <NavLink key={j} to={i?.to}>
                        {i?.title} /
                    </NavLink>
                ) : (
                    <p key={j}>{i?.title}</p>
                )
            )}
        </div>
    );
};
