import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { authActions } from 'store';
import { RedRadio, CustomSelect, NotYetScreen, PageLoader, PaginationItem } from 'components';
import { SearchedItems, SearchedFilterTypes, ACTION_TYPE, textForShow } from './fragments';
import SearchedMapView from './fragments/searchedMap';
import SearchedDrawer from './fragments/searchedDrawer';
import { FindLoad, SaveParams, searchedTypes } from 'utils';
import { Images } from 'assets';

export const Search = () => {
    const { searchResults } = useSelector((state) => ({
        searchResults: state.auth.searchResults,
    }));
    const location = useLocation();
    const info = location?.state;
    const loader = FindLoad(ACTION_TYPE);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const searchParams = new URLSearchParams(location.search);
    const searchField = searchParams.get('searchField');
    const lat = searchParams.get('lat');
    const lng = searchParams.get('lng');
    const address = searchParams.get('address');
    const renderText = textForShow(searchField, lat, lng, address);

    useEffect(() => {
        const params = {
            ...info,
            skip: info?.page ? info.page - 1 : 0,
            limit: 10,
        };
        searchField ? (params.searchField = searchField) : delete params.searchField;
        lat ? (params.lat = lat) : delete params.lat;
        lng ? (params.lng = lng) : delete params.lng;
        if (lat && lng) {
            params.zoom = 50000;
        }

        dispatch(authActions.search(params));
    }, [dispatch, info]);

    const handlePush = (name, value) => {
        const params = {
            ...info,
        };
        if (value === 'ALL') {
            delete params[name];
        } else {
            params[name] = value;
        }
        SaveParams(`${location?.pathname}${location?.search}`, navigate, params);
    };

    const renderTypes = () => {
        return (
            <div>
                <div className="filter-actions-selector-box">
                    {SearchedFilterTypes(info).map((item, index) => {
                        return (
                            <button
                                key={index}
                                onClick={() => handlePush('type', item.value)}
                                className={`filter-radio-actions-box-type
                               ${
                                   item.selectedValue === item.value || (item.value === 'ALL' && !info?.type)
                                       ? 'filter-radio-actions-box-active'
                                       : ''
                               }`}>
                                <p>{item.label}</p>
                                <RedRadio checked={info?.type ? info?.type === item.value : item.value === 'ALL'} value={item.value} />
                            </button>
                        );
                    })}
                </div>
            </div>
        );
    };

    return (
        <div className="search-page">
            <div className="search-page-title-filters">
                <div>
                    <p className="page-global-title-small desktop-view">
                        {renderText === 'noTypedText' ? `Search Results` : `Search Result for ‘’${renderText}’’`}
                    </p>
                </div>
                <CustomSelect active={info?.type && info?.type !== searchedTypes?.ALL} body={renderTypes()} label="Filter Type" />
            </div>

            {loader ? (
                <PageLoader height={'60vh'} />
            ) : (
                <>
                    {searchResults?.results?.length ? (
                        <>
                            <div className="search-page-screen">
                                <div className="searched-cards-pagination">
                                    <div className="searched-cards">
                                        {searchResults?.results?.map(
                                            (item, j) =>
                                                item && (
                                                    <React.Fragment key={j}>
                                                        <SearchedItems item={item} />
                                                    </React.Fragment>
                                                )
                                        )}
                                    </div>

                                    <PaginationItem
                                        count={searchResults?.count}
                                        link={`${window.location.pathname}${window.location.search}`}
                                    />
                                </div>

                                <div className="searched-map">
                                    <SearchedMapView />
                                </div>
                            </div>

                            <div className="searched-drawer">
                                <SearchedDrawer />
                            </div>
                        </>
                    ) : (
                        <NotYetScreen
                            image={Images.EmptySearch}
                            title={'No results found'}
                            desc={
                                renderText === 'noTypedText'
                                    ? 'Try searching for something more general, change the filters, or check for spelling mistakes.'
                                    : `Sorry, “${renderText}” did not match any results. Try searching for something more general, change the filters, or check for spelling mistakes.`
                            }
                        />
                    )}
                </>
            )}
        </div>
    );
};
