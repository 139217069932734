import * as React from 'react';
import Box from '@mui/material/Box';
import Popper from '@mui/material/Popper';
import { Svg } from 'assets';
import { renderButtonIcon, renderButtonText } from './constants';

export const EventCta = ({ event }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;

    const handleClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setAnchorEl(anchorEl ? null : e.currentTarget);
    };

    const close = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setAnchorEl(null);
    };

    const handleClickLink = (e, type) => {
        setAnchorEl(null);
        const value = event?.cta[type];

        if (type === 'emailUs') {
            window.open(`mailto:${value}`, '_blank');
        }
        if (type === 'contactUs') {
            window.open(`tel:${value}`, '_blank');
        } else {
            window.open(`${value}/?utm_source=https://armat.org/&utm_medium=referral&utm_campaign=summer-sale`, '_blank');
        }
    };
    return (
        <div>
            {open && <div className="beck-drop" onClick={close} />}
            <button className="cta-btn-box" aria-describedby={id} type="button" onClick={handleClick}>
                <Svg.MoreActions />
            </button>
            <Popper id={id} open={open} anchorEl={anchorEl} className="cta-paper-box" style={{ position: 'absolute', zIndex: 20 }}>
                <Box sx={{ border: 1, p: 1, bgcolor: 'background.paper' }}>
                    <div className="action-btns-box">
                        {Object?.keys(event?.cta).map((i, j) => {
                            return (
                                <button key={j} onClick={(e) => handleClickLink(e, i)} style={{ padding: '8px 12px', marginBottom: '4px' }}>
                                    <>{renderButtonIcon(i)}</>
                                    <p>{renderButtonText(i)}</p>
                                </button>
                            );
                        })}
                    </div>
                </Box>
            </Popper>
        </div>
    );
};
