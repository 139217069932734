import React from 'react';
import { Svg } from '../assets';

export const searchedTypes = {
    ALL: 'ALL',
    EVENTS: 'EVENT',
    BUSINESS: 'BUSINESS',
    NON_PROFIT: 'NON_PROFIT',
};
export const renderLink = (item) => {
    if (item?.type === searchedTypes.NON_PROFIT || item?.type === searchedTypes.BUSINESS) {
        return `/organization/${item?.id}`;
    }
    if (item?.type === searchedTypes.EVENTS) {
        return `/event/${item?.id}`;
    }
};

export const renderSearchIcon = (type) => {
    if (type === searchedTypes.NON_PROFIT) {
        return (
            <div>
                <Svg.Listing />
            </div>
        );
    }
    if (type === searchedTypes.BUSINESS) {
        return (
            <div>
                <Svg.Business />
            </div>
        );
    }
    if (type === searchedTypes.EVENTS) {
        return (
            <div>
                <Svg.Event />
            </div>
        );
    }
};
