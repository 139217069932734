import React, { useState } from 'react';
import Popper from '@mui/material/Popper';
import { Svg } from 'assets';
import { Notifications } from './notifications/notifications';
import { useWindowDimensions } from 'utils';

export const NotificationBox = () => {
    const [anchorNotEl, setAnchorNotEl] = useState(null);
    const openNote = Boolean(anchorNotEl);
    const id = anchorNotEl ? 'simple-note-popper' : undefined;
    const width = useWindowDimensions();
    const openNotifications = (event) => {
        setAnchorNotEl(event.currentTarget);
        if (event.currentTarget && width.width < 768) {
            document.body.style.overflow = 'hidden';
        }
    };

    return (
        <div>
            <button className="notification-btn" aria-describedby={id} onClick={openNotifications}>
                <Svg.Notification />
            </button>

            <Popper
                id={id}
                open={openNote}
                anchorEl={anchorNotEl}
                className="note-custom-select-paper-box"
                placement="bottom-start"
                style={{ position: 'absolute', zIndex: 9999 }}>
                <div className="notifications-box">
                    <Notifications closeOpen={() => setAnchorNotEl(null)} />
                </div>
            </Popper>

            {openNote && <div className="beck-drop" onClick={() => setAnchorNotEl(null)} />}
        </div>
    );
};
