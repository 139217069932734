import React from 'react';
import { useNavigate } from 'react-router';
import { NavLink } from 'react-router-dom';
import * as moment from 'moment/moment';
import { TextRow } from 'components';
import { Svg } from 'assets';
import { dateConverter, originalDateConverter } from 'utils';

export const EventListCard = ({ event }) => {
    const navigate = useNavigate();
    const eventImage = event?.images?.length > 0 ? event.images[event.eventImage ? event.eventImage : 0].url : null;

    return (
        <div className="event-list-card-wrapper" onClick={() => navigate(`/event/${event?.eventId}`)}>
            <div className="event-list-card-image-title">
                <div className="event-list-card-image-box">
                    <div className="list-event-type-box">{event?.locationType === 'VIRTUAL' ? 'ONLINE' : 'IN-PERSON'}</div>
                    <div className="event-list-image">{eventImage ? <img src={eventImage} alt="icon" /> : <Svg.Gallery />}</div>
                </div>

                {/*<div className="event-list-card-image-box" style={{ backgroundImage: `url(${eventImage})` }}>*/}
                {/*    <div*/}
                {/*        className="list-event-type-box">{event?.locationType === 'VIRTUAL' ? 'ONLINE' : 'IN-PERSON'}</div>*/}
                {/*</div>*/}

                <div className="event-list-card-title-desc">
                    <p className="list-event-title">
                        <TextRow name={event?.title} />
                    </p>
                    {event?.description && <p className="event-desc" dangerouslySetInnerHTML={{ __html: event?.description }} />}
                </div>
            </div>

            <div className="list-card-info-box">
                <div className="info-row">
                    <div className="icon-box">
                        <Svg.EventCalendar />
                    </div>

                    {event.timezoneOffset ? (
                        <div>
                            {event?.locationType === 'VIRTUAL' ? (
                                <p>
                                    {event?.startTime
                                        ? `${originalDateConverter(event)} ${event?.timezoneOffset ? `(GMT ${event.timezoneOffset} )` : ''}`
                                        : `${moment(event.startDate).format('MM/DD/YYYY')}`}
                                </p>
                            ) : (
                                <p>
                                    {event?.startTime
                                        ? `${dateConverter(event)} (Your Time)`
                                        : `${moment(event?.startDate).format('MM/DD/YYYY')}`}
                                </p>
                            )}
                        </div>
                    ) : (
                        <div>
                            <p>{`${moment(event?.startTime).format('llll')} ${
                                event?.timezoneOffset ? `(GMT ${event.timezoneOffset} )` : ''
                            }`}</p>
                        </div>
                    )}
                </div>

                {event?.address?.formattedAddress && (
                    <div className="info-row" style={{ alignItems: 'center' }}>
                        <div className="icon-box">
                            <Svg.EventLocation />
                        </div>
                        <TextRow name={event?.address?.formattedAddress} textWidth={8} />
                    </div>
                )}
            </div>

            <NavLink to='/' className="view-details-btn">
                <p className="view-details-btn-text">View Details</p>
                <Svg.ArrowUpRight />
            </NavLink>
        </div>
    );
};
