/** Types */
export const GET_EVENTS = 'GET_EVENTS';
export const GET_EVENTS_SUCCESS = 'GET_EVENTS_SUCCESS';
export const GET_EVENT_BY_ID = 'GET_EVENT_BY_ID';
export const GET_EVENT_BY_ID_SUCCESS = 'GET_EVENT_BY_ID_SUCCESS';
export const GET_EVENT_SPONSORS = 'GET_EVENT_SPONSORS';
export const GET_EVENT_SPONSORS_SUCCESS = 'GET_EVENT_SPONSORS_SUCCESS';

/** Rsvp **/
export const CREATE_RSVP = 'CREATE_RSVP';
export const EDIT_RSVP = 'EDIT_RSVP';
export const GET_CURRENT_RSVP = 'GET_CURRENT_RSVP';
export const GET_CURRENT_RSVP_SUCCESS = 'GET_CURRENT_RSVP_SUCCESS';

/** End **/
export const GET_CATEGORIES = 'GET_CATEGORIES';
export const GET_CATEGORIES_SUCCESS = 'GET_CATEGORIES_SUCCESS';
