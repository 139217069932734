import React from 'react';
import { Checkbox } from '@mui/material';

export const CheckboxMain = ({ checked, onChange, className }) => {
    return (
        <div className={`checkbox-box ${className ? className : ''}`}>
            <Checkbox
                checked={checked}
                onChange={onChange}
                sx={{
                    '&.Mui-checked': {
                        borderRadius: '4px',
                        color: '#BB3240',
                    },

                    '&.MuiCheckbox-root': {
                        borderRadius: '4px',
                    },
                }}
            />
        </div>
    );
};
