import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Popper from '@mui/material/Popper';
import { Svg } from 'assets';
import { eventActions as eventsActions } from 'store';
import { FindLoad, token } from 'utils';
import { Loader } from 'components';
import { CREATE_RSVP_ACTION, EDIT_RSVP_ACTION, RsvpEnums } from './constants';
import { useState } from 'react';

export const RsvpActions = ({ event }) => {
    const { eventRsvp } = useSelector((state) => ({
        eventRsvp: state.events.eventRsvp,
    }));
    const ACTION_TYPE = eventRsvp?.id ? EDIT_RSVP_ACTION : CREATE_RSVP_ACTION;
    const [anchorEl, setAnchorEl] = useState(null);
    const dispatch = useDispatch();
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;
    const loader = FindLoad(ACTION_TYPE);
    const [selected, setSelected] = useState(null);
    const eventId = event?.eventId || event?.id;

    const handleClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setSelected(null);
        setAnchorEl(anchorEl ? null : e.currentTarget);
    };

    const handleRsvp = (e, type) => {
        e.preventDefault();
        e.stopPropagation();

        if (token) {
            setSelected(eventId);
            setAnchorEl(null);
            if (eventRsvp?.id) {
                dispatch(eventsActions.editRsvp({ rsvpId: eventRsvp?.id, eventId: eventId, status: type }));
            } else {
                dispatch(eventsActions.createRsvp({ eventId: eventId, status: type }));
            }
        }
    };

    const renderRsvpActionBtn = () => {
        if (eventRsvp?.status === RsvpEnums?.GOING) {
            return (
                <div className="flex">
                    <Svg.Going />
                    <p>Going</p>
                </div>
            );
        } else if (eventRsvp?.status === RsvpEnums?.NOTGOING) {
            return (
                <div className="flex">
                    <Svg.NotGoing />
                    <p>Not Going</p>
                </div>
            );
        } else {
            return (
                <div className="flex">
                    <Svg.Interested />
                    <p>Interested</p>
                </div>
            );
        }
    };

    const close = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setAnchorEl(null);
    };

    return (
        <div>
            {open && <div className="beck-drop" onClick={close} />}
            <button
                // style={eventRsvp?.id ? {} : {}}
                className="rsvp-btn-box"
                aria-describedby={id}
                type="button"
                onClick={handleClick}>
                <div className="flex">{loader && eventId === selected ? <Loader /> : renderRsvpActionBtn()}</div>
                <Svg.DownBtn />
            </button>
            <Popper id={id} open={open} anchorEl={anchorEl} className="paper-box" style={{ position: 'absolute', zIndex: 20 }}>
                <Box sx={{ border: 1, p: 1, bgcolor: 'background.paper' }}>
                    <div className="action-btns-box">
                        <button
                            className={`${eventRsvp?.status === RsvpEnums?.INTERESTED ? 'active-rsvp-button' : ''}`}
                            onClick={(e) => handleRsvp(e, RsvpEnums?.INTERESTED)}>
                            <Svg.Interested />
                            <p>Interested</p>
                        </button>
                        <button
                            className={`${eventRsvp?.status === RsvpEnums?.GOING ? 'active-rsvp-button' : ''}`}
                            onClick={(e) => handleRsvp(e, RsvpEnums?.GOING)}>
                            <Svg.Going />
                            <p>Going</p>
                        </button>
                        <button
                            className={`${eventRsvp?.status === RsvpEnums?.NOTGOING ? 'active-rsvp-button' : ''}`}
                            onClick={(e) => handleRsvp(e, RsvpEnums?.NOTGOING)}>
                            <Svg.NotGoing />
                            <p>Not Going</p>
                        </button>
                    </div>
                </Box>
            </Popper>
        </div>
    );
};
