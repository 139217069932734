import { makeStyles } from '@mui/styles';

export const paginationStyle = makeStyles(() => ({
    PaginationWrapper: {
        width: '100%',
        margin: '21px 0 ',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        padding: '8px 16px',
        '@media (min-width: 1919px)': {
            padding: '20px 32px',
        },

        '& .MuiPaginationItem-textPrimary.Mui-selected': {
            background: '#F8EBEC',
            color: '#AA2E3A',
            borderRadius: '4px',
            fontSize: '14px',
            fontWeight: '600',
            lineHeight: '19px',

            '&:hover': {
                background: '#F8EBEC',
                color: '#AA2E3A',
            },
        },

        '& .MuiPaginationItem-page': {
            background: '#FFFFFF 0% 0% no-repeat padding-box',
            color: '#717680',
            // border: '0.5px solid #DDE3F0',
            borderRadius: '8px',
            fontSize: '14px',
            fontWeight: '600',
            lineHeight: '19px',
        },

        '& .MuiPaginationItem-outlined': {
            background: '#FFFFFF 0% 0% no-repeat padding-box',
            border: '1px solid #DDE3F0',
            borderRadius: '4px',
            textAlign: 'center',
            fontWeight: '600',
            fontSize: '14px',
            color: '#545F7E',
        },

        '& .MuiPaginationItem-page.Mui-selected:hover': {
            background: '#387DFF 0% 0% no-repeat padding-box',
            color: 'white',
        },
    },
    showCountText: {
        fontSize: '14px',
        fontWeight: '600',
    },
}));
