import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { NavLink } from 'react-router-dom';
import { useLocation } from 'react-router';
import { Svg, Images } from 'assets';
import { linksList, socialLinks } from './constants';
import { Notify, useWindowDimensions } from 'utils';
import { authService } from '../../store/auth/auth.service';
import { MinLoader } from '../../components';

export const Footer = () => {
    const [loading, setLoading] = useState(false);
    const { width } = useWindowDimensions();
    const location = useLocation();
    const info = location?.state;
    const { register, handleSubmit, reset } = useForm({});

    const currentYear = new Date().getFullYear();


    const checkShowFooter = () => {
        if (info?.listType === 'MAP' && width < 1279) {
            return false;
        } else return !(location?.pathname === '/search' && width < 1279);
    };

    const onSubmit = (data) => {
        setLoading(true);
        authService
            .subscribeNews(data?.email)
            .then(() => {
                Notify('You have successfully subscribed to our newsletter');
                reset();
            })
            .catch(() => {
                Notify('Something went wrong, please try again later');
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <>
            {checkShowFooter() && (
                <footer className='footer-section'>
                    <div className='footer-container'>

                        <div className='footer-logo-links-send-form'>
                            <div className='footer-logo-description_links-block'>
                                <div className='footer-logo_description'>
                                    <div className='footer-logo-block'>
                                        <img src={Images.LogoImage} alt='footer logo' />
                                    </div>

                                    <p className='footer-description'>
                                        Stay connected with your Armenian roots and community.
                                    </p>
                                </div>

                                <div className='footer-links-wrapper'>
                                    <div className='footer-links-block-mobile'>
                                        <NavLink to='/businesses'>
                                            Businesses
                                        </NavLink>

                                        <NavLink to='/nonprofits'>
                                            Nonprofits
                                        </NavLink>

                                        <NavLink to='/contact-us'>
                                            Contact Us
                                        </NavLink>
                                    </div>

                                    <div className='footer-links-block-mobile'>
                                        <NavLink to='/events'>
                                            Events
                                        </NavLink>

                                        <NavLink to='/privacy'>
                                            Privacy
                                        </NavLink>
                                    </div>

                                    <div className='footer-links-block'>
                                        {linksList.map(({ title, link }, i) => (
                                            <NavLink
                                                key={i}
                                                to={link}
                                            >
                                                {title}
                                            </NavLink>
                                        ))}
                                    </div>
                                </div>
                            </div>

                            <div className='send-form-privacy-text'>
                                <form className='send-email-form' onSubmit={handleSubmit(onSubmit)}>
                                    <div className='send-email-input-block'>
                                        <div className='send-email-input-svg'>
                                            <Svg.Sms />
                                        </div>

                                        <input {...register('email')} type='email' placeholder='Enter your email' />
                                    </div>

                                    <button
                                        type='submit'
                                        className='send-email-button'
                                        disabled={loading}
                                    >
                                        {loading ?
                                            <MinLoader margin='0' color='white' />:
                                            <>
                                                <Svg.SendSvg className='send-email-svg' />
                                                <p className='send-email-button-text'>Subscribe</p>
                                            </>
                                        }
                                    </button>
                                </form>

                                <p className='send-email-privacy-text'>
                                    We care about your data in our <a href='/privacy-policy' target='_blank'> privacy
                                    policy</a>
                                </p>
                            </div>
                        </div>


                        <div className='socials-copy-right-block'>
                            <div className='footer-socials-block'>
                                {socialLinks.map(({ Icon, link }, j) => (
                                    <div
                                        className={'footer-socials-block-item'}
                                        key={j}
                                        onClick={() => window.open(link)}
                                    >
                                        {Icon}
                                    </div>
                                ))}
                            </div>

                            <p className='footer-copy-right'>© {currentYear} Armat. All rights reserved.</p>
                        </div>
                    </div>
                </footer>
            )}
        </>
    );
};
