import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ticketTypes, Ticket } from './fragments';
import { DateSelector, NotYetScreen, PageLoader } from 'components';
import { ticketActions } from 'store';
import { FindLoad, SaveParams, useWindowSize } from 'utils';
import { useLocation, useNavigate } from 'react-router';
import { Images } from '../../assets';

const ACTION_TYPE = 'GET_MY_TICKETS';
export const MyTickets = () => {
    const { myTickets } = useSelector((state) => ({
        myTickets: state.tickets.myTickets,
    }));
    const dispatch = useDispatch();
    const filteredTickets = myTickets?.filter((i) => i?.ticketId?.eventId?.eventId);
    const loader = FindLoad(ACTION_TYPE);
    const location = useLocation();
    const navigate = useNavigate();
    const [width] = useWindowSize();
    const info = location?.state;

    const selectType = (selected) => {
        const params = {
            ...info,
        };
        params.timeStatus = selected;
        delete params.startDate;
        delete params.endDate;
        SaveParams('/tickets', navigate, params);
    };

    useEffect(() => {
        const params = {
            ...info,
        };
        delete params.timeStatus;

        if (info?.timeStatus === ticketTypes.PRESENT) {
            params.startDate = new Date();
        } else {
            params.endDate = new Date();
        }

        dispatch(ticketActions.getMyTickets(params));
    }, [dispatch, info]);

    return (
        <div className="my-tickets-page">
            <div className="flex" style={{ gap: '12px', justifyContent: 'space-between' }}>
                <p className="page-global-title">My Tickets</p>

                {width < 768 && (
                    <>
                        {info?.timeStatus === ticketTypes.PRESENT ? (
                            <DateSelector link={'/tickets'} min={new Date()} />
                        ) : (
                            <DateSelector link={'/tickets'} max={new Date()} />
                        )}
                    </>
                )}
            </div>

            <div className="tickets-filter-box">
                <div className="ticket-type-box">
                    <button
                        className={info?.timeStatus === ticketTypes.PAST ? 'active' : !info?.timeStatus ? 'active' : 'inactive'}
                        onClick={() => selectType(ticketTypes.PAST)}>
                        Past
                    </button>
                    <button
                        className={info?.timeStatus === ticketTypes.PRESENT ? 'active' : 'inactive'}
                        onClick={() => selectType(ticketTypes.PRESENT)}>
                        Present
                    </button>
                </div>

                {width >= 768 && (
                    <>
                        {info?.timeStatus === ticketTypes.PRESENT ? (
                            <DateSelector link={'/tickets'} min={new Date()} />
                        ) : (
                            <DateSelector link={'/tickets'} max={new Date()} />
                        )}
                    </>
                )}
            </div>

            <div className="ticket-body-box">
                {loader ? (
                    <PageLoader height={'60vh'} />
                ) : (
                    <>
                        {filteredTickets?.length ? (
                            filteredTickets?.map((i, j) => (
                                <div key={j}>
                                    <Ticket item={i} />
                                </div>
                            ))
                        ) : (
                            <NotYetScreen
                                image={Images.EmptyTickets}
                                title={'No Tickets Yet'}
                                desc={
                                    'It looks like you don’t have any tickets in your account right now. Browse upcoming events and get your tickets today!'
                                }
                            />
                        )}
                    </>
                )}
            </div>
        </div>
    );
};
