import React from 'react';
import { NavLink } from 'react-router-dom';
import { PhoneRow, TextRow } from 'components';
import { Svg } from 'assets';

export const OrgList = ({ org, viwButton, newTab }) => {
    const imageUrl = org?.images?.length ? org.images[org.mainImage ? org.mainImage : 0].url : org?.avatar?.url ? org?.avatar?.url : null;

    return (
        <div className="org-list-wrapper">
            <div className="org-list-mobile-view">
                <div className="org-image-and-info">
                    <div className="org-image-box">{imageUrl ? <img src={imageUrl} alt="icon" /> : <Svg.Gallery />}</div>
                    <div className="org-info-wrapper">
                        <p className="org-title">
                            <TextRow name={org?.name} />
                        </p>
                        {org?.address?.formattedAddress && (
                            <div className="org-info-box">
                                <a
                                    className="address-link"
                                    target={'_blank'}
                                    href={`https://www.google.com/maps?saddr=My+Location&daddr=${org?.address?.lat},${org?.address?.lng}`}
                                    rel="noreferrer">
                                    {org?.address?.formattedAddress}
                                </a>
                            </div>
                        )}
                    </div>
                </div>
                <div>
                    {org?.phoneNumber && (
                        <div className="org-info-box">
                            <Svg.CallCalling />
                            <a href={`tel:+${org?.phoneNumber}`}>
                                <PhoneRow phone={org?.phoneNumber} />
                            </a>
                        </div>
                    )}
                    {org?.email && (
                        <div className="org-info-box">
                            <Svg.Sms />
                            <a href={`mailto:${org?.email}`}>{org?.email}</a>
                        </div>
                    )}
                </div>

                <div className="org-view-details">
                    <NavLink to={`/organization/${org?.id}`} target={newTab ? '_blank' : '_parent'}>
                        View Details
                    </NavLink>
                </div>
            </div>

            <div className="org-list-desktop-view" style={viwButton === 'mapView' ? { flexDirection: 'column' } : {}}>
                <div className="org-image-and-info">
                    <div className="org-image-box">{imageUrl ? <img src={imageUrl} alt="icon" /> : <Svg.Gallery />}</div>
                    <div className="org-info-wrapper">
                        <div className="space-between">
                            <p className="org-title">
                                <TextRow name={org?.name} />
                            </p>

                            {viwButton === 'mapView' && (
                                <a
                                    className="org-view-details-desktop"
                                    href={`/organization/${org?.id}`}
                                    target={'_blank'}
                                >
                                    <Svg.ArrowUpRight />
                                </a>
                            )}
                        </div>

                        {org?.address?.formattedAddress && (
                            <div className="org-info-box">
                                <Svg.EventLocation />
                                <a
                                    target={'_blank'}
                                    href={`https://www.google.com/maps?saddr=My+Location&daddr=${org?.address?.lat},${org?.address?.lng}`}
                                    rel="noreferrer">
                                    {org?.address?.formattedAddress}
                                </a>
                            </div>
                        )}
                        {org?.phoneNumber && (
                            <div className="org-info-box">
                                <Svg.CallCalling />
                                <a href={`tel:+${org?.phoneNumber}`}>
                                    <PhoneRow phone={org?.phoneNumber} />
                                </a>
                            </div>
                        )}
                        {org?.email && (
                            <div className="org-info-box">
                                <Svg.Sms />
                                <a href={`mailto:${org?.email}`}>{org?.email}</a>
                            </div>
                        )}
                    </div>
                </div>

                {viwButton !== 'mapView' && (
                    <div className="org-view-details" style={viwButton === 'mapView' ? { width: '100%' } : {}}>
                        <NavLink to={`/organization/${org?.id}`} target={newTab ? '_blank' : '_parent'}>
                            View Details
                        </NavLink>
                    </div>
                )}
            </div>
        </div>
    );
};
