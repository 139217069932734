import React from 'react';
import { useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import { OrgList } from 'fragments';
import { NotYetScreen, PaginationItem } from 'components';
import { Images } from 'assets';
import OrganizationMapView from './organizationMapView';
import SwipeableTemporaryDrawer from './orgMapDrawer';
import { useWindowSize } from 'utils';

export const OrganizationMap = () => {
    const { organizations } = useSelector((state) => state.orgs);
    const location = useLocation();
    const [width] = useWindowSize();
    return (
        <>
            {organizations?.orgs?.length ? (
                <>
                    <div className="organization-map-box">
                        <div className="organization-map-block">
                            <div className="org-map-view-list">
                                {organizations?.orgs?.map((org) => (
                                    <React.Fragment key={org?.id}>
                                        <OrgList org={org} viwButton={'mapView'} />
                                    </React.Fragment>
                                ))}
                            </div>

                            {width > 1278 && <PaginationItem count={organizations?.count} link={location?.pathname} />}
                        </div>

                        <div className="organization-map">
                            <OrganizationMapView />
                        </div>
                    </div>
                    <SwipeableTemporaryDrawer />
                </>
            ) : (
                <NotYetScreen
                    image={Images.EmptyState}
                    title={'No Organizations Found'}
                    desc={'It seems there are no organizations listed yet.'}
                />
            )}
        </>
    );
};
