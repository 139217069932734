import { useSelector } from 'react-redux';
import { userInfo } from 'utils';
import { useState } from 'react';
import { ButtonsTab, TabEnums } from './constants';
import { GeneralInfo } from './generalInfo';
import { Privacy } from './privacy';
import { Notifications } from './notifications';
import { DeleteAccount } from './deleteAccount';

export const MyProfileInformation = () => {
    const { myProfile } = useSelector((state) => ({
        myProfile: state.auth.myProfile,
    }));
    const user = myProfile || userInfo;
    const [activeTab, setActiveTab] = useState(TabEnums.GENERAL);

    return (
        <div className="my-profile-information-section">
            <div className="my-profile-information-tab">
                {ButtonsTab.map((i, j) => (
                    <button
                        key={j}
                        onClick={() => setActiveTab(i?.value)}
                        className={activeTab === i?.value ? 'tab-active-btn' : 'tab-inactive-btn'}>
                        {i?.icon}
                        <p> {i?.name}</p>
                    </button>
                ))}
            </div>

            <div className="my-profile-information-action">
                {activeTab === TabEnums.GENERAL && <GeneralInfo user={user} />}
                {activeTab === TabEnums.PRIVACY && <Privacy user={user} />}
                {activeTab === TabEnums.NOTIFICATIONS && <Notifications user={user} />}
                {activeTab === TabEnums.ACCOUNT && <DeleteAccount user={user} />}
            </div>

            <div className="my-profile-information-action-mobile">
                <GeneralInfo user={user} />
                <Privacy user={user} />
                <Notifications user={user} />
                <DeleteAccount user={user} />
            </div>
        </div>
    );
};
