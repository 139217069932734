export const NotYetScreen = ({ image, title, desc }) => {
    return (
        <div className="not-yet-screen-wrapper">
            <div className="not-yet-screen-box">
                <div className="not-yet-img-block">
                    <img src={image} alt="icon" />
                </div>

                <p>{title}</p>
                <span>{desc}</span>
            </div>
        </div>
    );
};
