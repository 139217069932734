import React from 'react';
import { Svg } from 'assets';

export const CheckPassword = ({ fields, text, isActive, className }) => {
    const classNames = ['check-password-wrapper', isActive, className];

    if (isActive) classNames.push('active');
    if (className) classNames.push(className);

    return (
        isActive && (
            <div className={`check-password-wrapper ${classNames.join(' ')}`}>
                {fields?.map(({ message, regex }, index) => {
                    const isTrue = regex.test(text);

                    return (
                        <div className="check-password-item" key={index}>
                            <div className="check-password-item-icon">
                                {isTrue ? <Svg.CheckIconSvg className="check-password-item-icon-success" /> : <Svg.CheckIconSvg />}
                            </div>

                            <p className={`check-password-message ${isTrue ? 'check-message-success' : ''}`}>{message}</p>
                        </div>
                    );
                })}
            </div>
        )
    );
};
