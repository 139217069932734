import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { CircularProgress } from '@mui/material';
import { notificationsActions } from 'store';
import { useNoteContStyles } from './styles';
import NotificationItem from './notificationItem';
import { FindLoad } from 'utils';
import { CloseBtn } from 'components';
import { Svg } from 'assets';

const ACTION_TYPE = 'GET_NOTIFICATIONS';
export const Notifications = ({ userInfo, closeOpen }) => {
    const { notifications, removeNotification } = useSelector((state) => ({
        notifications: state.notifications,
        removeNotification: state.notifications.removeNotification,
    }));
    const dispatch = useDispatch();
    const classes = useNoteContStyles();
    const [array, setArray] = useState(6);
    const [notificationsList, setNotifications] = useState([]);
    const [showAllNotifications, setAllNotifications] = useState(true);
    const [load, setLoad] = useState(true);
    const [page, setPage] = useState(1);
    const loader = useRef(null);
    const params = { pageSize: array, page: 1, userId: userInfo?.id };
    const backLoad = FindLoad(ACTION_TYPE);

    const unreadNotifications = notificationsList.length && notificationsList?.filter((n) => n?.status !== 'READ');

    useEffect(() => {
        if (!notificationsList?.length) {
            setNotifications(notifications);
        }
    }, [notifications]);


    useEffect(() => {
        let options = {
            root: null,
            rootMargin: '20px',
            threshold: 1.0,
        };
        const observer = new IntersectionObserver(handleObserver, options);
        if (loader.current) {
            observer.observe(loader.current);
        }
    }, []);

    useEffect(() => {
        let config = {
            headers: { 'access-token': localStorage.getItem('access-token') },
            params: params,
        };
        axios
            .get(`/notifications`, config)
            .then((res) => {
                if (res.data.length >= notificationsList?.length) {
                    setArray(array + 6);
                    setNotifications(res?.data);
                    dispatch(notificationsActions.getNotifications(params));
                } else {
                    setLoad(false);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }, [page]);

    const handleObserver = (entities) => {
        const target = entities[0];
        if (target.isIntersecting) {
            setPage((page) => page + 1);
        }
    };

    return (
        <>
            <div className={classes.notificationsHeader}>
                <p className={classes.headerText}>Notifications</p>

                <CloseBtn handleClose={closeOpen} />
            </div>


            {(!!notificationsList?.length || !!unreadNotifications?.length) ? (
                <>

                    <div className={classes.notificationsTabWrapper}>
                        <div className={classes.notificationsTabBox}>
                            <button
                                className={classes.notificationsTabButton + ' ' + (showAllNotifications ? classes.notificationsTabButtonActive : '')}
                                onClick={() => setAllNotifications(true)}>
                                All
                            </button>

                            <button
                                className={classes.notificationsTabButton + ' ' + (!showAllNotifications ? classes.notificationsTabButtonActive : '')}
                                onClick={() => setAllNotifications(false)}>
                                Unread

                                {unreadNotifications?.length > 0 && (
                                    <div className={classes.notificationsUnreadCount}>
                                        {unreadNotifications?.length - 1}
                                    </div>
                                )}
                            </button>
                        </div>

                    </div>

                    <div>
                        <div className={classes.markAllReadButton}>
                            <button
                                className={classes.headerText1}
                                onClick={() => dispatch(notificationsActions.markReadNotifications(params))}>
                                Mark all as read
                            </button>
                        </div>

                        <div style={{ height: '410px', overflow: 'auto' }}>
                            <div>
                                <div className='post-list'>
                                    {(!!notificationsList?.length && showAllNotifications) &&
                                        notificationsList.map((n, i) => (
                                            <NotificationItem
                                                removeNotification={removeNotification}
                                                params={params}
                                                closeOpen={closeOpen}
                                                loader={loader}
                                                notifications={n}
                                                key={i}
                                            />
                                        ))}

                                    {(!!unreadNotifications?.length && !showAllNotifications) ?
                                        unreadNotifications.map((n, i) => (
                                            <NotificationItem
                                                removeNotification={removeNotification}
                                                params={params}
                                                closeOpen={closeOpen}
                                                loader={loader}
                                                notifications={n}
                                                key={i}
                                            />
                                        ))

                                        : !showAllNotifications && (
                                        <div className={classes.notificationsNotYet} style={{height:'380px'}}>
                                            <div className={classes.noNotificationsCont}>
                                                <Svg.EmptyNotifications />
                                                <p className={classes.noNotes}>No Notifications to Show</p>
                                                <p className={classes.noNotesDesk}>You have no notifications at the moment</p>
                                            </div>
                                        </div>
                                    )
                                    }
                                    {load && (
                                        <div className='loading' ref={loader}>
                                            <div className={classes.infinitiScrollStyle} />
                                        </div>
                                    )}
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    {backLoad.length && (
                                        <CircularProgress
                                            style={{
                                                width: '40px',
                                                height: '40px',
                                                margin: '0 auto',
                                            }}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <div className={classes.notificationsNotYet} style={{height:'490px'}}>
                    <div className={classes.noNotificationsCont}>
                            <Svg.EmptyNotifications />
                            <p className={classes.noNotes}>No Notifications to Show</p>
                            <p className={classes.noNotesDesk}>You have no notifications at the moment</p>
                    </div>
                </div>
            )}
        </>
    );
};
