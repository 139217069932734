import { Svg } from 'assets';

export const Entertainment = {
    title: 'Entertainment',
    icon: <Svg.Celebration />,
    list: [
        {
            id: 1,
            Icon: '🍿 ',
            title: 'Cinema',
        },

        {
            id: 2,
            Icon: '📜 ',
            title: 'Education',
        },

        {
            id: 3,
            Icon: '👜 ',
            title: 'Fashion',
        },

        {
            id: 4,
            Icon: '',
            title: 'tag 1',
        },

        {
            id: 5,
            Icon: '',
            title: 'tag 2',
        },

        {
            id: 6,
            Icon: '💃🏻 ',
            title: 'Dance',
        },
        {
            id: 7,
            Icon: '',
            title: 'tag 3',
        },

        {
            id: 8,
            Icon: '',
            title: 'tag 4',
        },

        {
            id: 9,
            Icon: '',
            title: 'tag 5',
        },

        {
            id: 10,
            Icon: '',
            title: 'tag 6',
        },

        {
            id: 11,
            Icon: '',
            title: 'tag 7',
        },
    ],
};

export const Art = {
    title: 'Arts and Culture',
    icon: <Svg.Celebration />,
    list: [
        {
            id: 12,
            Icon: '👜 ',
            title: 'Fashion',
        },

        {
            id: 13,
            Icon: '📜 ',
            title: 'Education',
        },

        {
            id: 14,
            Icon: '👜 ',
            title: 'Fashion',
        },

        {
            id: 15,
            Icon: '',
            title: 'tag 8',
        },

        {
            id: 16,
            Icon: '',
            title: 'tag 9',
        },

        {
            id: 17,
            Icon: '',
            title: 'tag 10',
        },
    ],
};

export const FoodDining = {
    title: 'Food and Dining',
    icon: <Svg.Celebration />,
    list: [
        {
            id: 18,
            Icon: '📜 ',
            title: 'Education',
        },

        {
            id: 19,
            Icon: '👜 ',
            title: 'Fashion',
        },

        {
            id: 20,
            Icon: '',
            title: 'tag 10',
        },

        {
            id: 21,
            Icon: '',
            title: 'tag 11',
        },

        {
            id: 22,
            Icon: '',
            title: 'tag 12',
        },
    ],
};
