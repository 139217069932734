import React, { useEffect } from 'react';
import { OrgList } from 'fragments';
import { useDispatch, useSelector } from 'react-redux';
import { organizationActions } from 'store';

export const SimilarOrgs = () => {
    const { org, organizations } = useSelector((state) => ({
        org: state.orgs.organizationsById,
        organizations: state.orgs.organizations,
    }));
    const dispatch = useDispatch();

    useEffect(() => {
        if (org?.categories?.length > 0) {
            const newArr = [];
            org?.categories?.forEach((category) => {
                newArr.push(category?.id);
            });

            const params = {
                categories: newArr,
            };
            dispatch(organizationActions.getOrg(params));
        }
    }, [org]);

    return (
        <div>
            {!!organizations?.orgs?.length && (
                <>
                    <div className="org-sections-box">
                        <p className="org-detail-sections-title">Discover Similar Organizations</p>
                    </div>
                    <div className="similar-orgs">
                        {organizations?.orgs?.map((org, i) => (
                            <div key={i}>
                                <OrgList org={org} />
                            </div>
                        ))}
                    </div>
                </>
            )}
        </div>
    );
};
