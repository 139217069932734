import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FileUploader } from 'react-drag-drop-files';
import axios from 'axios';
import { Images, Svg } from 'assets';
import { userInfo } from 'utils';
import { authActions } from 'store';

export const MyProfileImage = () => {
    const [isHoveredProfile, toggleHoveredProfile] = useState(false);
    const { myProfile } = useSelector((state) => ({
        myProfile: state.auth.myProfile,
    }));
    const user = myProfile || userInfo;
    const [imageSrc, setImgSrc] = useState(user?.avatar?.url ? user?.avatar?.url : '');
    const dispatch = useDispatch();

    const handleChange = async (e) => {
        const image = e && URL.createObjectURL(e);
        setImgSrc(image);
        let formData = new FormData();
        formData.append('files', e);
        axios
            .post(`/files/upload?includeThumbnail=true`, formData, { auth: true })
            .then((res) => {
                const params = {
                    ...user,
                };
                params.changeAvatar = {
                    ...res.data,
                };
                dispatch(authActions.editAccount(params));
            })
            .catch(() => {
                setImgSrc(user?.avatar?.url ? user?.avatar?.url : Images.UploadImage);
            });
    };

    return (
        <div className="my-profile-image-section">
            <FileUploader
                className="file-uploader"
                name="file"
                handleChange={handleChange}
                types={['JPG', 'PNG', 'GIF', 'SVG']}
                onDraggingStateChange={(isDragging) => {
                    toggleHoveredProfile(isDragging);
                }}
                dropMessageStyle={{ display: 'none' }}>
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label className="custom-file-upload" htmlFor="file">
                    <i />
                    <input
                        style={{ display: 'none' }}
                        onClick={(event) => (event.target.value = null)}
                        onChange={handleChange}
                        type="file"
                        id="file"
                        accept={'image/png, image/svg+xml, image/jpeg'}
                    />

                    <i className="fa fa-cloud-upload" />
                </label>

                <div
                    className={`my-profile-image
           ${!imageSrc ? 'my-profile-no-image' : ''}
            ${isHoveredProfile ? 'my-profile-image-hover' : ''}
           `}
                    onDragEnter={() => toggleHoveredProfile(true)}
                    onDragLeave={() => toggleHoveredProfile(false)}>
                    {!isHoveredProfile ? (
                        imageSrc ? (
                            <img src={imageSrc} alt="profile" />
                        ) : (
                            <Svg.UserNoImage />
                        )
                    ) : (
                        <div className="hovered-img-block">
                            <div className="hovered-img-block-svg">
                                <Svg.UploadCloudSvg />
                            </div>

                            <p>Upload</p>
                        </div>
                    )}
                </div>
            </FileUploader>

            <div>
                <p className="user-name">{user?.fullName}</p>
                <p className="user-email">{user?.email}</p>
            </div>
        </div>
    );
};
