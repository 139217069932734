/**Payment index */

import { assignCard, deleteCard, getCurrentCard, getInvoices } from './payments.action';

export { paymentsReducer } from './payments.reducer';
export { watchPayments } from './payments.saga';

export const paymentActions = {
    assignCard,
    deleteCard,
    getCurrentCard,
    getInvoices,
};
