import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { InputMain, MinLoader, PhoneInput } from 'components';
import { authActions, httpRequestsOnSuccessActions } from 'store';
import { Svg } from 'assets';
import { TitleAndEdit } from './core';
import { EDIT_ACCOUNT } from './constants';
import { EmailValidator, FindLoad, FindSuccess, useWindowSize } from 'utils';

export const GeneralInfo = ({ user }) => {
    const {
        handleSubmit,
        control,
        clearErrors,
        reset,
        formState: { errors },
    } = useForm({
        defaultValues: {
            fullName: user?.fullName,
            email: user?.email,
            phoneNumber: user?.phoneNumber,
            confirmPassword: '',
        },
    });
    const dispatch = useDispatch();
    const [disabled, setDisabled] = useState(true);
    const loader = FindLoad(EDIT_ACCOUNT);
    const success = FindSuccess(EDIT_ACCOUNT);
    const [width] = useWindowSize();

    useEffect(() => {
        if (success) {
            dispatch(httpRequestsOnSuccessActions.removeSuccess(EDIT_ACCOUNT));
            setDisabled(true);
        }
    }, [success]);

    const onSubmit = (info) => {
        const params = { ...info };
        info?.phoneNumber ? (params.phoneNumber = parseInt(info?.phoneNumber.replace(/[^0-9]/g, '')) + '') : params.phoneNumber = null;
        dispatch(authActions.editAccount(params));
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="user-info-inputs-block">
            <TitleAndEdit
                user={user}
                reset={reset}
                disabled={disabled}
                desc={'Update your general information'}
                clearErrors={clearErrors}
                title={'General Info'}
                setDisabled={setDisabled}
                actionType={EDIT_ACCOUNT}
                icon={<Svg.Setting />}
            />

            <div className={`user-info-inputs-wrapper ${!disabled ? 'user-info-inputs-no-gap' : ''}`}>
                {disabled ? (
                    <div className="user-info-input-box">
                        <div className="input-info">
                            <Svg.UserName />
                            <p>Name</p>
                        </div>

                        <p className="user-info-text">{user?.fullName ? user?.fullName : 'Not Set'}</p>
                    </div>
                ) : (
                    <div style={{ borderBottom: '1px solid #E9EAEB', paddingTop: '16px' }}>
                        <p className="edit-info-desc-mobile">Update your personal details here.</p>

                        <div className="padding-bottom-border-16">
                            <InputMain
                                name="fullName"
                                type="text"
                                control={control}
                                placeholder="Full Name"
                                labelHeader="Full Name"
                                maxLength={20}
                                rules={{
                                    required: 'This Field is required',
                                }}
                                startAdornment={<Svg.Profile className={errors?.fullName ? 'error-fullName-icon' : ''} />}
                            />
                        </div>
                    </div>
                )}

                {disabled ? (
                    <div className="user-info-input-box">
                        <div className="input-info">
                            <Svg.AccountPhone />
                            <p>Phone</p>
                        </div>

                        <p className="user-info-text">{user?.phoneNumber ? user?.phoneNumber : 'Not Set'}</p>
                    </div>
                ) : (
                    <div style={{ borderBottom: '1px solid #E9EAEB', paddingTop: '16px' }}>
                        <PhoneInput
                            disabled={disabled}
                            name={'phoneNumber'}
                            control={control}
                            placeholder={user?.phoneNumber ? '' : 'Not Set'}
                            labelHeader="Phone Number"
                            rules={{
                                validate: (value) => {
                                    const digitsOnly = value.replace(/[^0-9]/g, '');
                                    return (
                                        digitsOnly.length === 10 || "Please enter a valid 10-digit number."
                                    );
                                },
                            }}
                        />
                    </div>
                )}

                {disabled ? (
                    <div className="user-info-input-box">
                        <div className="input-info">
                            <Svg.AccountEmail />
                            <p>Email</p>
                        </div>

                        <p className="user-info-text">{user?.email ? user?.email : 'Not Set'}</p>
                    </div>
                ) : (
                    <div className="padding-top-16">
                        <InputMain
                            name="email"
                            type="email"
                            control={control}
                            placeholder="Email"
                            labelHeader="Email Address"
                            rules={{
                                required: 'This Field is required',
                                pattern: {
                                    value: EmailValidator,
                                    message: 'Must be valid email',
                                },
                            }}
                            startAdornment={<Svg.Sms />}
                        />
                    </div>
                )}
                {!disabled && width < 768 && (
                    <div className="save-cancel-btns">
                        <button type="button" onClick={() => setDisabled(true)} className="cancel-info-btn">
                            Cancel
                        </button>

                        <button type="submit" className="save-info-btn">
                            {loader ? <MinLoader color={'#FFF'} margin={'0'} /> : 'Save'}
                        </button>
                    </div>
                )}
            </div>
        </form>
    );
};
