import React from 'react';
import { EventGridCard } from 'fragments';
import { useSelector } from 'react-redux';
import { NotYetScreen } from 'components';
import { Images } from 'assets';

export const EventGrid = () => {
    const allEvents = useSelector((state) => state.events.events);

    return (
        <div className="event-grid">
            {allEvents?.events?.length ? (
                <>
                    <div className="event-grid-section">
                        {allEvents?.events?.map((event, j) => (
                            <React.Fragment key={j}>
                                <EventGridCard event={event} detailBtn={true} />
                            </React.Fragment>
                        ))}
                    </div>
                </>
            ) : (
                <NotYetScreen
                    image={Images.EmptyState}
                    title={'No Events Added Yet'}
                    desc={'It looks like there are no events added yet. Please check back later or explore other sections of our site.'}
                />
            )}
        </div>
    );
};
