import React, { useState } from 'react';
import { buyTicketStyles } from '../styles';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { CheckoutForm } from './applePay/checkoutForm';
import Radio from '@mui/material/Radio';
import { Svg } from 'assets';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PKEY);

export const CardTypes = ({ changeScreen }) => {
    const classes = buyTicketStyles();
    const [checked, setChecked] = useState(null);

    return (
        <div className="ticket-category-box">
            <p className={classes.choseTicketTitle}>Checkout</p>
            <div className={classes.ticketsWrapper}>
                <p className={classes.selectTMethodTitle}>Select Payment Method</p>
                <p className={classes.selectTMethodDescription}>Choose Your Payment Method: Use Your Saved Card or Add a New One</p>
                <div className={classes.paymentTypeSelector}>
                    <div
                        onClick={() => setChecked('debit')}
                        style={{
                            background: checked === 'debit' ? '#FAFAFA' : 'transparent',
                            marginBottom: '16px',
                        }}
                        className={classes.paymentMethodWrapper}>
                        <div className={classes.paymentMethodIconTitle}>
                            <div className={classes.paymentIcon}>
                                <Svg.CreditCard />
                            </div>
                            <p>Pay with Debit or Credit Card</p>
                        </div>
                        <Radio style={{ color: '#BB3240' }} checked={checked === 'debit'} />
                    </div>

                    <div style={{ marginBottom: '8px' }}>
                        <Elements stripe={stripePromise}>
                            <CheckoutForm />
                        </Elements>
                    </div>
                </div>
            </div>

            <div className={classes.continueBtnWrapper}>
                <button
                    disabled={!checked}
                    className={classes.continueBtn}
                    style={!checked ? { background: '#E0A1A7' } : {}}
                    onClick={() => changeScreen('completePayment')}>
                    Continue
                </button>
            </div>
        </div>
    );
};
