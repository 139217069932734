import React from 'react';
import { Svg } from 'assets';
import { searchedTypes } from 'utils';

export const SearchedFilterTypes = (info) => [
    { label: 'ALL', value: 'ALL', selectedValue: info?.type },
    { label: 'Events', value: searchedTypes.EVENTS, selectedValue: info?.type },
    { label: 'Businesses', value: searchedTypes.BUSINESS, selectedValue: info?.type },
    { label: 'Nonprofits', value: searchedTypes.NON_PROFIT, selectedValue: info?.type },
];

export const ACTION_TYPE = 'SEARCH';

export const renderTypeAndIcon = (type, onClick, className) => {
    if (type === searchedTypes.NON_PROFIT) {
        return (
            <button className={`searched-item-type ${className ? className : ''}`} onClick={onClick}>
                <Svg.Listing className="searched-item-type-svg" />
                <p className="searched-item-type-text">Nonprofit</p>
                <Svg.ArrowUpRight className="searched-item-type-svg-arrow" />
            </button>
        );
    }
    if (type === searchedTypes.BUSINESS) {
        return (
            <button className={`searched-item-type ${className ? className : ''}`} onClick={onClick}>
                <Svg.Business className="searched-item-type-svg" />
                <p className="searched-item-type-text">Business</p>
                <Svg.ArrowUpRight className="searched-item-type-svg-arrow" />
            </button>
        );
    }
    if (type === searchedTypes.EVENTS) {
        return (
            <button className={`searched-item-type ${className ? className : ''}`} onClick={onClick}>
                <Svg.Event className="searched-item-type-svg" />
                <p className="searched-item-type-text">Event</p>
                <Svg.ArrowUpRight className="searched-item-type-svg-arrow" />
            </button>
        );
    }
};

export const textForShow = (searchField, lat, lng, address) => {
    if (lat && lng && address && searchField) {
        return `${searchField} ${address}`;
    } else if (lat && lng) {
        return ` ${address ? address : ''}`;
    } else if (searchField) {
        return searchField;
    } else {
        return 'noTypedText';
    }
};
