import React from 'react';
import { CircularProgress } from '@mui/material';

export const MinLoader = ({ style = {}, color, margin, position, width, height }) => {
    const styles = {
        loaderStyle: {
            position: position ? position : 'relative',
            color: color ? color : 'black',
            width: width ? width : '18px',
            height: height ? height : '18px',
            margin: margin ? margin : '5px 0 0 10px',
        },
    };
    return <CircularProgress style={{ ...styles.loaderStyle, ...style }} />;
};
