export const LOAD_ORGANIZATIONS = 'GET_ORGANIZATION';

export const OrganizationListTypes = {
    LIST: 'LIST',
    MAP: 'MAP',
};

const orgTypes = {
    ALL: 'ALL',
    FOLLOWING: 'FOLLOWING',
};

export const OrgFilterTypes = (info) => [
    { label: 'ALL', value: orgTypes.ALL, selectedValue: info?.orgTypes },
    { label: 'Following', value: orgTypes.FOLLOWING, selectedValue: info?.orgTypes },
];
