import React from 'react';
import { useModal } from 'utils';

export const CancelBtnModal = ({ text }) => {
    const { close } = useModal();
    return (
        <button type="button" onClick={close} className={`cancel_btn_modal`}>
            <span>{text ? text : 'Cancel'}</span>
        </button>
    );
};
