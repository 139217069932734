import { CREATE_RSVP, EDIT_RSVP, GET_CATEGORIES, GET_CURRENT_RSVP, GET_EVENT_BY_ID, GET_EVENT_SPONSORS, GET_EVENTS } from './events.types';

export const getEvents = (params) => {
    return {
        type: GET_EVENTS,
        payload: { params },
    };
};

export const getEventById = (id) => {
    return {
        type: GET_EVENT_BY_ID,
        payload: { id },
    };
};

export const getEventSponsors = (id) => {
    return {
        type: GET_EVENT_SPONSORS,
        payload: { id },
    };
};

/** Rsvp **/

export const createRsvp = (params) => {
    return {
        type: CREATE_RSVP,
        payload: params,
    };
};

export const editRsvp = (params) => {
    return {
        type: EDIT_RSVP,
        payload: params,
    };
};

export const getCurrentRsvp = (id) => {
    return {
        type: GET_CURRENT_RSVP,
        payload: { id },
    };
};

/** End **/

export const getCategories = () => {
    return {
        type: GET_CATEGORIES,
    };
};
