import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { buyTicketStyles } from '../styles';
import { ErrMessage, MinLoader } from 'components';
import { StripeCard } from './stripeCard';
import { httpRequestsOnErrorsActions, ticketActions } from 'store';
import { FindError, FindLoad } from 'utils';
import Radio from '@mui/material/Radio';
import { Svg } from 'assets';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PKEY);

const ACTION_TYPE = 'BUY_TICKET';
export const CompletePayment = ({ selectedTickets, eventId }) => {
    const { currentCard, accessToken } = useSelector((state) => ({
        currentCard: state.payments.currentCard,
        accessToken: state.auth.accessToken,
    }));
    const token = accessToken ? accessToken : typeof window !== 'undefined' && localStorage.getItem('access-token');
    const classes = buyTicketStyles();
    const [checked, setChecked] = useState(null);
    const dispatch = useDispatch();
    const loader = FindLoad(ACTION_TYPE);
    const backError = FindError(ACTION_TYPE);

    useEffect(() => {
        dispatch(httpRequestsOnErrorsActions.removeError(ACTION_TYPE));
    }, [dispatch]);

    useEffect(() => {
        if (!currentCard) {
            setChecked('newCard');
        }
    }, [currentCard]);

    const handlePay = (email, pmtId) => {
        let ticketList = [];
        selectedTickets?.map((i) =>
            ticketList.push({
                ticketId: i?.id,
                count: i?.count,
            })
        );
        if (token) {
            dispatch(ticketActions.buyTicket({ tickets: ticketList, eventId: eventId, paymentMethod: pmtId }, eventId));
        } else {
            dispatch(
                ticketActions.buyTicketNoToken({ tickets: ticketList, email: email, eventId: eventId, paymentMethod: pmtId }, eventId)
            );
        }
        ticketList = [];
    };

    return (
        <div className="ticket-category-box">
            <div className={classes.spaceBetween}>
                <div>
                    <p className={classes.choseTicketTitle}>Checkout</p>
                    <div className={classes.ticketsWrapper}>
                        <p className={classes.selectTMethodTitle}>Payment Details</p>
                        <p className={classes.savedCardOr}>
                            {currentCard?.card ? 'Choose Your Payment Method: Use Your Saved Card or Add a New One' : ''}
                        </p>

                        {currentCard?.card && (
                            <div
                                style={{ background: checked === 'current' ? '#FAFAFA' : 'transparent' }}
                                className={classes.paymentDetailCardWrapper}
                                onClick={() => setChecked('current')}>
                                <div className={classes.flexAble}>
                                    {currentCard?.card === 'visa' ? <Svg.Visa /> : currentCard?.card === 'mastercard' ? <Svg.Master /> : ''}
                                    <div className={classes.cardInformationWrapper}>
                                        <p className={classes.cardTypeText}>
                                            {currentCard?.card === 'visa'
                                                ? 'Visa Card'
                                                : currentCard?.card === 'mastercard'
                                                ? 'Master Card'
                                                : ''}
                                        </p>
                                        <div className={classes.cardInformationText}>
                                            <p>{`**** **** **** ${currentCard?.last4}`} </p>
                                            <p>{`${currentCard?.exp_month}/${currentCard?.exp_year}`}</p>
                                        </div>
                                    </div>
                                </div>
                                <Radio style={{ color: '#BB3240' }} checked={checked === 'current'} />
                            </div>
                        )}
                        {checked !== 'newCard' && (
                            <div
                                style={{ background: checked === 'newCard' ? '#FAFAFA' : 'transparent' }}
                                className={classes.newCardWrapper}
                                onClick={() => setChecked('newCard')}>
                                <div className={classes.newCardWrapperHead}>
                                    <div className={classes.cardInformationWrapper} style={{ marginLeft: '0' }}>
                                        <p className={classes.cardTypeText}>+ Add New Card</p>
                                    </div>
                                    <Radio style={{ color: '#BB3240' }} checked={checked === 'newCard'} />
                                </div>
                            </div>
                        )}
                        {checked === 'newCard' && (
                            <div style={{ width: '100%' }}>
                                <Elements stripe={stripePromise}>
                                    <StripeCard handlePay={handlePay} currentCard={currentCard} backLoader={loader} />
                                </Elements>
                            </div>
                        )}
                    </div>

                    {backError?.error && <ErrMessage text={backError?.error} />}
                    {checked === 'current' && (
                        <div className={classes.continueBtnWrapper}>
                            <button
                                disabled={!checked}
                                className={classes.completeBtn}
                                style={!checked ? { background: '#E0A1A7' } : {}}
                                onClick={handlePay}>
                                {loader?.length ? <MinLoader /> : 'Complete Payment'}
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
