import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { withStyles } from '@mui/styles';

export const RadioBox = ({ label, handleChange, value, selectedValue }) => {
    return (
        <FormControl>
            <RadioGroup
                onClick={handleChange}
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="female"
                name="radio-buttons-group">
                <FormControlLabel checked={value === selectedValue} value={value} control={<Radio />} label={label} />
            </RadioGroup>
        </FormControl>
    );
};

export const RedRadio = withStyles({
    root: {
        color: '#BB3240',
        '&$checked': {
            color: '#BB3240',
        },
    },
    checked: {},
})((props) => <Radio color="default" {...props} />);
