import { Svg } from 'assets';
import { NavLink } from 'react-router-dom';
import { LinearProgress } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useWindowSize } from 'utils';
import { supportArmOrganization } from './constatnts';


export const SupportOrgs = () => {
    const [progress, setProgress] = useState(0);
    const [isRunning, setIsRunning] = useState(true);
    const [width] = useWindowSize();
    const [index, setIndex] = useState(1);
    const refTimer = useRef();

    useEffect(() => {
        const transitionTime = 5000;
        const progressUpdateInterval = 50;

        if (isRunning) {
            let startTime = Date.now();

            refTimer.current = window.setInterval(() => {
                const elapsedTime = Date.now() - startTime;

                setProgress(Math.min((elapsedTime / transitionTime) * 100, 100));

                if (elapsedTime >= transitionTime) {
                    setIndex((prevIndex) => (prevIndex === 3 ? 1 : prevIndex + 1));
                    startTime = Date.now();
                    setProgress(0);
                }
            }, progressUpdateInterval);
        }

        return () => {
            window.clearInterval(refTimer.current);
        };
    }, [isRunning, index]);

    const toggleTimer = () => {
        setIsRunning((prev) => !prev);

        if (isRunning) {
            setProgress(100);
            window.clearInterval(refTimer.current);
        } else {
            setProgress(0);
        }
    };

    const clickToNextOrPrev = (prop) => {
        if (prop === 'next') {
            setProgress(0);
            window.clearInterval(refTimer.current);
            setIndex((prevIndex) => (prevIndex === 3 ? 1 : prevIndex + 1));
        } else {
            setProgress(0);
            window.clearInterval(refTimer.current);
            setIndex((prevIndex) => (prevIndex === 1 ? 3 : prevIndex - 1));
        }
    };


    return (
        <div className='support-arm-organization'>
            <div className='container'>
                <div className='support-arm-header'>
                    <p className='home-section-title'>
                        {width > 768 ? 'Support Armenian Organizations' : 'Organizations'}
                    </p>

                    <div className='flex-end'>
                        <div className='view-all-btn'>
                            <NavLink to={'/nonprofits'}>
                                {width > 768 ? 'Browse All Organizations' : 'Browse All'}

                                <Svg.ArrowUpRight />
                            </NavLink>
                        </div>
                    </div>
                </div>

                <div className='support-org-slider'>
                    <div className='supprot-org-card'>
                        <div className='org-supported-slider-wrapper-box'
                             style={{ backgroundImage: `url(${supportArmOrganization[index - 1]?.img})` }}
                        >

                            <div className='org-slider-types-box'>
                                <div className='slider-type'>
                                    <LinearProgress color='inherit' variant='determinate' value={progress} />
                                </div>

                                <button onClick={toggleTimer}>
                                    {isRunning ? <Svg.StopBtn /> : <Svg.PlaySvg />}
                                </button>
                            </div>
                        </div>
                        <div className='supprot-org-info'>
                            <div className='supprot-org-info-title_svg'>
                                <p className='support-org-title'>{supportArmOrganization[index - 1]?.title}</p>

                                <button className='supprot-org-info-button_svg'>
                                    <Svg.ArrowUpRight />
                                </button>
                            </div>
                            <p className='support-org-desc'>
                                {supportArmOrganization[index - 1] ?.description}
                            </p>

                            <div className='support-org-categories-wrapper'>
                                {supportArmOrganization[index - 1] ?.tags.map((item,i)=>(
                                    <div key={i} className='support-org-categories'>
                                        <div>{item}</div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>

                <div className='support-org-footer'>
                    <button onClick={() => clickToNextOrPrev()}>
                        <Svg.ArrowLeftLineSvg />
                        Previous
                    </button>

                    <button onClick={() => clickToNextOrPrev('next')}>
                        Next
                        <Svg.DateArrowRight />
                    </button>
                </div>
            </div>
        </div>
    );
};
