import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { TitleAndEdit } from './core';
import { FindLoad, FindSuccess, useModal } from 'utils';
import { Svg } from 'assets';
import { CancelBtn, MinLoader } from 'components';
import { authActions, httpRequestsOnSuccessActions } from 'store';
import { DELETE_ACCOUNT } from './constants';

export const DeleteAccount = ({ user }) => {
    const [showAccordion, setShowAccordion] = useState(false);
    const { openModal, close } = useModal();
    const dispatch = useDispatch();
    const success = FindSuccess(DELETE_ACCOUNT);
    const deleteLoader = FindLoad(DELETE_ACCOUNT);

    useEffect(() => {
        if (success) {
            dispatch(httpRequestsOnSuccessActions.removeSuccess(DELETE_ACCOUNT));
            close();
        }
    }, [success]);

    const handleDelete = () => {
        dispatch(authActions.deleteAccount(user?.id));
    };

    const deleteAccount = () => {
        openModal(
            <div className="delete-account-modal">
                <div className="delete-account-modal-svg">
                    <Svg.ProfileDelete />
                </div>

                <p className="delete-account-modal-title">Are you sure you want to delete your account?</p>
                <p className="delete-account-modal-desc">
                    Deleting your account will permanently remove all your data, including your profile, settings, and any associated
                    information. This action cannot be undone.
                </p>
                <div className="delete-account-modal-actions">
                    <CancelBtn />

                    <button
                        className="delete-account-btn"
                        onClick={handleDelete}
                        style={{ width: '100%', height: '48px', maxWidth: '100%' }}>
                        {deleteLoader ? <MinLoader color={'#FFF'} margin={'0'} /> : 'Delete my account'}
                    </button>
                </div>
            </div>,
            'noPaddings'
        );
    };

    return (
        <div className="user-info-inputs-block">
            <TitleAndEdit
                onClick={() => setShowAccordion((prev) => !prev)}
                isAccordion={true}
                disabled={!showAccordion}
                title={'Delete Account'}
                actionBtns={true}
                desc={
                    'Your account will be closed immediately. Information and settings associated with your account will be permanently deleted.'
                }
                icon={<Svg.ProfileDelete />}
            />
            <div className={`user-info-inputs-wrapper ${!showAccordion ? 'delete-my-account-accordion' : ''}`}>
                <p className="edit-info-desc-mobile">
                    Your account will be closed immediately. Information and settings associated with your account will be permanently
                    deleted.
                </p>

                <button className="delete-account-btn" onClick={deleteAccount}>
                    Delete my account
                </button>
            </div>
        </div>
    );
};
