export const Privacy = () => {
    return (
        <div className="privacy-terms-page">
            <div className="privacy-terms-title-box">
                <div />
                <p className="page-global-title">Privacy Policy</p>
            </div>
            <div className="privacy-term-body-box">
                This privacy statement is applicable to www.armat.org and all its affiliates.
                <br />
                The www.armat.org website ("Armat") is a collection of Armenian businesses, events, and services
                operated by third
                parties. The owners and operators of the site (the “Admin”) own and control the content of Armat.
                <br />
                <p className="privacy-title">Definitions</p>
                <p className="privacy-sub-title">Service</p>
                <ul>
                    <li>
                        Service is the https://www.armat.org/ website operated by the owners and operators of the site.
                        <br />
                        Personal Data
                    </li>
                </ul>
                <p className="privacy-sub-title">Personal Data</p>
                <ul>
                    <li>
                        means data about a living individual who can be identified from those data (or from those and
                        other
                        information either in our possession or likely to come into our possession).
                        <bt />
                    </li>
                </ul>
                <p className="privacy-sub-title">Usage Data</p>
                <ul>
                    <li>
                        Usage Data is data collected automatically either generated by the use of the Service or from
                        the Service
                        infrastructure itself (for example, the duration of a page visit).
                    </li>
                </ul>
                <p className="privacy-sub-title">Cookies</p>
                <ul>
                    <li>Cookies are small files stored on your device (computer or mobile device).</li>
                </ul>
                <p className="privacy-title">Information Collection and Use </p>
                We collect several different types of information for various purposes to provide and improve our
                Service to you.
                <p className="privacy-title-small">Types of Data Collected </p>
                <p className="privacy-title-small-black"> Personal Data </p>
                While using our Service, we may ask you to provide us with certain personally identifiable information
                that can be
                used to contact or identify you ("Personal Data"). Personally identifiable information may include, but
                is not
                limited to:
                <ul>
                    <li>Email address</li>
                    <li> First name and last name</li>
                    <li>Phone number</li>
                    <li>Address, State, Province, ZIP/Postal code, City</li>
                    <li> Cookies and Usage Data</li>
                    <li>demographic information such as postcode, preferences, and interests</li>
                    <li>other information relevant to customer surveys and/or offers</li>
                </ul>
                We may use your Personal Data to contact you with newsletters, marketing or promotional materials, and
                other
                information that may be of interest to you. You may opt-out of receiving any, or all, of these
                communications from
                us by following the unsubscribe link or instructions provided in any email we send or by contacting us.
                <br />
                Upon request, the Company will allow any user to "opt-out" of further promotional contacts at any time.
                Additionally
                upon request, the Company will use reasonable efforts to allow users to update/correct personal
                information
                previously submitted which the user states is erroneous to the extent such activities will not
                compromise privacy or
                security interests. Also, upon a user's request, the Company will use commercially reasonable efforts to
                functionally delete the user and his or her personal information from its database; however, it may be
                impossible to
                delete a user's entry without some residual information because of backups and records of
                deletions. <br />
                Additionally, users should be aware that when they voluntarily disclose personally identifiable
                information (e.g.,
                user name, e-mail address) on the bulletin boards or in the chat areas of the Company’s sites, that
                information,
                along with any substantive information disclosed in the user's communication, can be collected and
                correlated and
                used by third parties and may result in unsolicited messages from other posters or third parties. Such
                activities
                are beyond the control of the Company. <br />
                <p className="privacy-title-small-black">Usage Data</p>
                We may also collect information on how the Service is accessed and used ("Usage Data"). This Usage Data
                may include
                information such as your computer's Internet Protocol address (e.g. IP address), browser type, browser
                version, the
                pages of our Service that you visit, the time and date of your visit, the time spent on those pages,
                unique device
                identifiers and other diagnostic data.
                <p className="privacy-title-small-black">Location Data</p>
                We may use and store information about your location if you give us permission to do so ("Location
                Data"). We use
                this data to provide features of our Service, to improve and customize our Service. <br />
                You can enable or disable location services when you use our Service at any time by way of your device
                settings.{' '}
                <br />
                <p className="privacy-title-small-black">Tracking & Cookies Data</p>
                Users also should be aware that non-personal information and data may be automatically collected through
                the
                standard operation of the Company’s internet servers or through the use of "cookies." Cookies are small
                text files a
                website can use to recognize repeat users, facilitate the user's ongoing access to and use of the site
                and allow a
                site to track usage behavior and compile aggregate data that will allow content improvements and
                targeted
                advertising. Cookies are not programs that come onto a user's system and damage files. Generally,
                cookies work by
                assigning a unique number to the user that has no meaning outside the assigning site. Users should be
                aware that the
                Company cannot or does not control the use of cookies or the resulting information by advertisers or
                third parties
                hosting data for the Company. If a user does not want information collected through the use of cookies,
                there is a
                simple procedure in most browsers that allows the user to deny or accept the cookie feature; however,
                users should
                note that cookies may be necessary to provide the user with certain features (e.g., customized delivery
                of
                information) available on the Company’s site. <br />
                <br />
                We use cookies and similar tracking technologies to track the activity on our Service and we hold
                certain
                information.
                <br />
                <br />
                Cookies are files with a small amount of data which may include an anonymous unique identifier. Cookies
                are sent to
                your browser from a website and stored on your device. Other tracking technologies are also used such as
                beacons,
                tags, and scripts to collect and track information and to improve and analyze our Service.
                <br />
                <br /> You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent.
                However, if
                you do not accept cookies, you may not be able to use some portions of our Service. <br />
                <br />
                Examples of Cookies we use:
                <p>
                    <span style={{ fontWeight: 'bold' }}>Session Cookies.</span> We use SessionCookies to operate our
                    Service.
                </p>
                <p>
                    <span style={{ fontWeight: 'bold' }}>Preference Cookies.</span> We use Preference Cookies to
                    remember your
                    preferences and various settings.
                </p>
                <p>
                    <span style={{ fontWeight: 'bold' }}>Security Cookies.</span> We use Security Cookies for security
                    purposes.
                </p>
                <p className="privacy-title-small"> Use of Data </p>
                <p>The Company uses the collected data for various purposes:</p>
                <ul>
                    <li>To provide and maintain our Service</li>
                    <li>Internal record keeping</li>
                    <li>To notify you about changes to our Service</li>
                    <li>To allow you to participate in interactive features of our Service when you choose to do so</li>
                    <li>To provide customer support</li>
                    <li>To gather analysis or valuable information so that we can improve our Service</li>
                    <li>To monitor the usage of our Service</li>
                    <li>To detect, prevent and address technical issues</li>
                    <li>
                        To provide you with news, special offers, and general information about other goods, services,
                        and events
                        which we offer that are similar to those that you have already purchased or enquired about
                        unless you have
                        opted not to receive such information
                    </li>
                    <li>
                        From time to time, we may also use your information to contact you for market research purposes.
                        We may
                        contact you by email, phone, fax, or mail. We may use the information to customize the website
                        according to
                        your interests.{' '}
                    </li>
                </ul>
                <br />
                Further, notwithstanding any opt-out of promotional information by the user, the Company reserves the
                right to
                contact a subscriber regarding account status, changes to the subscriber agreement, and other matters
                relevant to
                the underlying service and/or the information collected.
                <br />
                <p className="privacy-title">Children's Privacy </p>
                Our Service does not address anyone under the age of 18 ("Children"). <br />
                <br />
                We do not knowingly collect personally identifiable information from anyone under the age of 18. <br />
                <br />
                While the Company does not anticipate a large number of children accessing our website, we are committed
                to
                protecting the privacy of children who use our sites and applications. Our Company has established
                practices
                compliant with the U.S. Children’s Online Privacy Protection Act (“COPPA”) regarding children’s personal
                information. <br />
                <br />
                If you are a parent or guardian and you are aware that your Child has provided us with Personal Data,
                please
                <a href="mailto:admin@armat.org">contact us.</a> If we become aware that we have collected Personal Data
                from
                children without verification of parental consent, we take steps to remove that information from our
                servers.
                <br />
                <br />
                <p className="privacy-title">Transfer of Data </p>
                Your information, including Personal Data, may be transferred to - and maintained on - computers located
                outside of
                your state, province, country, or other governmental jurisdiction where the data protection laws may
                differ from
                those of your jurisdiction.
                <br />
                <br />
                If you are located outside the United States and choose to provide information to us, please note that
                we transfer
                the data, including Personal Data, to the United States and process it there.
                <br />
                <br />
                Your consent to this Privacy Policy followed by your submission of such information represents your
                agreement to
                that transfer. <br />
                <br />
                The Company will take all the steps reasonably necessary to ensure that your data is treated securely
                and in
                accordance with this Privacy Policy and no transfer of your Personal Data will take place to an
                organization or a
                country unless there are adequate controls in place including the security of your data and other
                personal
                information.
                <br />
                <br />
                <p className="privacy-title"> Disclosure of Data </p>
                <p className="privacy-title-small"> Business Transaction </p>
                If the Company is involved in a merger, acquisition, or asset sale, your Personal Data may be
                transferred. We will
                provide notice before your Personal Data is transferred and becomes subject to a different Privacy
                Policy. <br />
                <br />
                <p className="privacy-title-small"> Disclosure for Law Enforcement </p>
                Under certain circumstances, the Company may be required to disclose your Personal Data if required to
                do so by law
                or in response to valid requests by public authorities (e.g. a court or a government agency). <br />
                <p className="privacy-title-small"> Legal Requirements </p>
                The Company may disclose user information in special cases when we have reason to believe that
                disclosing this
                information is necessary to identify, contact or bring legal action against someone who may be causing
                injury to or
                interference with (either intentionally or unintentionally) the Company’s rights or property, other
                Company users,
                or anyone else that could be harmed by such activities. The Company may disclose user information when
                we believe in
                good faith that the law requires it. <br />
                <br />
                The Company may disclose your Personal Data in the good faith belief that such action is necessary to:
                <br />
                <ul>
                    <li> To comply with a legal obligation</li>
                    <li>To protect and defend the rights or property of the Company.</li>
                    <li>To prevent or investigate possible wrongdoing in connection with the Service</li>
                    <li> To protect the personal safety of users of the Service or the public</li>
                    <li> To protect against legal liability</li>
                </ul>
                <p className="privacy-title">
                    {' '}
                    Our Policy on "Do Not Track" Signals under the California Online Protection Act (CalOPPA)
                </p>
                We do not support Do Not Track ("DNT"). Do Not Track is a preference you can set in your web browser to
                inform
                websites that you do not want to be tracked.
                <br />
                <br />
                You can enable or disable Do Not Track by visiting the Preferences or Settings page of your web
                browser. <br />
                <br />
                <p className="privacy-title">Service Providers </p>
                We may employ third-party companies and individuals to facilitate our Service ("Service Providers"),
                provide the
                Service on our behalf, perform Service-related services or assist us in analyzing how our Service is
                used.
                <br />
                <br /> These third parties have access to your Personal Data only to perform these tasks on our behalf
                and are
                obligated not to disclose or use it for any other purpose.
                <br />
                <br />
                <p className="privacy-title-small">Analytics </p>
                The Company reserve the right to perform statistical analyses of user behavior and characteristics in
                order to
                measure interest in and use of the various areas of the site and to inform advertisers of such
                information as well
                as the number of users that have been exposed to or clicked on their advertising banners. The Company
                will provide
                only aggregated data from these analyses to third parties. Also, users should be aware that the Company
                may
                sometimes permit third parties to offer subscription and/or registration-based services through the
                Company’s site.
                The Company are not responsible for any actions or policies of such third parties and users should check
                the
                applicable privacy policy of such party when providing personally identifiable information.
                <br />
                <br />
                <p className="privacy-title-small-black">Tawk.to</p>
                Our site offers the Tawk.to online chat tool, a live chat solution provided by Tawk.to Ltd, so you can
                instantly
                communicate with our team if you have any questions. Tawk.to uses cookies to help us identify and track
                visitors who
                communicate with us. All communications between us and our visitors in this chat are secured by an SSL
                connection.
                The conversation log can be saved with your personal profile. <br />
                Its use implies the consent of their terms and conditions and their privacy policy, in addition to ours.
                <br />
                <br />
                You can find all this information about Tawk.to on these pages:
                <br />
                <a href="https://www.tawk.to/privacy-policy/" target={'_blank'} rel="noreferrer">
                    {' '}
                    Privacy Policy
                </a>
                <a href="https://www.tawk.to/terms-of-service/" target={'_blank'} rel="noreferrer">
                    {' '}
                    General terms and conditions{' '}
                </a>
                <a href="https://www.tawk.to/data-protection" target={'_blank'} rel="noreferrer">
                    {' '}
                    Data protection and privacy{' '}
                </a>
                <p className="privacy-title-small-black">Google Analytics</p>
                armat.org is sharing personally identifiable information with Google Analytics (acting as its site
                traffic gathering
                agent for this limited purpose) for the sole purpose of gathering statistical data on visitors to the
                site and the
                pages viewed by those visitors. If you wish to opt-out,
                <a href={'https://www.google.com/analytics/'} target={'_blank'} rel="noreferrer">
                    {' '}
                    visit Google Analytics' privacy center.
                </a>
                <p className="privacy-title-small-black">Hotjar</p>
                armat.org uses Hotjar in order to better understand our users’ needs and to optimize this service and
                experience.
                Hotjar is a technology service that helps us better understand our users’ experience (e.g. how much time
                they spend
                on which pages, which links they choose to click, what users do and don’t like, etc.) and this enables
                us to build
                and maintain our service with user feedback. Hotjar uses cookies and other technologies to collect data
                on our
                users’ behavior and their devices (in particular device's IP address (captured and stored only in
                anonymized form),
                device screen size, device type (unique device identifiers), browser information, geographic location
                (country
                only), preferred language used to display our website). Hotjar stores this information in a
                pseudonymized user
                profile. Neither Hotjar nor we will ever use this information to identify individual users or to match
                it with
                further data on an individual user. For further details, please see Hotjar’s privacy policy by clicking
                on this
                link. You can opt-out to the creation of a user profile, Hotjar’s storing of data about your usage of
                our site, and
                Hotjar’s use of tracking cookies on other websites by following
                <a href={'https://www.hotjar.com/legal/policies/do-not-track/'} target={'_blank'} rel="noreferrer">
                    {' '}
                    this opt-out link.
                </a>
                <p className="privacy-title-small">Payments</p>
                We may provide paid products and/or services within the Service. In that case, we use third-party
                services for
                payment processing (e.g. payment processors).
                <br />
                <br />
                We will not store or collect your payment card details. That information is provided directly to our
                third-party
                payment processors whose use of your personal information is governed by their Privacy Policy. These
                payment
                processors adhere to the standards set by PCI-DSS as managed by the PCI Security Standards Council,
                which is a joint
                effort of brands like Visa, MasterCard, American Express, and Discover. PCI-DSS requirements help ensure
                the secure
                handling of payment information. <br />
                <br />
                The payment processors we work with are: <br />
                <p className="privacy-title-small-black">Stripe</p>
                <ul>
                    <li>
                        {' '}
                        Their Privacy Policy can be viewed{' '}
                        <a href="https://stripe.com/us/privacy" target={'_blank'} rel="noreferrer">
                            here.
                        </a>
                    </li>
                </ul>
                <p className="privacy-title-small-black">PayPal / Braintree </p>
                <ul>
                    <li>
                        {' '}
                        Their Privacy Policy can be viewed{' '}
                        <a href="https://www.paypal.com/webapps/mpp/ua/privacy-full" target={'_blank'} rel="noreferrer">
                            here.
                        </a>
                    </li>
                </ul>
                <p className="privacy-title-small-black">Amazon Pay </p>
                <ul>
                    <li>
                        {' '}
                        Their Privacy Policy can be viewed{' '}
                        <a href="https://pay.amazon.com/help/201751600" target={'_blank'} rel="noreferrer">
                            here.
                        </a>
                    </li>
                </ul>
                <p className="privacy-title-small-black"> Apple Pay </p>
                <ul>
                    <li>
                        {' '}
                        Their Privacy Policy can be viewed{' '}
                        <a href="https://support.apple.com/en-us/HT210665" target={'_blank'} rel="noreferrer">
                            here.
                        </a>
                    </li>
                </ul>
                <p className="privacy-title-small-black"> Google Pay </p>
                <ul>
                    <li>
                        {' '}
                        Their Privacy Policy can be viewed{' '}
                        <a href="https://support.google.com/googlepay/answer/10253883?hl=en" target={'_blank'}
                           rel="noreferrer">
                            here.
                        </a>
                    </li>
                </ul>
                <p className="privacy-title"> Links to Other Sites </p>
                Our Service may contain links to other sites that are not operated by us. If you click a third-party
                link, you will
                be directed to that third party's site. We strongly advise you to review the Privacy Policy of every
                site you visit.
                <br />
                <br />
                We have no control over and assume no responsibility for the content, privacy policies, or practices of
                any
                third-party sites or services. <br />
                <br />
                <p className="privacy-sub-title"> Behavioral Remarketing </p>
                Armat uses remarketing services to advertise on third party websites to you after you visited our
                Service. We and
                our third-party vendors use cookies to inform, optimize and serve ads based on your past visits to our
                Service.
                <br />
                <br />
                <p className="privacy-title-small-black"> Google Ads (AdWords) </p>
                Google Ads (AdWords) remarketing service is provided by Google Inc. <br />
                <br />
                You can opt-out of Google Analytics for Display Advertising and customize the Google Display Network ads
                by visiting
                the
                <a href="https://www.google.com/settings/ads" target={'_blank'} rel="noreferrer">
                    Google Ads Settings page.
                </a>
                Google also recommends installing the
                <a href="https://tools.google.com/dlpage/gaoptout" target={'_blank'} rel="noreferrer">
                    Google Analytics Opt-out Browser Add-on
                </a>
                your web browser. Google Analytics Opt-out Browser Add-on provides visitors with the ability to prevent
                their data
                from being collected and used by Google Analytics.
                <br />
                For more information on the privacy practices of Google, please visit the
                <a href="https://policies.google.com/privacy?hl=en" target={'_blank'} rel="noreferrer">
                    {' '}
                    Google Privacy & Terms
                </a>
                web page.
                <p className="privacy-title-small-black"> Facebook </p>
                Facebook remarketing service is provided by Facebook Inc.
                <br />
                You can learn more about interest-based advertising from Facebook by visiting
                <a href="https://www.facebook.com/help/164968693837950" target={'_blank'} rel="noreferrer">
                    this page.
                </a>
                To opt-out from Facebook's interest-based ads,
                <a href="https://www.facebook.com/help/568137493302217" target={'_blank'} rel="noreferrer">
                    {' '}
                    follow these instructions from Facebook.
                </a>
                <br />
                Facebook adheres to the Self-Regulatory Principles for Online Behavioral Advertising established by the
                Digital
                Advertising Alliance. You can also opt-out from Facebook and other participating companies through the
                <a href="https://www.aboutads.info/choices/" target={'_blank'} rel="noreferrer">
                    {' '}
                    Digital Advertising Alliance in the USA,{' '}
                </a>
                the
                <a href="https://youradchoices.ca/" target={'_blank'} rel="noreferrer">
                    {' '}
                    Digital Advertising Alliance of Canada in Canada{' '}
                </a>
                or
                <a href="https://www.youronlinechoices.eu/" target={'_blank'} rel="noreferrer">
                    {' '}
                    European Interactive Digital Advertising Alliance in Europe,
                </a>
                the or opt-out using your mobile device settings.
                <br />
                <br />
                For more information on the privacy practices of Facebook, please visit
                <a href="https://www.facebook.com/privacy/explanation" target={'_blank'} rel="noreferrer">
                    {' '}
                    Facebook's Data Policy.
                </a>
                <br />
                The foregoing policies are effective as of March 25, 2021. The Company reserves the right to change this
                policy at
                any time by notifying users of the existence of a new privacy statement. This statement and the policies
                outlined
                herein are not intended to and do not create any contractual or other legal rights in or on behalf of
                any party.
                <br />
                <br />
                By using this site, you signify your assent to the Company’s Privacy Policy. If you do not agree to this
                policy,
                please do not use our sites. Your continued use of the Company’s sites following the posting of changes
                to these
                terms will mean you accept those changes.
                <br />
                <br />
                If you have questions or concerns regarding this Web site's privacy statement,
                <a href="mailto:admin@armat.org"> contact the Company.</a>
                <br />
                As our Company is headquartered in the United States, we adhere most closely with applicable federal and
                state laws.
                We do, however, value our non-US users. The Company has implemented policies to adhere to the European
                Union’s
                General Data Protection Regulation (“GDPR”) which includes strict data protection principles that
                organizations must
                follow in order to protect the personal information they collect about their clients or people who visit
                their
                websites. While many rules and actions may be the same in the US and EU, there may be specific instances
                of policy
                differences. If you are concerned about how your personal information is being collected in connection
                with GDPR,
                please use this contact information
                <a href="mailto:admin@armat.org"> and reach out to the Company.</a>
                <br />
                <br />
                <p className="privacy-sub-title">Security</p>
                We are committed to ensuring that your information is secure. In order to prevent unauthorized access or
                disclosure,
                we have put in place suitable physical, electronic, and managerial procedures to safeguard and secure
                the
                information we collect online. The security of your data is important to us but remember that no method
                of
                transmission over the Internet or method of electronic storage is 100% secure. While we strive to use
                commercially
                acceptable means to protect your Personal Data, we cannot guarantee its absolute security.
            </div>
        </div>
    );
};