import { Rating } from '@mui/material';
import React from 'react';

export const ReviewsModal = ({ type, info }) => {
    let text = type === 'Google' ? 'Google Reviews' : type === 'Yelp' ? 'Yelp Reviews' : '';

    return (
        <div className="review-modal">
            <div>
                <p className="review-modal-title">{text}</p>
                <div className="stars-box">
                    <p>{`Reviews (${info?.numReviews})`}</p>
                    <Rating name="size-medium" defaultValue={info?.rating} readOnly={true} />
                </div>

                <div className="review-cards-body">
                    {info.reviews &&
                        info.reviews.map((i, j) => (
                            <div key={j} className="review-card-wrapper">
                                <div className="review-card-box">
                                    <div className="reviewer-image">
                                        {/*<Icon name={SVGNames.UserAvatarFill} color={Colors.ThemeGreen} width={'45px'} height={'45px'} />*/}
                                    </div>
                                    <div>
                                        <p className="reviewer-name">{i.authorName}</p>
                                        <Rating name="size-medium" defaultValue={i.rating} readOnly={true} />
                                        <p className="reviewer-desc"> {i.text}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                </div>
            </div>
        </div>
    );
};
