import { MyProfileImage } from './fragments/myProfileImage';
import { MyProfileInformation } from './fragments/myProfileInformation';

export const MyProfile = () => {
    return (
        <div className="my-profile-page">
            <MyProfileImage />
            <MyProfileInformation />
        </div>
    );
};
