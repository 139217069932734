import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { TitleAndEdit } from './core';
import { Svg } from 'assets';
import { CheckPassword, InputMain, MinLoader } from 'components';
import { authActions, httpRequestsOnErrorsActions, httpRequestsOnSuccessActions } from 'store';
import { CHANGE_PASSWORD, EDIT_ACCOUNT } from './constants';
import { FindError, FindLoad, FindSuccess, useWindowSize } from 'utils';
import { checkPassword } from '../../../fragments/auth/constants';

export const Privacy = ({ user }) => {
    const { handleSubmit, control, setError, reset, watch } = useForm({
        defaultValues: {
            fullName: user?.fullName,
            email: user?.email,
            phoneNumber: user?.phoneNumber,
            confirmPassword: '',
        },
    });
    const error = FindError(CHANGE_PASSWORD);
    const dispatch = useDispatch();
    const [disabled, setDisabled] = useState(true);
    const [showPassword, setShowPassword] = useState({
        password: false,
        newPassword: false,
        confirmation: false,
    });

    const [width] = useWindowSize();
    const loader = FindLoad(CHANGE_PASSWORD);
    const success = FindSuccess(CHANGE_PASSWORD);

    useEffect(() => {
        if (success) {
            dispatch(httpRequestsOnSuccessActions.removeSuccess(EDIT_ACCOUNT));
            setDisabled(true);
        }
    }, [success]);

    useEffect(() => {
        if (error?.error === 'user password does not match') {
            setError('password', {
                message: 'user password does not match',
            });
            dispatch(httpRequestsOnErrorsActions.removeError(CHANGE_PASSWORD));
        }
    }, [error]);
    const onSubmit = (info) => {
        if (info?.newPassword === info?.confirmation) {
            dispatch(authActions.changePassword(info));
        } else {
            setError('confirmation', {
                message: 'Password and confirm password does not match',
            });
        }
    };

    return (
        <div>
            <form onSubmit={handleSubmit(onSubmit)} className='user-info-inputs-block'>
                <TitleAndEdit
                    user={user}
                    reset={reset}
                    disabled={disabled}
                    desc={
                        disabled
                            ? 'Change your password to keep your account secure.'
                            : 'Your new password should have at least 8 characters, including 1 uppercase letter and 1 digit.'
                    }
                    actionType={CHANGE_PASSWORD}
                    title={'Privacy'}
                    setDisabled={setDisabled}
                    icon={<Svg.Privacy />}
                />

                <div className={`user-info-inputs-wrapper ${!disabled ? 'user-info-inputs-no-gap' : ''}`}>
                    {disabled ? (
                        <div className='user-info-input-box'>
                            <div className='input-info'>
                                <Svg.Password />
                                <p>Password</p>
                            </div>

                            <p className='user-info-text'>{user?.password ? user?.password : '*********'}</p>
                        </div>
                    ) : (
                        <>
                            <p className='edit-info-desc-mobile'>Change your password to keep your account secure.</p>

                            <div className='bottom-border-16'>
                                <InputMain
                                    name='password'
                                    type='password'
                                    showPassword={showPassword.newPassword}
                                    control={control}
                                    placeholder='Enter your current password'
                                    labelHeader='Current Password'
                                    rules={{
                                        required: 'This Field is required',
                                    }}
                                    onClickIconEnd={() => setShowPassword('newPassword')}
                                    startAdornment={<Svg.Lock />}
                                />
                            </div>

                            <div className='bottom-border-16' style={{ paddingTop: '16px' }}>
                                <InputMain
                                    name='newPassword'
                                    type='password'
                                    showPassword={showPassword.newPassword}
                                    control={control}
                                    placeholder='Enter your new password'
                                    labelHeader='New Password'
                                    rules={{
                                        required: 'This Field is required',
                                    }}
                                    onClickIconEnd={() => setShowPassword('newPassword')}
                                    startAdornment={<Svg.Lock />}
                                />

                                {watch('newPassword') && (
                                    <div className='padding-bottom-16'>
                                        <CheckPassword
                                            fields={checkPassword}
                                            text={watch('newPassword')}
                                            isActive={watch('newPassword')}
                                        />
                                    </div>
                                )}
                            </div>

                            <div className='padding-top-16'>
                                <InputMain
                                    name='confirmation'
                                    type='password'
                                    showPassword={showPassword.confirmation}
                                    control={control}
                                    placeholder='Confirm new password'
                                    labelHeader='Confirm Password'
                                    rules={{
                                        required: 'This Field is required',
                                    }}
                                    onClickIconEnd={() => setShowPassword('confirmation')}
                                    startAdornment={<Svg.Lock />}
                                />
                            </div>

                            {!disabled && width < 768 && (
                                <div className='save-cancel-btns'>
                                    <button type='button' onClick={() => setDisabled(true)} className='cancel-info-btn'>
                                        Cancel
                                    </button>

                                    <button type='submit' className='save-info-btn'>
                                        {loader ? <MinLoader color={'#FFF'} margin={'0'} /> : 'Save'}
                                    </button>
                                </div>
                            )}
                        </>
                    )}
                </div>
            </form>
        </div>
    );
};
