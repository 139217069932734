import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import InputMask from 'react-input-mask';
import { ButtonMain, CheckPassword, InputMain } from 'components';
import { InputAdornment, TextField } from '@mui/material';
import { LogIn } from './logIn';
import { EmailValidator, FindError, FindSuccess, PasswordValidator2, useModal } from 'utils';
import { authActions, httpRequestsOnErrorsActions, httpRequestsOnSuccessActions } from 'store';
import { checkPassword } from './constants';
import { Svg } from 'assets';

const ACTION_TYPE = 'SIGN_UP';

export const SignUp = () => {
    const dispatch = useDispatch();
    const { openModal, close } = useModal();
    const backError = FindError(ACTION_TYPE);
    const success = FindSuccess(ACTION_TYPE);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const {
        handleSubmit,
        control,
        setError,
        reset,
        watch,
        formState: { errors },
    } = useForm({});

    console.log(errors);

    useEffect(() => {
        reset();
    }, []);

    useEffect(() => {
        if (success) {
            close();
            dispatch(httpRequestsOnSuccessActions.removeSuccess(ACTION_TYPE));
        }
    }, [success]);

    useEffect(() => {
        dispatch(httpRequestsOnErrorsActions.removeError(ACTION_TYPE));
        if (backError?.error === 'Such user does not exist in our records') {
            setError('email', {
                type: 'required',
                message: 'Such user does not exist in our records',
            });
        }
        if (backError?.error === 'User with this email or phone number exists') {
            setError('email', {
                type: 'required',
                message: 'User with this email exists',
            });
        }
        if (backError?.error === 'User password does not match') {
            setError('password', {
                type: 'required',
                message: 'User password does not match',
            });
        }
    }, [backError]);

    const onSubmit = (data) => {
        if (data?.password === data?.confirmPassword) {
            if (data?.fullName?.length < 3) {
                setError('fullName', {
                    type: 'required',
                    message: 'FullName must be longer than or equal to 3 characters',
                });
            } else {
                const params = {
                    ...data,
                };
                phoneNumber ? (params.phoneNumber = parseInt(phoneNumber.replace(/[^0-9]/g, ''))) : delete params.phoneNumber;
                dispatch(authActions.signUp(params));
            }
        } else {
            setError('confirmPassword', {
                type: 'required',
                message: 'Password does not match with the confirmation',
            });
        }
    };

    const handleClickIcon = () => {
        setShowPassword((prev) => !prev);
    };

    return (
        <div className="login-modal-box">
            <div className="login-svg-block">
                <Svg.SignUpSvg />
            </div>

            <h3 className="auth-modal-title">Sign Up</h3>
            <h3 className="welcome-back">Welcome! Please enter your details.</h3>
            <form className="form-box" onSubmit={handleSubmit(onSubmit)}>
                <InputMain
                    name="fullName"
                    type="text"
                    control={control}
                    placeholder="Full Name"
                    labelHeader="Full Name"
                    maxLength={20}
                    rules={{
                        required: 'This Field is required',
                    }}
                    startAdornment={<Svg.Profile className={errors?.fullName ? 'error-fullName-icon' : ''} />}
                />

                <InputMain
                    name="email"
                    type="email"
                    control={control}
                    placeholder="Email"
                    labelHeader="Email Address"
                    rules={{
                        required: 'This Field is required',
                        pattern: {
                            value: EmailValidator,
                            message: 'Must be valid email',
                        },
                    }}
                    startAdornment={<Svg.Sms />}
                />

                <div className={`input-main-wrapper input-custom-wrapper`}>
                    <p className={`input-main-label-header`}>Phone Number</p>
                    <InputMask
                        maskChar=""
                        mask="(999) 999-9999"
                        name={'phoneNumber'}
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e?.target?.value)}>
                        {() => (
                            <TextField
                                autoComplete="on"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Svg.CallCalling />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        )}
                    </InputMask>
                    <p className="error-message">{''}</p>
                </div>
                <InputMain
                    name="password"
                    type="password"
                    showPassword={showPassword}
                    control={control}
                    placeholder="Password"
                    labelHeader="Password"
                    maxLength={20}
                    rules={{
                        required: 'This Field is required',
                        pattern: {
                            value: PasswordValidator2,
                            message: 'Not valid password',
                        },
                    }}
                    onClickIconEnd={handleClickIcon}
                    startAdornment={<Svg.Lock />}
                />
                <InputMain
                    name="confirmPassword"
                    type="password"
                    showPassword={showPassword}
                    control={control}
                    placeholder="Confirm Password"
                    labelHeader="Confirm Password"
                    maxLength={20}
                    rules={{
                        required: 'This Field is required',
                    }}
                    onClickIconEnd={handleClickIcon}
                    startAdornment={<Svg.Lock />}
                />

                <CheckPassword fields={checkPassword} text={watch('password')} isActive={watch('password')} />

                <ButtonMain actionTye={ACTION_TYPE} text="Sign Up" type="submit" />
            </form>

            <div className="dont-have" style={{ marginTop: '32px' }}>
                <span>Already have an Armat account?</span>
                <button onClick={() => openModal(<LogIn />)}>Sign In</button>
            </div>

            <p className="agree-terms">
                By signing up, you agree to{' '}
                <a href="/term-condition" target="_blank">
                    Terms of Use
                </a>{' '}
                and{' '}
                <a href="/privacy-policy" target="_blank">
                    Privacy Policy
                </a>
            </p>
        </div>
    );
};
