import React from 'react';
import { Svg } from 'assets';
import { PhoneRow, TextRow } from 'components';
import { renderTypeAndIcon } from './constants';
import { renderLink } from 'utils';

export const SearchedItems = ({ item, boxShadow }) => {
    const handleClick = () => {
        window.open(renderLink(item), '_blank');
    };

    return (
        <div className="searched-item-box" style={boxShadow ? { boxShadow: '0px 0px 14.87px 0px rgba(208, 214, 220, 0.60' } : {}}>
            <div className="search-card-information-box">
                <div className="search-card-image">{item?.image ? <img src={item?.image?.url} alt="icon" /> : <Svg.Graphic />}</div>

                <div className="search-card-information">
                    <div className="searched-item-title">
                        <TextRow name={item?.name} />

                        {renderTypeAndIcon(item?.type, handleClick)}
                    </div>

                    <div className="searched-info-box-desktop">
                        {item?.address?.formattedAddress && (
                            <div className="searched-info-box">
                                <div className="svg">
                                    <Svg.Location />
                                </div>
                                <a
                                    target={'_blank'}
                                    href={`https://www.google.com/maps?saddr=My+Location&daddr=${item?.address?.lat},${item?.address?.lng}`}
                                    rel="noreferrer">
                                    <TextRow name={item?.address?.formattedAddress} />
                                </a>
                            </div>
                        )}
                        {item?.phoneNumber && (
                            <div className="searched-info-box">
                                <div className="svg">
                                    <Svg.CallBlack />
                                </div>
                                <a href={`tel:+${item?.phoneNumber}`}>
                                    <PhoneRow phone={item?.phoneNumber} />
                                </a>
                            </div>
                        )}
                        {item?.email && (
                            <div className="searched-info-box">
                                <div className="svg">
                                    <Svg.SmsBlack />
                                </div>
                                <a href={`mailto:${item?.email}`}>{item?.email}</a>
                            </div>
                        )}
                    </div>

                    {item?.address?.formattedAddress && (
                        <div className="searched-info-box-mobile">
                            <div className="searched-info-box">
                                <a
                                    target={'_blank'}
                                    href={`https://www.google.com/maps?saddr=My+Location&daddr=${item?.address?.lat},${item?.address?.lng}`}
                                    rel="noreferrer">
                                    <TextRow name={item?.address?.formattedAddress} />
                                </a>
                            </div>
                        </div>
                    )}
                </div>
            </div>

            <div className="searched-detail-box-mobile">
                {item?.phoneNumber && (
                    <div className="searched-info-box">
                        <div className="svg">
                            <Svg.CallBlack />
                        </div>
                        <a href={`tel:+${item?.phoneNumber}`}>
                            <PhoneRow phone={item?.phoneNumber} />
                        </a>
                    </div>
                )}
                {item?.email && (
                    <div className="searched-info-box">
                        <div className="svg">
                            <Svg.SmsBlack />
                        </div>
                        <a href={`mailto:${item?.email}`}>{item?.email}</a>
                    </div>
                )}
            </div>
        </div>
    );
};
