export const shortDayNames = (name) => {
    switch (name) {
        case 'monday':
            return 'Mon';
        case 'tuesday':
            return 'tue';
        case 'wednesday':
            return 'wed';
        case 'thursday':
            return 'thu';
        case 'friday':
            return 'fri';
        case 'saturday':
            return 'sat';
        case 'sunday':
            return 'sun';
    }
};

export const CLAIM_LOAD = 'CLAIMS';
export const FOLLOW = 'FOLLOW';
export const UN_FOLLOW = 'UNFOLLOW';

export const canManageOrg = (user, info, orgAdmins) => {
    let search = orgAdmins?.members?.find((member) => member?.userId === user?.id);
    if (!info || !user) return false;
    if (user.auth?.role === 'ADMIN') return true;
    if (search) return true;
    return false;
};
export const canManageOrgNoAdmin = (user, info, orgAdmins) => {
    let search = orgAdmins?.members?.find((member) => member?.userId === user?.id);
    if (!info || !user) return false;
    if (search) return true;
    return false;
};

// export let orgEventsLideSettings = {
//     dots: true,
//     infinite: true,
//     button: false,
//     speed: 500,
//     slidesToShow: 3,
//     slidesToScroll: 1,
//     responsive: [
//         {
//             breakpoint: 1440,
//             settings: {
//                 slidesToShow: 2.5,
//                 slidesToScroll: 2.5,
//             },
//         },
//         {
//             breakpoint: 1280,
//             settings: {
//                 slidesToShow: 2.2,
//                 slidesToScroll: 2.2,
//                 arrows: false,
//             },
//         },
//         {
//             breakpoint: 1000,
//             settings: {
//                 slidesToShow: 2,
//                 slidesToScroll: 2,
//                 arrows: false,
//             },
//         },
//         {
//             breakpoint: 768,
//             settings: {
//                 slidesToShow: 1.2,
//                 slidesToScroll: 1.2,
//                 arrows: false,
//             },
//         },
//         {
//             breakpoint: 450,
//             settings: {
//                 slidesToShow: 1,
//                 slidesToScroll: 1,
//                 arrows: false,
//             },
//         },
//     ],
// };

export const orgEventsLideSettings = {
    desktop: {
        breakpoint: { max: 3000, min: 1279 },
        items: 3,
    },
    tablet: {
        breakpoint: { max: 1024, min: 768 },
        items: 2,
    },
    mobile: {
        breakpoint: { max: 768, min: 0 },
        items: 1,
    },
};
