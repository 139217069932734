import React from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import { SaveParams } from 'utils';
import { organizationsStyle } from './style';
import CheckboxTree from './CheckboxTree';

export const OrganizerCateg = ({ type }) => {
    const { orgCategories } = useSelector((state) => ({
        orgCategories: state.orgs.orgCategories,
    }));
    const classes = organizationsStyle();
    const navigate = useNavigate();
    const location = useLocation();
    const info = location?.state;

    const handleNode = (node) => {
        window.scrollTo({ top: 0 });
        const params = {
            ...info,
        };
        node?.length ? (params.categories = [...node]) : delete params.categories;
        params.limit = 9;
        params.skip = 0;
        delete params.page;
        SaveParams(location?.pathname, navigate, params);
    };

    const translateTree = (data, type) => {
        const newTree = [];
        for (let i = 0; i < data.length; i++) {
            const checkNonProfit = data[i]?.nonProfitUsedCount > 0;
            const checkBusiness = data[i]?.businessUsedCount > 0;
            const check = type === 'BUSINESS' ? checkBusiness : checkNonProfit;

            if (check) {
                let children;
                if (data[i].items?.length > 0) {
                    children = translateTree(data[i].items, type);
                }
                let label;
                if (type === 'BUSINESS') {
                    label = `${data[i].text} (${data[i].businessUsedCount})`;
                } else {
                    label = `${data[i].text} (${data[i].nonProfitUsedCount})`;
                }

                newTree.push({
                    value: data[i].id,
                    label,
                    children,
                });
            }
        }
        return newTree;
    };

    return (
        <div>
            <div className="App">
                <CheckboxTree
                    selectedId={info?.categories}
                    orgCateg={translateTree(orgCategories, type)}
                    handleNode={handleNode}
                    classes={classes}
                />
            </div>
        </div>
    );
};
