import { AboutBusinessList } from './constatnts';
import { NavLink } from 'react-router-dom';
import { Svg } from 'assets';

export const AboutBusiness = () => {
    return (
        <div className="about-business-section">
            <div className="about-card-wrapper container">
                {AboutBusinessList?.map((i, j) => (
                    <div key={j} className="about-card-box">
                        <div>{i?.icon}</div>
                        <p className="title">{i?.title}</p>
                        <p className="description">{i?.subTitle}</p>

                        <div className="link-btn">
                            <NavLink to={i?.link}>
                                Learn More
                                <Svg.ArrowRightLine />
                            </NavLink>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};
