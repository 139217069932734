import { makeStyles } from '@mui/styles';

export const orgStyles = makeStyles(() => ({
    dragDropImage: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        background: '#F7F8FA',
        border: '1px dashed #E1E1E1',
        borderRadius: '12px',
        width: '100%',
        padding: '24px',

        '@media (min-width: 768px)': {
            // width: '516px',
            minHeight: '280px',
        },
        '@media (min-width: 1279px)': {
            // width: '952px',
            minHeight: '165px',
        },
    },
    carouselWidth: {
        width: '200px !important',

        '@media (min-width: 768px)': {
            width: '420px !important',
        },
        '@media (min-width: 1439px)': {
            width: '800px !important',
        },
    },

    carouselWidthOrg: {
        width: '100% !important',
        // width: '200px !important',

        // '@media (min-width: 768px)': {
        //     width: '420px !important',
        // },
        // '@media (min-width: 1439px)': {
        //     width: '720px !important',
        // },
    },
    leftButton: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '32px',
        height: '32px',
        borderRadius: '4px',
        border: 'none',
        cursor: 'pointer',
    },

    selectedImageBorder: {
        border: '2px solid #387DFF',
        borderRadius: '25px',
        padding: '6px',
        marginRight: '16px',

        '@media (min-width: 320px)': {
            width: '127px',
        },
        '@media (min-width: 768px)': {
            width: '165px',
            height: '165px',
        },
        '@media (min-width: 1439px)': {
            height: '205px',
            width: '230px',
        },
    },

    CloseButtonContent: {
        display: 'flex',
        justifyContent: 'flex-end',
    },

    CloseButton: {
        minWidth: '30px',
        height: '32px',
        background: 'transparent',
        borderRadius: '24px',
        margin: '8px 8px 0 0',
        border: 'none',
        // padding: '4px',
        zIndex: '10',
        cursor: 'pointer',
        '@media (min-width: 768px)': {
            margin: '8px 0 0 0',
        },
        '@media (min-width: 1240px)': {
            margin: '8px 8px 0 0',
        },
    },

    mainImage: {
        position: 'absolute',
        width: '90px',
        height: '30px',
        background: '#FFFFFF 0% 0% no-repeat padding-box',
        borderRadius: '8px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '14px',
        fontWeight: 600,
        margin: '8px 0 0 10px',

        '@media (min-width: 768px)': {
            width: '80px',
            height: '30px',
            fontSize: '12px',
        },
        '@media (min-width: 1439px)': {
            fontSize: '14px',
        },
        // '@media (min-width: 320px)': {
        //     width: '55px',
        //     height: '20px',
        //     fontSize: '9px',
        //     borderRadius: '4px',
        //     margin: '8px 0 0 6px',
        // },
    },
    editEventImage: {
        borderRadius: '16px',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',

        '@media (min-width: 320px)': {
            width: '100px',
            height: '100px',
        },
        '@media (min-width: 768px)': {
            width: '190px',
            height: '190px',
        },
        '@media (min-width: 1240px)': {
            width: '210px',
            height: '210px',
        },
        '@media (min-width: 1919px)': {
            width: '150px',
            height: '150px',
        },
    },
    createEventImage: {
        borderRadius: '16px',
        // backgroundSize: 'cover',
        // backgroundRepeat: 'no-repeat',
        // backgroundPosition: 'center',
        '& img': {
            borderRadius: '16px',
            objectFit: 'cover',
            '@media (min-width: 320px)': {
                width: '111px',
                height: '111px',
            },
            '@media (min-width: 768px)': {
                width: '150px',
                height: '150px',
            },
            '@media (min-width: 1439px)': {
                width: '212px',
                height: '188px',
            },
        },
    },
    dragDropBody: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: '32px',

        '@media (min-width: 320px)': {
            '& img': {
                width: '32px',
                height: '32px',
            },
        },

        '@media (min-width: 768px)': {
            // paddingTop: '40px',
            '& img': {
                width: '60px',
                height: '55px',
            },
        },
        '@media (min-width: 1240px)': {
            // paddingTop: '107px',
            '& img': {
                width: '60px',
                height: '55px',
            },
        },
    },
    dragDropBodyDrag: {
        display: 'flex',
        fontSize: '16px',
        lineHeight: '24px',
        fontWeight: '500',
        color: '#181D27',
        marginTop: '8px',

        '& span': {
            fontSize: '16px',
            lineHeight: '24px',
            color: '#85242D',
        },
        //
        // '@media (min-width: 320px)': {
        //     fontSize: '14px',
        //     margin: '0',
        //     '& span': {
        //         fontSize: '14px',
        //     },
        // },
        //
        // '@media (min-width: 768px)': {
        //     marginTop: '20px',
        //     '& span': {
        //         fontSize: '20px',
        //     },
        //     fontSize: '20px',
        //     // height: '180px',
        // },
    },
    dragDropBodyDragSizeError: {
        fontSize: '16px',
        lineHeight: '30px',
        color: '#F07379',
        marginTop: '16px',
        fontWeight: 'bold',

        '@media (min-width: 320px)': {
            fontSize: '12px',
            margin: 0,
        },
        '@media (min-width: 768px)': {
            fontSize: '16px',
        },
        '@media (min-width: 1240px)': {
            fontSize: '16px',
        },
    },

    dragDropBodyDragSize: {
        fontWeight: '400',
        lineHeight: '30px',
        color: '#9D9D9D',
        marginTop: '16px',
        fontSize: '12px',

        '@media (min-width: 320px)': {
            margin: 0,
        },
        '@media (min-width: 768px)': {
            fontSize: '16px',
        },
        '@media (min-width: 1240px)': {
            fontSize: '16px',
        },
    },
}));
