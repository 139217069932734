import React from 'react';
import { useLocation } from 'react-router';
import { useSelector } from 'react-redux';
import { OrgList } from 'fragments';
import { NotYetScreen, PaginationItem } from 'components';
import { Images } from 'assets';

export const OrganizationList = () => {
    const { organizations } = useSelector((state) => state.orgs);
    const location = useLocation();

    return (
        <div>
            {organizations?.orgs?.length ? (
                <>
                    <div className="organization-list-section">
                        {organizations?.orgs?.map((org) => (
                            <React.Fragment key={org?.id}>
                                <OrgList org={org} newTab={true}/>
                            </React.Fragment>
                        ))}
                    </div>
                    <PaginationItem count={organizations?.count} link={location?.pathname} />
                </>
            ) : (
                <NotYetScreen
                    image={Images.EmptyOrg}
                    title={'No Organizations Added Yet'}
                    desc={'It seems there are no organizations listed yet.'}
                />
            )}
        </div>
    );
};
