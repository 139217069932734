import moment from 'moment/moment';
import { SaveParams } from '../../../utils';

export const LOAD_EVENTS = 'GET_EVENTS';

export const EventListTypes = {
    GRID: 'GRID',
    LIST: 'LIST',
    MAP: 'MAP',
};

export const EventTypes = {
    ALL: 'ALL',
    VIRTUAL: 'VIRTUAL',
    PHYSICAL: 'PHYSICAL',
};

export const EventFilterTypes = (info) => [
    { label: 'ALL', value: EventTypes?.ALL, selectedValue: info?.eventLocation },
    { label: 'Online', value: EventTypes?.VIRTUAL, selectedValue: info?.eventLocation },
    { label: 'In-Person', value: EventTypes?.PHYSICAL, selectedValue: info?.eventLocation },
];

export const EventDateFilters = (info) => [
    { label: 'From Today', value: 'FROM_TODAY', selectedValue: info?.dateType },
    { label: 'Today', value: 'TODAY', selectedValue: info?.dateType },
    { label: 'Tomorrow', value: 'TOMORROW', selectedValue: info?.dateType },
    { label: 'This weekend', value: 'THIS_WEEKEND', selectedValue: info?.dateType },
    { label: 'This week', value: 'THIS_WEEK', selectedValue: info?.dateType },
    { label: 'Next Week', value: 'NEXT_WEEK', selectedValue: info?.dateType },
    { label: 'This month', value: 'THIS_MONTH', selectedValue: info?.dateType },
];

export const dateTypes = {
    FROM_TODAY: 'From Today',
    TODAY: 'Today',
    TOMORROW: 'Tomorrow',
    THIS_WEEK: 'This Week',
    THIS_WEEKEND: 'This Weekend',
    NEXT_WEEK: 'Next Week',
    THIS_MONTH: 'This Month',
};

export const handleGetSelectedDate = (name, type, info, navigate) => {
    if (type === 'FROM_TODAY') {
        const params = {
            ...info,
        };

        const startTime = moment().utc();
        startTime.hour(0);
        startTime.minute(0);
        startTime.second(1);
        params.startDate = startTime.format();
        delete params[name];
        SaveParams('/events', navigate, params);
    }
    if (type === 'TODAY') {
        const startTime = moment().utc();
        startTime.hour(0);
        startTime.minute(0);
        startTime.second(1);
        const endTime = moment().utc();
        endTime.hour(23);
        endTime.minute(59);
        endTime.second(59);

        const params = {
            ...info,
        };
        params[name] = type;
        params.startDate = startTime.format();
        params.endDate = endTime.format();
        SaveParams('/events', navigate, params);
    }

    if (type === 'TOMORROW') {
        const startTime = moment().utc();
        startTime.add(1, 'days');
        startTime.hour(0);
        startTime.minute(0);
        startTime.second(1);
        const endTime = moment().utc();
        endTime.add(1, 'days');
        endTime.hour(23);
        endTime.minute(59);
        endTime.second(59);

        const params = {
            ...info,
        };
        params[name] = type;
        params.startDate = startTime.format();
        params.endDate = endTime.format();
        SaveParams('/events', navigate, params);
    }
    if (type === 'THIS_WEEK') {
        const startTime = moment().utc();
        startTime.startOf('week');
        startTime.hour(0);
        startTime.minute(0);
        startTime.second(1);
        const endTime = moment().utc();
        endTime.endOf('week');
        endTime.hour(23);
        endTime.minute(59);
        endTime.second(59);

        const params = {
            ...info,
        };
        params[name] = type;
        params.startDate = startTime.format();
        params.endDate = endTime.format();
        SaveParams('/events', navigate, params);
    }
    if (type === 'THIS_WEEKEND') {
        const currentMoment = moment().utc();
        const startOfWeek = currentMoment.startOf('week');
        const saturday = moment(startOfWeek).add(5, 'days');
        const sunday = moment(startOfWeek).add(6, 'days');
        saturday.hour(0);
        saturday.minute(0);
        saturday.second(1);
        sunday.hour(23);
        sunday.minute(59);
        sunday.second(59);

        const params = {
            ...info,
        };
        params[name] = type;
        params.startDate = saturday.format();
        params.endDate = sunday.format();
        SaveParams('/events', navigate, params);
    }
    if (type === 'NEXT_WEEK') {
        const startTime = moment().utc();
        startTime.add(1, 'weeks');
        startTime.startOf('week');
        startTime.hour(0);
        startTime.minute(0);
        startTime.second(1);
        const endTime = moment().utc();
        endTime.add(1, 'weeks');
        endTime.endOf('week');
        endTime.hour(23);
        endTime.minute(59);
        endTime.second(59);

        const params = {
            ...info,
        };
        params[name] = type;
        params.startDate = startTime.format();
        params.endDate = endTime.format();
        SaveParams('/events', navigate, params);
    }
    if (type === 'THIS_MONTH') {
        const startTime = moment().utc();
        startTime.startOf('month');
        startTime.hour(0);
        startTime.minute(0);
        startTime.second(1);
        const endTime = moment().utc();
        endTime.endOf('month');
        endTime.hour(23);
        endTime.minute(59);
        endTime.second(59);

        const params = {
            ...info,
        };
        params[name] = type;
        params.startDate = startTime.format();
        params.endDate = endTime.format();
        SaveParams('/events', navigate, params);
    }
};
