import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { FindLoad } from 'utils';
import { organizationActions } from 'store';
import { Breadcrumb, PageLoader } from 'components';
import { EventGridCard } from 'fragments';

const ACTION_TYPE = 'GET_EVENTS_BY_ORGANIZATION';
export const OrgEventsPage = () => {
    const params = useParams();
    const dispatch = useDispatch();
    const loader = FindLoad(ACTION_TYPE);
    const orgEvents = useSelector((state) => state.orgs.orgEvents);

    useEffect(() => {
        dispatch(organizationActions.orgEvents(params?.id));
    }, [dispatch]);

    if (loader) {
        return <PageLoader />;
    }

    return (
        <div className="events-page">
            <Breadcrumb
                list={[
                    { to: '/businesses', title: 'Organizations' },
                    { to: `/organization/${params?.id}`, title: 'Organization Details' },
                    { title: 'Events' },
                ]}
            />
            <p className="page-global-title">Events {orgEvents?.events?.[0]?.org?.name && `of ${orgEvents?.events?.[0]?.org?.name}`}</p>
            <div className="org-events-box">
                <div className="event-grid">
                    <div className="event-grid-section">
                        {orgEvents?.events?.map((event, j) => (
                            <React.Fragment key={j}>
                                <EventGridCard detailBtn={true} event={event} />
                            </React.Fragment>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};
