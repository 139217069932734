import React from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Svg } from 'assets';
import { PriceConvertor } from 'components';
import { handleGetTicketInfo, renderDiff } from './constants';
import { BuyTicket } from './buyTicket';
import { useModal } from 'utils';
import { useParams } from 'react-router';

export const EventDetailsSection = () => {
    const { event, eventTickets, eventSponsors } = useSelector((state) => ({
        eventTickets: state.tickets.eventTickets,
        event: state.events.eventById,
        eventSponsors: state.events.eventSponsors,
    }));
    const { minPrice, maxPrice, left } = handleGetTicketInfo(eventTickets);
    const { openModal } = useModal();
    const dateChecker = renderDiff(event);
    const params = useParams();

    const renderAccessType = () => {
        if (event?.access?.status === 'PUBLIC') {
            return <p>Public</p>;
        } else {
            return (
                <p>
                    Members Only
                    <span style={{ color: '#65676B' }}>(only members of organization can see it)</span>
                </p>
            );
        }
    };

    return (
        <div className="event-details-section">
            <div className="details-white-box">
                <p className="detail-title">Details</p>
                <div>
                    <div className="detail-item-box">
                        <Svg.ProfileUserCheck />
                        <p>{event?.rsvpCount ? event?.rsvpCount : 0} people responded</p>
                    </div>
                    <div className="detail-item-box">
                        {event?.locationType === 'VIRTUAL' ? <Svg.Globe /> : <Svg.Globe />}
                        <p>{event?.locationType === 'VIRTUAL' ? 'Virtual' : 'In-person'}</p>
                    </div>
                    <div className="detail-item-box">
                        <Svg.Users />
                        {renderAccessType()}
                    </div>
                    {event?.description && <p className="event-description" dangerouslySetInnerHTML={{ __html: event?.description }} />}
                </div>
                <div className="detail-tags-box">
                    {event?.tags &&
                        event?.tags.map((d, j) => (
                            <div
                                key={j}
                                style={{
                                    backgroundColor: j % 2 === 0 ? '#FFF1F3' : 'rgba(239, 239, 239, 0.93)',
                                    color: j % 2 === 0 ? '#C01048' : '363F72',
                                }}>
                                {d}
                            </div>
                        ))}
                </div>
            </div>

            {!!eventTickets?.tickets?.length && (
                <div className="details-white-box">
                    <div>
                        <p className="detail-title">Tickets</p>

                        <div className="tickets-price-button-block">
                            <div>
                                {eventTickets?.tickets?.length > 1 ? (
                                    <div className="ticket-price">
                                        <PriceConvertor price={minPrice} />
                                        <p style={{ margin: '0 2px' }}>{` - `}</p>
                                        <PriceConvertor price={maxPrice} />
                                    </div>
                                ) : (
                                    <div className="ticket-price">
                                        <PriceConvertor price={minPrice} />
                                    </div>
                                )}

                                <p className="only-spots-left">{`Only ${left} spots left`}</p>
                            </div>

                            {!dateChecker?.isBeforeToday && (
                                <button
                                    className="buy-ticket-btn"
                                    onClick={() => openModal(<BuyTicket eventId={params?.id} />, 'noPaddings')}>
                                    <Svg.BuyTicket />
                                    <p>Buy Tickets</p>
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            )}

            {!!eventSponsors?.length && (
                <div className="details-white-box">
                    <div>
                        <p className="detail-title">Sponsors</p>
                        <div className="event-sponsors-box">
                            {eventSponsors?.map((i, j) => (
                                <div className="event-sponsor" key={j}>
                                    <Svg.OrganizationLogo />
                                    <NavLink to={`/organization/${i?.orgId}`} target="_blank">
                                        {i?.orgName}
                                    </NavLink>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};
