import React from 'react';
import { useSelector } from 'react-redux';
import { Svg } from 'assets';
import { ButtonMain } from 'components';
import { useModal, userInfo } from 'utils';

export const ResetPasswordSuccess = () => {
    const { myProfile } = useSelector((state) => ({
        myProfile: state.auth.myProfile,
    }));
    const { close } = useModal();
    const user = myProfile || userInfo;

    return (
        <div className="forgot-modal-box">
            <div className="login-svg-block">
                <Svg.resetSuccessSvg />
            </div>

            <h3 className="auth-modal-title">
                Hey{user?.fullName ? `, ${user?.fullName}` : ''} 👋🏼 <br />
                Welcome to Armat
            </h3>

            <p className="forgot-modal-desc" style={{ marginBottom: '40px' }}>
                Your password has been successfully reset. Click below to log in magically.
            </p>
            <ButtonMain text="Continue" className="login-sign-in-button" type="submit" onClick={() => close()} />
        </div>
    );
};
