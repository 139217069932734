import React from 'react';
import { Controller } from 'react-hook-form';
import InputMask from 'react-input-mask';
import { InputAdornment, TextField } from '@mui/material';
import { Svg } from 'assets';

export const PhoneInput = ({
                               customDesign = true,
                               label,
                               labelHeader,
                               placeholder,
                               control,
                               rules,
                               name,
                               className,
                               disabled,
                               maxLength,
                               startAdornment,
                               endAdornment,
                               ...anotherProps
                           }) => {
    return (
        <div
            className={`input-main-wrapper ${customDesign ? 'input-custom-wrapper' : ''} ${className ? className : ''}`}>
            <Controller
                control={control}
                name={name}
                rules={rules}
                render={({ field, formState: { errors } }) => (
                    <>
                        {labelHeader && <p className={`input-main-label-header`}>{labelHeader}</p>}
                        <InputMask
                            value={field?.value || ''}
                            onChange={field?.onChange}
                            onBlur={field?.onBlur}
                            disabled={disabled} mask='(999) 999-9999'
                        >
                            {() => (
                                <TextField
                                    disabled={disabled}
                                    {...anotherProps}
                                    inputRef={field?.ref}
                                    autoComplete='on'
                                    error={!!errors[field?.name]?.message}
                                    placeholder={placeholder}
                                    label={label}
                                    inputProps={{ maxLength }}
                                    InputProps={{
                                        startAdornment: startAdornment && (
                                            <InputAdornment
                                                className={`${errors[field?.name]?.message ? 'error-svg' : ''}`}
                                                position='start'>
                                                {startAdornment}
                                            </InputAdornment>
                                        ),
                                        endAdornment: endAdornment && (
                                            <InputAdornment position='end'
                                                            className={`${errors[field?.name]?.message ? 'error-svg' : ''}`}>
                                                {endAdornment || <Svg.CallCalling />}
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            )}
                        </InputMask>

                        <p className='error-message'>{errors[field?.name]?.message}</p>
                    </>
                )}
            />
        </div>
    );
};
