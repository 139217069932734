import { EventFilterTypes, EventListTypes } from './constants';
import { Svg } from 'assets';
import { RedRadio, CustomSelect, MinLoader, TypedButtons } from 'components';
import { useLocation, useNavigate } from 'react-router';
import { Notify, SaveParams } from 'utils';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import React, { useEffect, useState } from 'react';
import PlacesAutocomplete from 'react-places-autocomplete';
import { authService } from '../../../store/auth/auth.service';

moment.updateLocale('en', {
    week: {
        dow: 1,
    },
});

export const EventFilters = () => {
    const categories = useSelector((state) => state.events.categories);
    const navigate = useNavigate();
    const location = useLocation();
    const info = location?.state;
    const [address, setAddress] = useState('');
    const [loadMyLocation, setLoadMyLocation] = useState(false);
    const [state, setState] = useState([
        {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection',
        },
    ]);

    useEffect(() => {
        if (info?.startDate && info?.endDate) {
            setState([
                {
                    startDate: new Date(info?.startDate),
                    endDate: new Date(info?.endDate),
                    key: 'selection',
                },
            ]);
        }
        setAddress(info?.city ? info?.city : '');
    }, [info]);

    const renderTabBtns = () => {
        return (
            <TypedButtons
                list={[
                    { icon: <Svg.GridView />, value: EventListTypes?.GRID },
                    { icon: <Svg.ListView />, value: EventListTypes?.LIST },
                    { icon: <Svg.MapView />, value: EventListTypes?.MAP },
                ]}
                setSelected={(value) => {
                    handlePush('listType', value);
                    window.scrollTo(0, 0);
                }}
                selected={info?.listType ? info?.listType : EventListTypes?.GRID}
            />
        );
    };

    const renderTypes = () => {
        return (
            <div className="filter-actions-selector-box">
                {EventFilterTypes(info).map((item, index) => {
                    return (
                        <button
                            key={index}
                            onClick={() => handlePush('eventLocation', item.value)}
                            className={`filter-radio-actions-box-type
               ${
                   item.selectedValue === item.value || (item.value === 'ALL' && !info?.eventLocation)
                       ? 'filter-radio-actions-box-active'
                       : ''
               }  }`}>
                            <p>{item.label}</p>
                            <RedRadio
                                checked={item.selectedValue ? item.selectedValue === item.value : item.value === 'ALL'}
                                value={item.value}
                            />
                        </button>
                    );
                })}
            </div>
        );
    };

    const renderCategories = () => {
        return (
            <div className="filter-actions-selector-box">
                <FormGroup style={{ width: '100%' }}>
                    {!!categories?.length &&
                        categories?.map((i, j) => (
                            <div
                                className={`filter-radio-actions-box ${
                                    info?.categories?.includes(i) ? 'filter-radio-actions-box-active' : ''
                                }`}
                                key={j}
                                style={{ padding: '0 8px' }}>
                                <FormControlLabel
                                    checked={info?.categories?.includes(i)}
                                    onChange={(e) => filterByCategories(e.target.value, e?.target.checked)}
                                    sx={{
                                        width: '100%',
                                    }}
                                    control={
                                        <Checkbox
                                            sx={{
                                                '&.Mui-checked': {
                                                    color: '#85242D',
                                                },
                                            }}
                                            value={i}
                                        />
                                    }
                                    label={i}
                                    key={j}
                                />
                            </div>
                        ))}
                </FormGroup>
            </div>
        );
    };

    const filterByCategories = (value, checked) => {
        // eslint-disable-next-line no-unsafe-optional-chaining
        let newArr = info?.categories ? [...info?.categories] : [];
        const params = {
            ...info,
        };

        if (checked) {
            newArr.push(value);
        } else {
            newArr = info?.categories?.filter((i) => i !== value);
        }

        if (newArr?.length) {
            params.categories = newArr;
        } else {
            delete params.categories;
        }
        handlePush('categories', newArr);
    };

    const handlePush = (name, value) => {
        const params = {
            ...info,
        };
        params.limit = 10;
        params.skip = 0;
        params.page = 1;
        if (value === 'ALL') {
            delete params[name];
        } else {
            params[name] = value;
        }
        SaveParams('/events', navigate, params);
    };

    const handleDateChange = (item) => {
        setState([item.selection]);

        const startTime = moment(item?.selection?.startDate).utc();
        startTime.hour(0);
        startTime.minute(0);
        startTime.second(1);
        const endTime = moment(item?.selection?.endDate).utc();
        endTime.hour(23);
        endTime.minute(59);
        endTime.second(59);

        const params = {
            ...info,
        };
        params.startDate = item?.selection?.startDate;
        params.endDate = item?.selection?.endDate;
        params.limit = 10;
        params.skip = 0;
        params.page = 1;
        SaveParams('/events', navigate, params);
    };

    const renderDates = () => {
        return (
            <div className="filter-actions-selector-box">
                <DateRangePicker ranges={state} onChange={handleDateChange} />
            </div>
        );
    };

    const handleSelect = (value) => {
        setAddress(value);
        const params = {
            ...info,
        };
        delete params.lat;
        delete params.lng;
        delete params.radius;
        params.limit = 10;
        params.skip = 0;
        params.page = 1;
        try {
            if (value) {
                authService.getAddressService(value).then((res) => {
                    params.city = res?.data?.city;
                    SaveParams('/events', navigate, params);
                });
            } else {
                delete params.city;
            }
        } catch (e) {
            console.log(e);
        }
    };

    const handleMyAddress = () => {
        setLoadMyLocation(true);
        const handleSuccess = (position) => {
            const { latitude, longitude } = position.coords;
            const params = {
                ...info,
            };
            params.limit = 10;
            params.skip = 0;
            params.page = 1;
            params.lat = latitude;
            params.lng = longitude;
            params.radius = 50000;
            delete params.city;
            setLoadMyLocation(false);
            SaveParams('/events', navigate, params);
        };

        const handleError = () => {
            Notify('Location access denied. Please enable');
            setLoadMyLocation(false);
        };

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(handleSuccess, handleError);
        }
    };

    const renderLocationBody = () => {
        return (
            <div className="filter-actions-selector-box">
                <div className="search-city">
                    <Svg.Search />
                    <PlacesAutocomplete
                        searchOptions={{
                            types: ['(cities)'], // Restrict search results to cities
                        }}
                        value={address}
                        onChange={setAddress}
                        onSelect={handleSelect}>
                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                            <>
                                <input
                                    {...getInputProps({
                                        placeholder: 'Search by city',
                                    })}
                                />
                                <div className="address-suggestion-custom-input-wrapper">
                                    {loading && <div className="address-loading-class">Loading...</div>}
                                    <div className="address-custom-input-suggestions">
                                        {suggestions.map((suggestion, index) => {
                                            const className = 'suggestion-item';
                                            return (
                                                <p key={index} {...getSuggestionItemProps(suggestion, { className })}>
                                                    {suggestion.description}
                                                </p>
                                            );
                                        })}
                                    </div>
                                </div>
                            </>
                        )}
                    </PlacesAutocomplete>
                </div>

                <button
                    onClick={handleMyAddress}
                    className={`filter-radio-actions-box ${info?.lat && info?.lng ? 'filter-radio-actions-box-active' : ''}`}>
                    <div style={{ height: '25px' }}>
                        {loadMyLocation ? <MinLoader margin={'0'} /> : <RedRadio checked={info?.lat && info?.lng ? true : null} />}
                    </div>

                    <div className="align-center">
                        {/*<Svg.Location />*/}
                        <p style={{ marginLeft: '8px' }}>My location</p>
                    </div>
                </button>
            </div>
        );
    };

    const handeRemove = (type) => {
        const params = {
            ...info,
        };
        params.limit = 10;
        params.skip = 0;
        params.page = 1;
        if (type === 'location') {
            delete params.city;
            delete params.lat;
            delete params.lng;
            delete params.radius;
        }
        if (type === 'date') {
            delete params.startDate;
            delete params.endDate;
        }
        SaveParams('/events', navigate, params);
    };

    return (
        <div className="event-filters-box">
            <div className="space-between">
                <p className="page-global-title">Discover events</p>
                <div className="filter-mobile-view">{renderTabBtns()}</div>
            </div>
            <div className="filter-box">
                <div className="filter-selector-box">
                    <CustomSelect
                        active={info?.city || (info?.lat && info?.lng)}
                        body={renderLocationBody()}
                        label="My Location"
                        selectIcon={<Svg.EventLocation />}
                        removeBtn={() => handeRemove('location')}
                    />
                    <CustomSelect
                        noShowBack={true}
                        active={info?.startDate && info?.endDate}
                        label={'Any Date'}
                        body={renderDates()}
                        selectIcon={<Svg.EventCalendar />}
                        removeBtn={() => handeRemove('date')}
                    />
                    {info?.listType !== EventListTypes?.MAP && (
                        <CustomSelect
                            label="Event Type"
                            body={renderTypes()}
                            active={info?.eventLocation && info?.eventLocation !== 'ALL'}
                        />
                    )}

                    <CustomSelect
                        label={info?.categories?.length ? `Categories: ${info?.categories?.length}` : 'Categories'}
                        body={renderCategories()}
                        active={!!info?.categories?.length}
                    />
                </div>
                <div className="filter-desktop-view">{renderTabBtns()}</div>
            </div>
        </div>
    );
};
