import { RedRadio, CustomSelect, TypedButtons } from 'components';
import { Svg } from 'assets';
import { OrganizationListTypes, OrgFilterTypes } from './constants';
import { useLocation, useNavigate } from 'react-router';
import { SaveParams, token } from 'utils';
import { Filters } from './core';

export const OrganizationFilters = ({ type }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const info = location?.state;

    const renderTypes = () => {
        return (
            <div className='filter-actions-selector-box'>
                {OrgFilterTypes(info).map((item, index) => {
                    return (
                        <button
                            key={index}
                            onClick={() => handlePush('orgTypes', item.value)}
                            className={`filter-radio-actions-box-type
                            ${item.selectedValue === item.value || (item.value === 'ALL' && !info?.orgTypes) ? 'filter-radio-actions-box-active' : ''}`}
                        >
                            <p>{item.label}</p>
                            <RedRadio
                                checked={item.selectedValue ? item.selectedValue === item.value : item.value === 'ALL'}
                                value={item.value}
                            />
                        </button>
                    );
                })}
            </div>
        );
    };

    const handlePush = (name, value) => {
        const params = {
            ...info,
        };
        params.limit = 10;
        params.skip = 0;
        params.page = 1;
        if (value === 'ALL') {
            delete params[name];
        } else {
            params[name] = value;
        }
        SaveParams(location?.pathname, navigate, params);
    };

    return (
        <div className='organization-filters-box'>
            <div className='types-and-category'>
                <CustomSelect
                    active={!!info?.categories?.length}
                    label={info?.categories?.length ? `Categories ${info?.categories?.length}` : 'Categories'}
                    body={<Filters type={type} />}
                />
                {token && <CustomSelect active={!!info?.orgTypes} label={'Types'} body={renderTypes()} />}
            </div>

            <TypedButtons
                list={[
                    { icon: <Svg.ListView />, value: OrganizationListTypes?.LIST },
                    { icon: <Svg.MapView />, value: OrganizationListTypes?.MAP },
                ]}
                setSelected={(value) => {
                    handlePush('listType', value);
                    window.scrollTo(0, 0);
                }}
                selected={info?.listType ? info?.listType : OrganizationListTypes?.LIST}
            />
        </div>
    );
};
