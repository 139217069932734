import { ASSIGN_CARD, GET_CURRENT_CARD, GET_PAYMENT_INVOICES, UN_ASSIGN_CARD } from './payments.types';

export const assignCard = (params) => {
    return {
        type: ASSIGN_CARD,
        payload: { params },
    };
};
export const deleteCard = () => {
    return {
        type: UN_ASSIGN_CARD,
    };
};

export const getCurrentCard = () => {
    return {
        type: GET_CURRENT_CARD,
    };
};

export const getInvoices = () => {
    return {
        type: GET_PAYMENT_INVOICES,
    };
};
