import { useSelector } from 'react-redux';

export const FindLoad = (status) => {
    const httpOnLoad = useSelector((state) => state?.httpOnLoad);

    return httpOnLoad && httpOnLoad.length && httpOnLoad.find((i) => i === status);
};

export const FindError = (status) => {
    const httpOnError = useSelector((state) => state.httpOnError);

    return httpOnError?.length && httpOnError.find((i) => i.type === status);
};

export const FindSuccess = (status) => {
    const httpOnSuccess = useSelector((state) => state.httpOnSuccess);

    return httpOnSuccess?.length && httpOnSuccess.find((i) => i.type === status);
};
