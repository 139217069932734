import Switch from '@mui/material/Switch';
import React from 'react';

const label = { inputProps: { 'aria-label': 'Switch demo' } };

export const CustomSwitch = ({ handleSwitch, name, checked, sx }) => {
    return (
        <div className="custom-switch-box">
            <Switch onChange={handleSwitch} name={name} checked={checked} sx={sx} {...label} />
        </div>
    );
};
