import { useEffect } from 'react';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { eventActions, organizationActions, ticketActions } from 'store';
import { FindLoad, token } from 'utils';
import { PageLoader } from 'components';
import { LOAD_EVENT_BY_ID, EventInformation, EventDetailsSection, EventsYouLike, OrganizedBy, EventMap } from './fragments';

export const EventDetails = () => {
    const { event } = useSelector((state) => ({
        event: state.events.eventById,
    }));
    const params = useParams();
    const dispatch = useDispatch();
    const loader = FindLoad(LOAD_EVENT_BY_ID);

    useEffect(() => {
        if (params?.id) {
            dispatch(eventActions.getEventById(params?.id));
            dispatch(eventActions.getEventSponsors(params?.id));
            dispatch(ticketActions.getEventTickets(params?.id));
            if (token) {
                dispatch(eventActions.getCurrentRsvp(params?.id));
            }
        }
    }, [params?.id]);

    useEffect(() => {
        if (event?.org?.id) {
            dispatch(organizationActions.getOrgById(event?.org?.id));
            dispatch(organizationActions.getOrgSocials(event?.org?.id));
            dispatch(
                eventActions.getEvents({
                    categories: event?.categories,
                })
            );
            if (token) {
                dispatch(organizationActions.getFollows());
            }
        }
    }, [event]);

    if (loader) {
        return <PageLoader />;
    }

    return (
        <div className="event-details-page">
            <EventInformation />

            <div className="event-details">
                <EventDetailsSection />
                <div className="event-map-and-organizations">
                    <EventMap />
                    <OrganizedBy />
                </div>
            </div>

            <EventsYouLike />
        </div>
    );
};
