import axios from 'axios';

export const eventsService = {
    getEventsService: (params) => axios.get(`/events/upcoming/events`, { params: { ...params } }),

    getEventByIdService: (id) => axios.get(`/events/${id}`),

    getEventSponsorsService: (id) => axios.get(`/events/${id}/sponsors`, { auth: false }),

    /** Rsvp **/

    createRsvpService: (params) => axios.post(`/rsvp`, params, { auth: true }),

    editRsvpService: (params) => axios.patch(`/rsvp/${params?.rsvpId}`, { status: params?.status }, { auth: true }),

    getCurrentRsvpService: (id) => axios.get(`/rsvp/event/${id}/byMember`, { auth: true }),

    /** End **/

    getCategoriesService: () => axios.get(`/categories`, { auth: false }),
};
