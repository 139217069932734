import { Routes, Route } from 'react-router-dom';
import {
    Home,
    Events,
    EventDetails,
    Organizations,
    OrgDetails,
    Billing,
    OrgEventsPage,
    MyTickets,
    MyProfile,
    Search,
    Recommendations, Privacy, Terms,
} from 'pages';
import { ORG_TYPES } from '../fragments';

export const Routers = () => {
    return (
        <div className="router-wrapper">
            <Routes>
                <Route index path={'/'} element={<Home />} />
                <Route index path={'/events'} element={<Events />} />
                <Route index path={'/event/:id'} element={<EventDetails />} />
                <Route index path={'/nonProfits'} element={<Organizations type={ORG_TYPES.NON_PROFIT} />} />
                <Route index path={'/businesses'} element={<Organizations type={ORG_TYPES.BUSINESS} />} />
                <Route index path={'/organization/:id'} element={<OrgDetails />} />
                <Route index path={'/organization/:id/events'} element={<OrgEventsPage />} />
                <Route index path={'/billing'} element={<Billing />} />
                <Route index path={'/tickets'} element={<MyTickets />} />
                <Route index path={'/profile'} element={<MyProfile />} />
                <Route index path={'/search'} element={<Search />} />
                <Route index path={'/recommendations'} element={<Recommendations />} />
                <Route index path={'/privacy-policy'} element={<Privacy />} />
                <Route index path={'/term-condition'} element={<Terms />} />
                <Route path="*" element={<Home />} />
            </Routes>
        </div>
    );
};
