import { Svg } from 'assets';

export const TitleAndEditBtn = ({ title, handleClick, showBtn }) => {
    return (
        <div className="org-title-and-edit">
            <p>{title}</p>

            {showBtn && (
                <button onClick={handleClick}>
                    <Svg.Edit />
                </button>
            )}
        </div>
    );
};
