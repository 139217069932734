import React from 'react';
import { Svg } from 'assets';
import { TextRow } from 'components';
import { multiConverter } from 'utils';

export const Ticket = ({ item }) => {
    const event = item?.ticketId?.eventId;
    const ticket = item?.ticketId;
    const imageUrl = event?.images?.length > 0 ? event?.images[event?.eventImage ? event?.eventImage : 0].url : null;

    return (
        <div className="ticket-box">
            <div className="ticket-image-information">
                <div className="flex-start full-width">
                    <div className="ticket-image-box">{imageUrl ? <img src={imageUrl} alt="icon" /> : <Svg.Gallery />}</div>
                    <div className="ticket-texts-box full-width">
                        <TextRow name={event?.title} />
                        <p className="ticket-desc" dangerouslySetInnerHTML={{ __html: event?.description }} />
                    </div>
                </div>
                <div className="ticket-desktop-view">
                    <button className="download-ticket-btn" onClick={() => window.open(item?.qr?.url, '_blank')}>
                        <Svg.Download />
                    </button>
                </div>
            </div>
            <div className="ticket-date-box">
                <div className="ticket-date-address">
                    <div className="ticket-date">
                        <p>{item?.ticketId && multiConverter(event?.startDate, event?.startTime, event?.timezoneOffset, 'MMM DD')}</p>
                        <span>{item?.ticketId && multiConverter(event?.startDate, event?.startTime, event?.timezoneOffset, 'YYYY')}</span>
                    </div>
                    <div className="ticket-address">
                        <p className="day-time">
                            {item?.ticketId && multiConverter(event?.startDate, event?.startTime, event?.timezoneOffset, 'ddd')}
                            <span>
                                {event?.startTime
                                    ? multiConverter(event?.startDate, event?.startTime, event?.timezoneOffset, 'hh:mm')
                                    : event?.allDay
                                    ? 'All Day'
                                    : ''}
                            </span>
                        </p>
                        {event?.address && <span className="event-address">{event?.address?.formattedAddress}</span>}
                    </div>
                </div>
                <div className="ticket-desktop-view">
                    <div className="ticket-display-id">#{ticket?.displayId}</div>
                </div>
            </div>

            <div className="ticket-mobile-view">
                <div className="ticket-display-id">#{ticket?.displayId}</div>
                <button className="download-ticket-btn" onClick={() => window.open(item?.qr?.url, '_blank')}>
                    <Svg.Download />
                </button>
            </div>
        </div>
    );
};
