import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import { OrgList } from 'fragments';

export default function SwipeableTemporaryDrawer() {
    const { organizations } = useSelector((state) => state.orgs);
    const [state, setState] = useState({ bottom: false });

    const toggleDrawer = (anchor, open) => (event) => {
        if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    const list = (anchor) => (
        <div
            className="bottom-anchor-list"
            style={{ height: '70vh' }}
            // sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}>
            <div>
                <div className="discovered-swipe-box">
                    <div />
                    <p>Discover Armenian Organizations</p>
                </div>

                <div className="discover-organizations-box">
                    {organizations?.orgs?.length ? (
                        organizations?.orgs.map((i, j) => (
                            <div key={j}>
                                <OrgList org={i} />
                            </div>
                        ))
                    ) : (
                        <div>Not</div>
                    )}
                </div>
            </div>
        </div>
    );

    return (
        <div className="bottom-anchor-box">
            <React.Fragment>
                <div style={{ cursor: 'pointer' }} className="discovered-swipe-box" onClick={toggleDrawer('bottom', true)}>
                    <div />
                    <p>Discover Armenian Organizations</p>
                </div>

                <SwipeableDrawer
                    anchor={'bottom'}
                    open={state['bottom']}
                    onClose={toggleDrawer('bottom', false)}
                    onOpen={toggleDrawer('bottom', true)}>
                    {list('bottom')}
                </SwipeableDrawer>
            </React.Fragment>
        </div>
    );
}
