import { useEffect } from 'react';
import { Svg } from 'assets';
import { MinLoader } from 'components';
import { FindLoad, FindSuccess, useWindowSize } from 'utils';
import { useDispatch } from 'react-redux';
import { httpRequestsOnSuccessActions } from 'store';

export const TitleAndEdit = ({
    user,
    reset,
    title,
    desc,
    disabled,
    setDisabled,
    clearErrors,
    actionBtns,
    actionType,
    icon,
    isAccordion,
    onClick,
}) => {
    const dispatch = useDispatch();
    const loader = FindLoad(actionType);
    const success = FindSuccess(actionType);
    const [width] = useWindowSize();

    useEffect(() => {
        if (success) {
            dispatch(httpRequestsOnSuccessActions.removeSuccess(actionType));
            if (setDisabled) {
                setDisabled(true);
            }
        }
    }, [success]);

    const handleShowSave = () => {
        if (setDisabled) {
            setDisabled((prev) => !prev);
        }

        if (clearErrors) {
            clearErrors();
        }

        if (reset && user) {
            reset(user);
        }
    };

    return (
        <div
            className={`edit-info-actions-wrapper ${width < 768 && !disabled ? 'red-edit-info-title-and-icon' : ''}`}
            style={{ cursor: isAccordion ? 'pointer' : 'default' }}
            onClick={onClick}>
            <div className="edit-info-box">
                <div className="edit-info-title-and-icon">
                    {icon && <div className="edit-info-icon">{icon}</div>}
                    <p className="edit-info-title">{title}</p>
                    {desc && <p className="edit-info-desc-desktop">{desc}</p>}
                </div>

                {width < 768 && isAccordion && (
                    <Svg.ArrowAccordionDown
                        className={`edit-info-actions-accordion-header-svg ${
                            !disabled ? 'edit-info-actions-accordion-header-svg-open' : ''
                        }`}
                    />
                )}

                {!actionBtns && (
                    <>
                        {!disabled ? (
                            width > 767 && (
                                <div className="save-cancel-btns">
                                    <button type="button" onClick={handleShowSave} className="cancel-info-btn">
                                        Cancel
                                    </button>

                                    <button type="submit" className="save-info-btn">
                                        {loader ? <MinLoader color={'#FFF'} margin={'0'} /> : 'Save'}
                                    </button>
                                </div>
                            )
                        ) : (
                            <button type="button" onClick={handleShowSave} className="edit-info-btn">
                                <Svg.Edit />
                            </button>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};
