import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import * as moment from 'moment/moment';
import { Map, Marker, GoogleApiWrapper, InfoWindow } from 'google-maps-react';
import { Svg, Images } from 'assets';
import { mapStyles } from '../../eventDetails/fragments/constants';
import { TextRow } from 'components';
import { dateConverter, originalDateConverter } from 'utils';

const EventMapView = (props) => {
    const allEvents = useSelector((state) => state.events.events);
    const [showInfoWindow, setShowInfoWindow] = useState(false);
    const [activeMarker, setActiveMarker] = useState(null);
    const [event, setEvent] = useState(null);
    const navigate = useNavigate();
    const eventImage = event?.images?.length > 0 ? event.images[event.eventImage ? event.eventImage : 0].url : Images.EventImage;

    const mapStyle = {
        width: '100%',
        height: '1000px',
        position: 'relative',
    };

    const defaultCenter = {
        lat: 40.712776,
        lng: -74.005974,
    };

    const onMarkerClick = (props, marker, item) => {
        setEvent(item);
        setActiveMarker(marker);
        setShowInfoWindow(true);
    };

    const onClose = () => {
        if (showInfoWindow) {
            setShowInfoWindow(false);
            setActiveMarker(null);
        }
    };

    return (
        <div className="events-page-map-view" onClick={() => {}}>
            <div className="event-map-view-section">
                <Map
                    google={props.google}
                    zoom={12}
                    styles={mapStyles}
                    style={mapStyle}
                    initialCenter={defaultCenter}
                    containerStyle={mapStyle}
                    streetViewControl={false}
                    fullscreenControl={false}
                    mapTypeControl={false}>
                    {allEvents?.events?.map(
                        (i) =>
                            i?.address?.lat && (
                                <Marker
                                    onClick={(props, marker) => onMarkerClick(props, marker, i)}
                                    position={{
                                        lat: i.address.lat,
                                        lng: i.address.lng,
                                    }}
                                    icon={Svg.MapMarker}
                                    key={i?.id}
                                />
                            )
                    )}
                    <InfoWindow marker={activeMarker} visible={showInfoWindow} onClose={onClose}>
                        <div
                            className="map-event-wrapper"
                            onClick={(e) => {
                                e.stopPropagation();
                                navigate(`/event/${event?.id}`);
                            }}>
                            <div className="map-event-image-box" style={{ backgroundImage: `url(${eventImage})` }}>
                                <div className="map-event-type-box">
                                    <div className="event-type">{event?.locationType === 'VIRTUAL' ? 'ONLINE' : 'IN-PERSON'}</div>
                                </div>
                                <button
                                    className="close-map-btn"
                                    onClick={() => {
                                        setShowInfoWindow(false);
                                        setActiveMarker(null);
                                    }}>
                                    <Svg.Close />
                                </button>
                            </div>
                            <div className="map-event-information-box">
                                <div className="map-event-title">
                                    <TextRow name={event?.title} />
                                </div>
                                <div className="map-event-date-time-location-box">
                                    <div className="map-event-info-box">
                                        <div className="icon-box">
                                            <Svg.EventCalendar />
                                        </div>
                                        {event?.timezoneOffset ? (
                                            <div>
                                                {event?.locationType === 'VIRTUAL' ? (
                                                    <p>
                                                        {event?.startTime
                                                            ? `${originalDateConverter(event)} ${
                                                                  event.timezoneOffset ? `(GMT ${event.timezoneOffset} )` : ''
                                                              }`
                                                            : `${moment(event.startDate).format('MM/DD/YYYY')}`}
                                                    </p>
                                                ) : (
                                                    <p>
                                                        {event.startTime
                                                            ? `${dateConverter(event)} (Your Time)`
                                                            : `${moment(event.startDate).format('MM/DD/YYYY')}`}
                                                    </p>
                                                )}
                                            </div>
                                        ) : (
                                            <p>{`${moment(event?.startTime).format('llll')} ${
                                                event?.timezoneOffset ? `(GMT ${event?.timezoneOffset} )` : ''
                                            }`}</p>
                                        )}
                                    </div>
                                    {event?.address?.formattedAddress && (
                                        <div className="map-event-info-box">
                                            <div className="icon-box">
                                                <Svg.EventLocation />
                                            </div>
                                            <TextRow name={event?.address?.formattedAddress} textWidth={8} />
                                        </div>
                                    )}
                                </div>

                                <button className="map-event-view-details-btn" onClick={() => navigate(`/event/${event?.id}`)}>
                                    <p className="map-view-details-btn-text">View Details</p>
                                    <Svg.ArrowUpRight />
                                </button>
                            </div>
                        </div>
                    </InfoWindow>
                </Map>
            </div>
        </div>
    );
};

export default GoogleApiWrapper({
    apiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
})(EventMapView);
