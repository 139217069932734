import { Svg } from 'assets';

export const CloseBtn = ({ handleClose }) => {
    return (
        <div>
            <button className="close-button-box" onClick={handleClose}>
                <Svg.Close />
            </button>
        </div>
    );
};
