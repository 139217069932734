import React, { useEffect } from 'react';
import { ButtonMain } from 'components';
import { useDispatch } from 'react-redux';
import { httpRequestsOnSuccessActions } from 'store';
import { FindSuccess, useModal } from 'utils';

export const DeleteModal = ({ onDelete, actionType, title, description, deleteBtnText }) => {
    const dispatch = useDispatch();
    const { close } = useModal();
    const success = FindSuccess(actionType);

    useEffect(() => {
        if (success) {
            close();
            dispatch(httpRequestsOnSuccessActions.removeSuccess(actionType));
        }
    }, [success]);

    return (
        <div className="delete-modal-wrapper">
            <div className="delete-modal-content">
                <h3 className="delete-modal-content-title">{title}</h3>
                <p className="delete-modal-content-text">{description}</p>
                <div className="delete-modal-content-buttons">
                    <ButtonMain text="Cancel" cancel onClick={() => close()} />
                    <ButtonMain
                        text={deleteBtnText ? deleteBtnText : 'Delete'}
                        deleteButton
                        actionTye={actionType}
                        onClick={onDelete}
                        loadStyles={{
                            color: '#FFF',
                        }}
                    />
                </div>
            </div>
        </div>
    );
};
