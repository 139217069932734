export const Terms = () => {
    return (
        <div className="privacy-terms-page">
            <div className="privacy-terms-title-box">
                <div />
                <p className="page-global-title">TERMS & CONDITIONS</p>
            </div>
            <div className="privacy-term-body-box">
                THIS IS A FREE SERVICE TO THE COMMUNITY. BUSINESSES, EVENTS, AND ORGANIZATIONS ARE INCLUDED IN THE LIST
                AT NO COST
                TO THEM. ARMAT DOES NOT MAKE REPRESENTATIONS AND WARRANTIES IN RESPECT TO THE QUALITY OR NATURE OF THESE
                BUSINESSES
                AND SERVICES, OR PRODUCTS OFFERED BY THE BUSINESSES.
                <br />
                <br /> The www.armat.org website ("Armat") is a collection of Armenian businesses, services, and events
                operated by
                third parties. The owners and operators of the site (the Admin) own and control the content of Armat.
                <br />
                <br /> Please take time to read through the below to terms of use of Armat period to utilizing its
                features:
                <br />
                <br /> The use of Armat is offered to you conditioned on your acceptance of the terms of service and
                notices
                contained herein (the "Terms"). Your use of the Armat represents your agreement to all such Terms.
                <br />
                <br /> Armat contains links to third-party websites ("Websites") and social media accounts (“Socials”).
                The Websites
                and Socials are not under the control of Armat and Armat is not responsible for the contents of any
                Website,
                including without limitation any link contained in a Website, or any changes or updates to a Website.
                Armat is
                providing these Websites and Socials to you free of charge as a form of discovery of Armenian
                Businesses, Services,
                and Events, and the inclusion of these does not imply endorsement by Armat or any connection or relation
                with its
                owners. Armat does not make any guarantees, promises, or warranties on behalf of these Businesses,
                Services, and
                Events. <br />
                <br />
                All products, services, and events discovered via Armat are offered solely and completely by third party
                sites and
                organizations. By using any product, service or functionality originating from the Armat website, you
                hereby
                acknowledge that Armat has no connection or responsibility to the outcome or results of this action.
                When you access
                any of the third party sites and organizations, the applicable third party’s terms and policies apply,
                including the
                third party’s privacy and data gathering practices. It is on you, the user, to make whatever
                investigation you feel
                necessary or appropriate before proceeding with any transaction in connection with the third party sites
                and
                organizations.
                <br />
                <br />
                The Armat Admins reserve the right to make updates and changes to the Terms with or without notice to
                you.
                <br />
                <br />
                You acknowledge and agree that it is your responsibility to assess Armat and these Terms. Your continued
                use of
                Armat after any such changes will represent your acknowledgement and acceptance of the updated
                Terms. <br />
                <br />
                Visiting Armat or contacting the Armat Admin via email represents electronic communications. You consent
                to receive
                electronic communications and you agree that all agreements, notices, disclosures and other
                communications that we
                provide to you electronically, via email and on Armat, satisfy any legal requirement that such
                communications be in
                writing.
                <br />
                <br />
                The Websites and Socials featured on Armat are those of worldwide origin, and by using Armat you
                acknowledge your
                compliance with the laws based in the country of the business or service you are using. You consent that
                you will
                not use Armat content accessed through Armat in any country or in any manner prohibited by any
                applicable laws,
                restrictions or regulations. <br />
                <br /> You consent to indemnify, defend and hold harmless Armat, its officers, directors, employees,
                agents and
                third parties, for any losses, costs, liabilities and expenses (including reasonable attorney's fees)
                relating to or
                arising out of your use of or inability to use Armat or services. Armat retains the right, at its own
                cost, to
                assume the exclusive defense and control of any matter otherwise subject to indemnification by you, in
                which event
                you will fully cooperate with Armat in asserting any available defenses. <br />
                <br />
                All information related to the products, services, and events listed in Armat may include inaccuracies
                or
                typographical errors. The Admins will make changes to this information from time to time as requested by
                business
                and event owners. Armat and the businesses and events listed on the site make no representations or
                guarantees about
                the suitability, reliability, availability, timeliness, and accuracy of the information you find on the
                Armat site.
                To the maximum extent permitted by applicable law, all such information, software, products, services
                and related
                graphics are provided "as is" without warranty or condition of any kind. Armat and/or the businesses and
                events
                found on the Armat site hereby disclaim all warranties and conditions with regard to this information,
                software,
                products, services and related graphics, including all implied warranties or conditions of
                merchantability, fitness
                for a particular purpose, title and non-infringement. <br />
                <br />
                Disclaimer
                <br />
                <br />
                TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL Armat BE LIABLE FOR ANY DIRECT,
                INDIRECT,
                PUNITIVE, INCIDENTAL, SPECIAL, CONSEQUENTIAL DAMAGES OR ANY DAMAGES WHATSOEVER INCLUDING, WITHOUT
                LIMITATION,
                DAMAGES FOR LOSS OF USE, DATA OR PROFITS, ARISING OUT OF OR IN ANY WAY CONNECTED WITH THE USE OR
                PERFORMANCE OF THE
                SITE, WITH THE DELAY OR INABILITY TO USE THE SITE OR RELATED SERVICES, THE PROVISION OF OR FAILURE TO
                PROVIDE
                SERVICES, OR FOR ANY INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS OBTAINED THROUGH THE
                SITE, OR
                OTHERWISE ARISING OUT OF THE USE OF THE SITE, WHETHER BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT
                LIABILITY OR
                OTHERWISE, EVEN IF ARMAT HAS BEEN ADVISED OF THE POSSIBILITY OF DAMAGES. BECAUSE SOME
                STATES/JURISDICTIONS DO NOT
                ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, THE ABOVE
                LIMITATION MAY NOT
                APPLY TO YOU. IF YOU ARE DISSATISFIED WITH ANY PORTION OF THE SITE, OR WITH ANY OF THESE TERMS OF USE,
                YOUR SOLE AND
                EXCLUSIVE REMEDY IS TO DISCONTINUE USING THE SITE.
            </div>
        </div>
    );
};