import { createRsvp, editRsvp, getCategories, getCurrentRsvp, getEventById, getEvents, getEventSponsors } from './events.action';
export { eventsReducer } from './events.reducer';
export { watchEvents } from './events.saga';

export const eventActions = {
    getEvents,
    getEventById,
    getEventSponsors,

    /** Rsvp **/
    createRsvp,
    editRsvp,
    getCurrentRsvp,
    /** End **/

    getCategories,
};
