import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { ButtonMain, CancelBtn, InputMain, AddressInput, PhoneInput } from 'components';
import { FindError, FindSuccess, useModal } from 'utils';
import { httpRequestsOnErrorsActions, httpRequestsOnSuccessActions, organizationActions } from 'store';
import { CategoryItems } from './categoryItems';

import { CREATE_ORG_TYPE } from './constants';
import { ORG_TYPES } from '../constants';

export const CreateOrganization = ({ type }) => {
    const { orgCategories } = useSelector((state) => ({
        orgCategories: state.orgs.orgCategories,
    }));
    const dispatch = useDispatch();
    const { close } = useModal(CREATE_ORG_TYPE);
    const backError = FindError(CREATE_ORG_TYPE);
    const successLogin = FindSuccess(CREATE_ORG_TYPE);
    const [categoryArray, setCategoryArray] = useState([]);
    const [newCategList, setNewCategList] = useState([]);
    const [description, setDescription] = useState('');

    const arr = [];
    const { handleSubmit, control, setError, setValue } = useForm({});

    useEffect(() => {
        if (successLogin) {
            close();
            dispatch(httpRequestsOnSuccessActions.removeSuccess(CREATE_ORG_TYPE));
        }
    }, [successLogin]);

    useEffect(() => {
        dispatch(organizationActions.getOrgCategories());
    }, []);

    useEffect(() => {
        if (backError?.type === CREATE_ORG_TYPE) {
            if (backError?.error === 'Such user does not exist in our records') {
                setError('website', {
                    message: 'Website must be a URL address',
                });
            }
            // if (backError?.error === 'User password does not match' || backError?.error === 'user password does not match') {
            //     setError('password', {
            //         type: 'required',
            //         message: 'User password does not match',
            //     });
            // }
            dispatch(httpRequestsOnErrorsActions.removeError(CREATE_ORG_TYPE));
        }
    }, [dispatch, backError]);

    const onSubmit = (data) => {
        const formData = {
            ...data,
            type: type,
        };
        if (data?.email) {
            formData.email = data.email;
        } else {
            delete formData.email;
        }

        if (data?.phoneNumber) {
            formData.phoneNumber = `${parseInt(data?.phoneNumber.replace(/[^0-9]/g, ''))}`;
        } else {
            delete formData.phoneNumber;
        }
        if (newCategList?.length) {
            formData.categories = newCategList;
        }

        description ? (formData.description = description) : delete formData.description;
        dispatch(organizationActions.createOrganization(formData));
    };

    useEffect(() => {
        if (orgCategories?.length) {
            getTree(orgCategories);
        }
    }, [orgCategories]);

    const getTree = (org) => {
        org.length &&
            org.map((it) => {
                arr.push(it);
                if (it.items) {
                    return getTree(it.items);
                }
                setCategoryArray(arr);
            });
    };

    return (
        <div className="create-org-box">
            <p className="create-org-title">{`Create a ${type === ORG_TYPES?.BUSINESS ? 'Business' : 'Non Profit'}`}</p>
            <form className="create-org-form-box" onSubmit={handleSubmit(onSubmit)}>
                <div className="create-org-basic-info">
                    <p className="create-org-basic-title">Basic Info</p>
                    <p className="create-org-basic-desc">Provide some basic organization info to let users know about you.</p>
                </div>
                <div className="org-inputs-box">
                    <InputMain
                        rules={{
                            required: 'This Field is required',
                        }}
                        name="name"
                        type="text"
                        control={control}
                        placeholder="Enter the name of the organization"
                        labelHeader="Organization Name"
                    />
                    <AddressInput
                        name="address"
                        control={control}
                        placeholder="Enter org. address"
                        labelHeader="Organization Address"
                        setValue={setValue}
                    />
                </div>
                <div className="org-inputs-box">
                    <PhoneInput
                        name={'phoneNumber'}
                        control={control}
                        placeholder="Enter the phone number of the organization"
                        labelHeader="Phone Number"
                    />
                    <InputMain name="email" type="email" control={control} placeholder="Enter org. email" labelHeader="Email Address" />
                </div>
                <div className="org-inputs-box">
                    <InputMain name="website" type="text" control={control} placeholder="Enter website URL" labelHeader="Website" />

                    <div style={{ width: '100%' }}>
                        <CategoryItems
                            selectedIdInfos={[]}
                            selectedInfo={[]}
                            categories={categoryArray}
                            allCategories={orgCategories}
                            handleGetNewList={(newArr) => setNewCategList(newArr)}
                        />
                    </div>
                </div>

                <div className="create-org-basic-info">
                    <p className="create-org-basic-title">Description*</p>
                    <p className="create-org-basic-desc">Provide short description for your organization.</p>

                    <div className={'rich-container'}>
                        <ReactQuill
                            theme="snow"
                            value={description}
                            onChange={setDescription}
                            placeholder="Enter the Description here..."
                        />
                    </div>
                </div>

                <div className="create-org-btns">
                    <CancelBtn />
                    <ButtonMain actionTye={CREATE_ORG_TYPE} text="Save" type="submit" />
                </div>
            </form>
        </div>
    );
};
