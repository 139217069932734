import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import { CircularProgress, Tooltip } from '@mui/material';
import { notificationsActions } from 'store';
import { useNoteItemStyle } from './styles';
import { Text } from './text';
import { Svg } from 'assets';
import { RenderImage } from './renderImage';

const NotificationItem = ({ notifications, closeOpen, params, removeNotification }) => {
    const classes = useNoteItemStyle();
    const dispatch = useDispatch();
    const router = useNavigate();
    const [marked, setmarked] = useState(true);

    const date1 = new Date(notifications?.createdAt);
    const date2 = new Date();
    let month = date2.getMonth() + 1;
    let year = date2.getFullYear();
    let daysInMonth = new Date(year, month, 0).getDate();
    let diffTime = Math.abs(date2 - date1);
    let total_seconds = parseInt(Math.floor(diffTime / 1000));
    let total_minutes = parseInt(Math.floor(total_seconds / 60));
    let total_hours = parseInt(Math.floor(total_minutes / 60));
    let days = parseInt(Math.floor(total_hours / 24));
    let seconds = parseInt(total_seconds % 60);
    let minutes = parseInt(total_minutes % 60);
    let hours = parseInt(total_hours % 24);
    let week = parseInt(Math.floor(days / 7));
    let filteredMonth = parseInt(Math.floor(days / daysInMonth));
    let filteredYear = parseInt(Math.floor(filteredMonth / 12));

    const handleDel = () => {
        dispatch(notificationsActions.removeNotification(notifications.id, params));
    };

    const time =
        filteredYear > 0
            ? `${filteredYear}y`
            : filteredMonth
                ? `${filteredMonth}m`
                : week > 0
                    ? `${week}w`
                    : days > 0
                        ? `${days}d`
                        : hours > 0
                            ? `${hours}h`
                            : minutes > 0
                                ? `${minutes}m`
                                : `${seconds}s`;

    const handleSeeEvent = (id) => {
        closeOpen();
        router(`/organization/${id}`);
    };

    return (
        <div
            className={classes.NotificationItem}
        >
            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <div
                    style={{ display: 'flex', gap: '12px' }}
                    onClick={() => {
                        setmarked(false);
                        dispatch(notificationsActions.markReadNotification({ notificationId: notifications.id }));
                    }}>
                    <div className={classes.iconCont}>
                        <RenderImage type={notifications.type} className={classes.notificationImage} />
                    </div>

                    <div className={classes.messageCont}>
                        <p className={classes.message}>
                            <Text
                                type={notifications.type}
                                info={notifications}
                                classes={classes}
                                handleClose={closeOpen}
                                handleSeeComment={handleSeeEvent}
                            />
                        </p>

                        <div className={classes.dateUnreadCircle}>
                            <p className={classes.date}>{time}</p>
                            {(notifications?.status === 'UNREAD' && marked) && (
                                <div className={classes.unreadCircle} />
                            )}
                        </div>
                    </div>
                </div>

                <div className={classes.delIconCont}>
                    {removeNotification === notifications.id ? (
                        <CircularProgress
                            style={{
                                color: '#BB3240',
                                width: '15px',
                                height: '15px',
                                margin: '0 auto',
                            }}
                        />
                    ) : (

                        <Tooltip
                            classes={{
                                tooltip: classes.tooltip,
                                arrow: classes.tooltipArrow,
                            }}
                            placement='top'
                            title='Delete'
                            arrow
                        >
                            <button onClick={handleDel}>
                                <Svg.Close />
                            </button>
                        </Tooltip>
                    )}
                </div>
            </div>
        </div>
    );
};

export default NotificationItem;
