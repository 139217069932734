import { makeStyles } from '@mui/styles';

export const useNoteItemStyle = makeStyles(() => ({
    NotificationItem: {
        padding: '16px 24px',
        borderBottom: '1px solid #E9EAEB',
        display: 'flex',
        flexDirection: 'row',
        cursor: 'pointer',
        transition: 'all 0.2s',

        '&:hover': {
            background: '#F7F8FA',
            transition: 'all 0.2s',

            '& $notificationImage': {
                background: '#F8EBEC',
                border: '1px solid #F8EBEC',
            },

            '& $delIconCont': {
                opacity: 1,
                visibility: 'visible',
            },
        },
    },
    iconCont: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },

    tooltip: {
        backgroundColor: '#101828',
        padding: '8px 12px',
    },

    tooltipArrow: {
        '&:before': {
            backgroundColor: '#101828',
        }
    },

    notificationImage: {
        width: '40px',
        height: '40px',
        borderRadius: '9999px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: 'white',
        padding: '10px',
        border: '1px solid #D5D7DA',
    },

    messageCont: {
        // width: '70%',
        height: '100%',
    },
    message: {
        color: '#181D27',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '21px',
    },
    delIconCont: {
        opacity: 0,
        visibility: 'hidden',
        display: 'flex',
        flexDirection: ' column',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        '& button': {
            background: 'none',
            border: 'none',
            outline: 'none',
            cursor: 'pointer',
            padding: '0',
            '& svg': {
                filter: 'brightness(0) saturate(100%) invert(43%) sepia(55%) saturate(6844%) hue-rotate(343deg) brightness(100%) contrast(89%)',
            }
        },
    },
    dateUnreadCircle: {
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
        marginTop: '4px',
    },
    date: {
        color: '#717680',
        fontSize: '12px',
        fontWeight: 400,
        marginTop: '4px',
    },

    unreadCircle: { width: '8px', height: '8px', borderRadius: '50%', background: '#BB3240' },

    linkButton: {
        border: 'none',
        background: 'none',
        cursor: 'pointer',
        fontSize: '16px',
        color: 'black',
    },
}));

export const useNoteContStyles = makeStyles(() => ({
    notificationsHeader: {
        display: 'flex',
        gap: '8px',
        justifyContent: 'space-between',
        padding: '12px 12px 0 12px',
        marginBottom: '24px',
    },

    notificationsTabWrapper: {
        width: '100%',
        padding: '0 24px 16px',
        borderBottom: '1px solid #E4E7EC',
    },

    notificationsTabBox: {
        display: 'flex',
        alignItems: 'center',
        gap: '4px',
        borderRadius: '8px',
        border: '1px solid  #E9EAEB',
        padding: '4px',
    },

    notificationsTabButton: {
        width: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '8px',
        background: 'none',
        border: 'none',
        outline: 'none',
        cursor: 'pointer',
        fontSize: '14px',
        color: '#475467',
        fontWeight: 500,
        lineHeight: '20px',
        padding: '8px 14px',
        borderRadius: '8px',
        transition: 'all 0.2s',

        '&:hover': {
            color: '#FFF',
            background: '#BB3240',
            transition: 'all 0.2s',
        },
    },

    notificationsTabButtonActive: {
        color: '#FFF',
        background: '#BB3240',
    },

    notificationsUnreadCount: {
        borderRadius: '16px',
        background: '#F2F4F7',
        padding: '2px 8px',
        color: '#475467',
        fontSize: '12px',
        lineHeight: '18px',
    },

    notificationsNotYet: {
        padding: '24px',
    },

    noNotificationsCont: {
        overflow: 'hidden',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },

    //     '@media (min-width: 320px)': {
    //         zIndex: 9,
    //         top: 0,
    //         height: '100vh',
    //         width: '100%',
    //         opacity: '1',
    //         position: 'fixed',
    //         borderRadius: 0,
    //     },
    //     '@media (min-width: 768px)': {
    //         zIndex: 9,
    //         top: 0,
    //         3
    //         height: '100vh',
    //         width: '100%',
    //         opacity: '1',
    //         position: 'fixed',
    //         borderRadius: 0,
    //     },
    //     '@media (min-width: 1240px)': {
    //         width: '500px',
    //         maxHeight: '500px',
    //         backgroundColor: '#FFFFFF',
    //         boxShadow: '0px 2px 6px #0000001A',
    //         borderRadius: '16px',
    //         opacity: 1,
    //         position: 'absolute',
    //         top: 76,
    //         right: 80,
    //     },
    //     '@media (min-width: 1920px)': {
    //         width: '500px',
    //         maxHeight: '700px',
    //         backgroundColor: '#FFFFFF',
    //         boxShadow: '0px 2px 6px #0000001A',
    //         borderRadius: '16px',
    //         opacity: 1,
    //         position: 'absolute',
    //         top: 76,
    //         right: 120,
    //     },
    // },
    notificationsActionBox: {
        padding: '8px 24px 16px 24px',
    },
    header: {
        height: '97px',
        display: 'flex',
        alignItems: 'center',
        padding: '0 24px 0 24px',
        justifyContent: 'space-between',
    },
    headerText: {
        fontSize: '24px',
        fontWeight: 'bold',
        padding: '12px 0 0 12px',
    },
    markAllReadButton: {
        display: 'flex',
        justifyContent: 'end',
        padding: '0 24px 12px',
        marginTop: '12px',
        borderBottom: '1px solid #E4E7EC',
    },

    headerText1: {
        border: 'none',
        background: 'none',
        outline: 'none',
        color: '#181D27',
        fontSize: '16px',
        lineHeight: '21px',
        fontWeight: '500',
        fontStyle: 'normal',
        margin: 0,
        cursor: 'pointer',
        textDecoration: 'none',
    },
    notificationItemCont: {
        display: 'flex',
        flexDirection: 'column',
        overflow: 'auto',
        padding: '0 0 20px 0',

        '@media (min-width: 320px)': {
            maxHeight: '100vh',
            height: '100vh',
        },
        '@media (min-width: 768px)': {
            maxHeight: '100vh',
            height: '100vh',
        },
        '@media (min-width: 1240px)': {
            maxHeight: '100%',
            height: '400px',
        },
        '@media (min-width: 1920px)': {
            maxHeight: '100vh',
            height: '600px',
        },
    },
    noNotesCont: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',

        // [theme.breakpoints.down('xl')]: {
        //     width: '500px',
        //     maxHeight: '700px',
        //     height: '600px',
        //     backgroundColor: '#FFFFFF',
        //     boxShadow: '0px 2px 6px #0000001A',
        //     borderRadius: '16px',
        //     opacity: 1,
        //     position: 'absolute',
        //     top: 54,
        //     left: -443,
        // },
        // [theme.breakpoints.down('sm')]: {
        //     marginTop: '72px',
        //     width: '100%',
        //     height: '100vh',
        //     backgroundColor: '#FFFFFF',
        //     boxShadow: '0px 2px 6px #0000001A',
        //     opacity: 1,
        //     position: 'inherit',
        //     borderRadius: 0,
        // },
    },
    noNotes: {
        fontSize: '30px',
        color: '#000',
        fontWeight: '600',
        lineHeight: '27px',
        margin: '32px 0 4px',
    },

    noNotesDesk: {
        color: '#475367',
        fontSize: '14px',
        fontWeight: '400',
        lineHeight: '21px',
    },
    backdrop: {
        width: '100%',
        position: 'fixed',
        height: '100vh',
        top: 0,
        left: 0,
        '@media (min-width: 320px)': {
            background: 'white',
        },
        '@media (min-width: 768px)': {
            background: 'white',
        },
        '@media (min-width: 1240px)': {
            background: 'transparent',
        },
        '@media (min-width: 1920px)': {
            background: 'transparent',
        },
    },
    closeNotificationButton: {
        right: '24px',
        position: 'absolute',
        top: '15px',
        background: 'none',
        border: 'none',
        outline: 'none',
        '@media (min-width: 320px)': {
            display: 'block',
        },
        '@media (min-width: 768px)': {
            display: 'block',
        },
        '@media (min-width: 1240px)': {
            display: 'none',
        },
        '@media (min-width: 1920px)': {
            display: 'none',
        },
    },
    infinitiScrollStyle: {
        margin: '0 auto',
        display: 'flex',
        padding: '20px',
    },
}));
