import { Svg } from 'assets';

export const RoutersInfo = () => {
    return [
        {
            title: 'Discover Events',
            path: '/events',
        },
        {
            title: 'Find Businesses',
            path: '/businesses',
        },
        {
            title: 'Support Organizations',
            path: '/nonprofits',
        },
    ];
};

export const Links = [
    {
        name: 'Dashboard view',
        icon: <Svg.DashboardView />,
        link: '/dashboard',
    },

    {
        name: 'My profile',
        icon: <Svg.UserView />,
        link: '/profile',
    },

    // {
    //     name: 'My events',
    //     icon: <Svg.EventsView />,
    //     link: '/my-events',
    // },

    {
        name: 'My tickets',
        icon: <Svg.MyTickets />,
        link: '/tickets',
    },

    // {
    //     name: 'My subscriptions',
    //     icon: <Svg.MySubscriptions />,
    //     link: '/subscriptions',
    // },

    {
        name: 'Billing',
        icon: <Svg.Billing />,
        link: '/billing',
    },

    {
        name: 'Invite People',
        icon: <Svg.InvitePeople />,
        type: 'notLink',
        clickFn: 'invite',
    },
];

export const MobileLinks = [
    {
        name: 'Discover Events',
        icon: <Svg.DiscoveredEvents />,
        link: '/events',
    },
    {
        name: 'Find Businesses',
        icon: <Svg.FindBusiness />,
        link: '/businesses',
    },
    {
        name: 'Support Organizations',
        icon: <Svg.SupportOrg />,
        link: '/nonprofits',
    },
];
