import { call, put, takeLatest } from 'redux-saga/effects';
import { eventsService } from './events.service';
import {
    CREATE_RSVP,
    EDIT_RSVP,
    GET_CATEGORIES,
    GET_CATEGORIES_SUCCESS,
    GET_CURRENT_RSVP,
    GET_CURRENT_RSVP_SUCCESS,
    GET_EVENT_BY_ID,
    GET_EVENT_BY_ID_SUCCESS,
    GET_EVENT_SPONSORS,
    GET_EVENT_SPONSORS_SUCCESS,
    GET_EVENTS,
    GET_EVENTS_SUCCESS,
} from './events.types';
import { httpRequestsOnErrorsActions } from '../http_requests_on_errors';
import { httpRequestsOnLoadActions } from '../http_requests_on_load';
import { httpRequestsOnSuccessActions } from '../http_requests_on_success';

function* getEvents({ payload, type }) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    try {
        const { data } = yield call(eventsService.getEventsService, payload?.params);
        yield put({
            type: GET_EVENTS_SUCCESS,
            payload: { data },
        });
        yield put(httpRequestsOnErrorsActions.removeError(type));
    } catch (err) {
        yield put(httpRequestsOnErrorsActions.appendError(type, err?.data.message));
    } finally {
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}

function* getEventById({ payload, type }) {
    if (payload?.load !== 'noLoad') {
        yield put(httpRequestsOnLoadActions.appendLoading(type));
    }
    yield put(httpRequestsOnErrorsActions.removeError(type));
    try {
        const { data } = yield call(eventsService.getEventByIdService, payload?.id);
        yield put({
            type: GET_EVENT_BY_ID_SUCCESS,
            payload: { data },
        });
        yield put(httpRequestsOnErrorsActions.removeError(type));
    } catch (err) {
        yield put(httpRequestsOnErrorsActions.appendError(type, err?.data.message));
    } finally {
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}

function* getEventSponsors({ payload, type }) {
    yield put(httpRequestsOnErrorsActions.removeError(type));
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    try {
        const res = yield call(eventsService.getEventSponsorsService, payload?.id);
        yield put({
            type: GET_EVENT_SPONSORS_SUCCESS,
            payload: res?.data,
        });
        yield put(httpRequestsOnErrorsActions.removeError(type));
    } catch (err) {
        yield put(httpRequestsOnErrorsActions.appendError(type, err?.data?.message));
    } finally {
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}

/** Rsvp **/
function* createRsvp({ payload, type }) {
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    try {
        yield call(eventsService.createRsvpService, payload);
        const res = yield call(eventsService.getCurrentRsvpService, payload?.eventId);
        yield put({
            type: GET_EVENT_BY_ID,
            payload: { id: payload?.eventId, load: 'noLoad' },
        });
        yield put({
            type: GET_CURRENT_RSVP_SUCCESS,
            payload: res?.data,
        });
        yield put({
            type: GET_CURRENT_RSVP,
            payload: { id: payload?.eventId },
        });
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}

function* editRsvp({ payload, type }) {
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    try {
        yield call(eventsService.editRsvpService, payload);
        const res = yield call(eventsService.getCurrentRsvpService, payload?.eventId);
        yield put({
            type: GET_EVENT_BY_ID,
            payload: { id: payload?.eventId, load: 'noLoad' },
        });
        yield put({
            type: GET_CURRENT_RSVP_SUCCESS,
            payload: res?.data,
        });
        yield put({
            type: GET_CURRENT_RSVP,
            payload: { id: payload?.eventId },
        });
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}

function* getCurrentRsvp({ payload, type }) {
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    try {
        const res = yield call(eventsService.getCurrentRsvpService, payload?.id);
        yield put({
            type: GET_CURRENT_RSVP_SUCCESS,
            payload: res?.data,
        });
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    } catch (err) {
        yield put({
            type: GET_CURRENT_RSVP_SUCCESS,
            payload: null,
        });
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}
/** End **/

function* getCategories({ type }) {
    yield put(httpRequestsOnLoadActions.appendLoading(type));
    try {
        const res = yield call(eventsService.getCategoriesService);
        yield put({
            type: GET_CATEGORIES_SUCCESS,
            payload: res?.data,
        });
        yield put(httpRequestsOnSuccessActions.appendSuccess(type));
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    } catch (err) {
        yield put(httpRequestsOnLoadActions.removeLoading(type));
    }
}

export const watchEvents = function* () {
    yield takeLatest(GET_EVENTS, getEvents);
    yield takeLatest(GET_EVENT_BY_ID, getEventById);
    yield takeLatest(GET_EVENT_SPONSORS, getEventSponsors);

    /** Rsvp **/
    yield takeLatest(CREATE_RSVP, createRsvp);
    yield takeLatest(EDIT_RSVP, editRsvp);
    yield takeLatest(GET_CURRENT_RSVP, getCurrentRsvp);
    /** End **/
    yield takeLatest(GET_CATEGORIES, getCategories);
};
