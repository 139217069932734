import React, { useState } from 'react';
import { DateRangePicker } from 'react-date-range';
import { Svg } from 'assets';
import Popper from '@mui/material/Popper';
import moment from 'moment';
import { SaveParams } from 'utils';
import { useLocation, useNavigate } from 'react-router';
import { DateRow } from 'components';

export const DateSelector = ({ link, min, max }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const navigate = useNavigate();
    const location = useLocation();
    const info = location?.state;
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;
    const [state, setState] = useState([
        {
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection',
        },
    ]);

    const handleDateChange = (item) => {
        setState([item.selection]);
        const startTime = moment(item?.selection?.startDate).utc();
        startTime.hour(0);
        startTime.minute(0);
        startTime.second(1);
        const endTime = moment(item?.selection?.endDate).utc();
        endTime.hour(23);
        endTime.minute(59);
        endTime.second(59);

        const params = {
            ...info,
        };
        params.startDate = item?.selection?.startDate;
        params.endDate = item?.selection?.endDate;
        SaveParams(link, navigate, params);
    };

    const handleClick = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    return (
        <div className="date-selector-box">
            <button className="date-range-selector" aria-describedby={id} type="button" onClick={handleClick}>
                <Svg.EventCalendar className="date-range-selector-svg" />
                <p>{info?.startDate ? <DateRow date={info?.startDate} /> : 'Start Date'}</p>
                <Svg.DateArrowRight />
                <p>{info?.endDate ? <DateRow date={info?.startDate} /> : <span style={{ color: '#535862' }}>End Date</span>}</p>
            </button>

            <button className="date-range-selector-mobile" aria-describedby={id} type="button" onClick={handleClick}>
                <Svg.EventCalendar className="date-range-selector-svg" />
                <Svg.DateArrowRight />
                <Svg.EventCalendar />
            </button>

            <Popper
                id={id}
                open={open}
                anchorEl={anchorEl}
                className={'custom-select-paper-box'}
                placement="bottom-start"
                style={{ position: 'absolute', zIndex: 20 }}>
                <DateRangePicker minDate={min} maxDate={max} ranges={state} onChange={handleDateChange} showDateDisplay={false} />
            </Popper>
            {open && <div className="beck-drop" onClick={() => setAnchorEl(null)} />}
        </div>
    );
};
