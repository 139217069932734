import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { eventActions } from 'store';
import { SearchBox } from 'fragments';
import eventImage from '../../../assets/images/img/eventImage.png';
import businessImage from '../../../assets/images/img/banner.png';
import orgImage from '../../../assets/images/img/footer.png';
import { Svg } from 'assets';
import { LinearProgress } from '@mui/material';

export const Discover = () => {
    const [progress, setProgress] = useState(0);
    const [isRunning, setIsRunning] = useState(true);
    const dispatch = useDispatch();
    const categories = useSelector((state) => state.events.categories);
    const [index, setIndex] = useState(1);
    const imageTooShow = index === 1 ? eventImage : index === 2 ? orgImage : businessImage;
    const refTimer = useRef();

    useEffect(() => {
        dispatch(eventActions.getCategories());
    }, [dispatch]);

    useEffect(() => {
        const transitionTime = 5000;
        const progressUpdateInterval = 50;

        if (isRunning) {
            let startTime = Date.now();

            refTimer.current = window.setInterval(() => {
                const elapsedTime = Date.now() - startTime;

                setProgress(Math.min((elapsedTime / transitionTime) * 100, 100));

                if (elapsedTime >= transitionTime) {
                    setIndex((prevIndex) => (prevIndex === 3 ? 1 : prevIndex + 1));
                    startTime = Date.now();
                    setProgress(0);
                }
            }, progressUpdateInterval);
        }

        return () => {
            window.clearInterval(refTimer.current);
        };
    }, [isRunning]);

    const toggleTimer = () => {
        setIsRunning((prev) => !prev);

        if (isRunning) {
            setProgress(100);
            window.clearInterval(refTimer.current);
        } else {
            setProgress(0);
        }
    };

    return (
        <div className='discover-section'>
            <div className='search-wrapper'>
                <div className='search-box-banner'>
                    <p className='search-box-title'>
                        Discover Armenian Events, Businesses, Nonprofits
                    </p>
                    <p className='search-box-description'>
                        Find upcoming events, businesses, and nonprofits in your area!
                    </p>

                    <div className='search-box-categories'>
                        <SearchBox />

                        <div className='tags-box'>
                            {categories?.map((category, k) => (
                                <button className='tag' key={k}>
                                    {category}
                                </button>
                            ))}
                        </div>
                    </div>
                </div>
            </div>

            <div className='slider-wrapper-box'>
                <div className='slider-wrapper' style={{ backgroundImage: `url(${imageTooShow})` }}>
                    <div className='slider-types-box'>
                        <div className='slider-type'>
                            <p style={index === 1 ? { fontWeight: '700' } : {}}>Discover Events</p>
                            {index === 1 ? (
                                <LinearProgress color='inherit' variant='determinate' value={progress} />
                            ) : (
                                index > 1 ? <div className='liner-done' />
                                    :
                                    <div className='liner-dont-done' />
                            )}
                        </div>
                        <div className='slider-type'>
                            <p style={index === 2 ? { fontWeight: '700' } : {}}>Find Businesses</p>
                            {index === 2 ? (
                                <LinearProgress color='inherit' variant='determinate' value={progress} />
                            ) : (
                                index > 2 ? <div className='liner-done' />
                                    :
                                    <div className='liner-dont-done' />
                            )}
                        </div>
                        <div className='slider-type'>
                            <p style={index === 3 ? { fontWeight: '700' } : {}}>Support Nonprofits</p>
                            {index === 3 ? (
                                <LinearProgress color='inherit' variant='determinate' value={progress} />
                            ) : (
                                <div className='liner-dont-done' />
                            )}
                        </div>

                        <button onClick={toggleTimer}>
                            {isRunning ? <Svg.StopBtn /> : <Svg.PlaySvg />}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};
