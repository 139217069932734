import React from 'react';
import { useSelector } from 'react-redux';
import { EventListCard } from 'fragments';
import { NotYetScreen } from 'components';
import { Images } from 'assets';

export const EventList = () => {
    const allEvents = useSelector((state) => state.events.events);

    return (
        <div>
            {allEvents?.events?.length ? (
                <>
                    {allEvents?.events?.map((event, j) => (
                        <React.Fragment key={j}>
                            <EventListCard event={event} />
                        </React.Fragment>
                    ))}
                </>
            ) : (
                <NotYetScreen
                    image={Images.EmptyState}
                    title={'No Events Added Yet'}
                    desc={'It looks like there are no events added yet. Please check back later or explore other sections of our site.'}
                />
            )}
        </div>
    );
};
