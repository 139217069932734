import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ModalProvider, Modals, initAxiosInterceptors } from './utils';
import './assets/styles/index.scss';
import './assets/styles/global.scss';
import { store } from './store';

initAxiosInterceptors();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    // <React.StrictMode>
    <BrowserRouter>
        <ModalProvider>
            <Provider store={store}>
                <App />
                <Modals />
            </Provider>
        </ModalProvider>
    </BrowserRouter>
    // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
