import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { dateConverter } from 'utils';
import { Svg } from 'assets';
import { ticketActions } from 'store';
import { buyTicketStyles } from '../styles';

export const TicketEvent = ({ event, selectedTickets }) => {
    const classes = buyTicketStyles();
    const dispatch = useDispatch();
    const [total, setTotal] = useState(null);

    useEffect(() => {
        if (selectedTickets) {
            let totalPrice = 0;
            let totalCount = 0;
            selectedTickets?.map((i) => {
                totalPrice = totalPrice + i?.price * i?.count;
                totalCount = totalCount + i?.count
            });

            setTotal({
                totalAmount: totalPrice,
                totalCount: totalCount,
            });
            dispatch(
                ticketActions.saveTotal({
                    totalAmount: totalPrice,
                    totalCount: totalCount,
                })
            );
        }
    }, [selectedTickets]);

    const eventImage = event?.images?.length > 0 ? event.images[event.eventImage ? event.eventImage : 0].url : null;

    return (
        <div className={classes.ticketEventWrapper}>
            <div>
                <div className={classes.eventImageAndTitle}>
                    {eventImage ? <img className={classes.eventImage} src={eventImage} alt="icon" /> : <Svg.Gallery />}
                    <div>
                        <p className={classes.eventTitle}>{event?.title}</p>
                        <p className={classes.orderSummary}>Order Summary</p>
                    </div>
                </div>

                <div className={classes.eventInfo}>
                    <div className={classes.titleAndInfo}>
                        <p>Date</p>
                        <span>{dateConverter(event)}</span>
                    </div>
                    <div className={classes.titleAndInfo}>
                        <p>Time</p>
                        <span>{event?.allDay ? 'All Day' : dateConverter(event, 'LT')}</span>
                    </div>
                    <div className={classes.titleAndInfo}>
                        <p>Location</p>
                        <span>{event?.address?.formattedAddress ? event?.address?.formattedAddress : 'Not Set'}</span>
                    </div>
                </div>

                {selectedTickets?.length ? (
                    <div className={classes.eventInfo}>
                        {selectedTickets?.map((i, j) => (
                            <div key={j} className={classes.titleAndInfo}>
                                <p>{`${i?.count} x ${i?.name}`}</p>
                                <span>$ {i?.price * i?.count}</span>
                            </div>
                        ))}
                    </div>
                ) : (
                    ''
                )}

                <div style={{ marginTop: '16px' }}>
                    <div className={classes.titleAndInfo}>
                        <p>Total Tickets</p>
                        <span>x {total?.totalCount ? total?.totalCount : 0}</span>
                    </div>
                    <div className={classes.titleAndInfo}>
                        <p>Total Amount</p>
                        <span>$ {total?.totalAmount ? total?.totalAmount : 0}</span>
                    </div>
                </div>
            </div>
        </div>
    );
};
