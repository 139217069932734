import { CircularProgress } from '@mui/material';
import React from 'react';

export const PageLoader = ({ height }) => {
    return (
        <div className="page-loader" style={{ height: '100vh' }}>
            <div className="page-loader-box" style={height ? { height: height } : {}}>
                <CircularProgress
                    style={{
                        width: '100px',
                        height: '100px',
                        color: '#000',
                    }}
                />
            </div>
        </div>
    );
};
