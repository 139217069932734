import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Map, Marker, GoogleApiWrapper, InfoWindow } from 'google-maps-react';
import { Svg } from 'assets';
import { mapStyles } from 'fragments';
import { renderLink, useWindowDimensions } from 'utils';
import { PhoneRow } from '../../../components';
import { renderTypeAndIcon } from './constants';

const SearchedMapView = (props) => {
    const { searchResults } = useSelector((state) => ({
        searchResults: state.auth.searchResults,
    }));
    const [showInfoWindow, setShowInfoWindow] = useState(false);
    const [activeMarker, setActiveMarker] = useState(null);
    const [item, setItem] = useState(null);
    const { width } = useWindowDimensions();

    const mapStyle = {
        width: '100%',
        height: width > 768 ? '1000px' : '70vh',
        position: 'relative',
    };

    const onMarkerClick = (props, marker, value) => {
        setItem(value);
        setActiveMarker(marker);
        setShowInfoWindow(true);
    };

    const onClose = () => {
        if (showInfoWindow) {
            setShowInfoWindow(false);
            setActiveMarker(null);
        }
    };

    const handleClick = () => {
        window.open(renderLink(item), '_blank');
    };

    return (
        <div className="org-map-view-section">
            <Map
                google={props.google}
                zoom={12}
                styles={mapStyles}
                style={mapStyle}
                initialCenter={{
                    lat: 40.712776,
                    lng: -74.005974,
                }}
                containerStyle={mapStyle}
                streetViewControl={false}
                fullscreenControl={false}
                mapTypeControl={false}>
                {searchResults?.results?.map(
                    (i) =>
                        i?.address?.lat && (
                            <Marker
                                onClick={(e, j) => onMarkerClick(e, j, i)}
                                position={{
                                    lat: i.address.lat,
                                    lng: i.address.lng,
                                }}
                                icon={Svg.MapMarker}
                                key={i?.id}
                            />
                        )
                )}
                <InfoWindow marker={activeMarker} visible={showInfoWindow} onClose={onClose}>
                    <div className="org-map-card" style={{ width: '343px' }}>
                        <div className="org-map-image-and-title">
                            <div className="org-map-image-box">
                                {item?.image ? <img src={item?.image?.url} alt="icon" /> : <Svg.Graphic />}
                            </div>

                            <div>
                                <div className="org-map-title" style={{ width: '200px' }}>
                                    <p>
                                        {item?.name ? (item?.name?.length > 20 ? `${item?.name.slice(0, 20)}...` : item?.name) : 'Not Set'}
                                    </p>
                                </div>

                                {renderTypeAndIcon(item?.type, handleClick, 'search-map-card-button')}

                                {item?.address?.formattedAddress && (
                                    <div className="org-map-info-box">{item?.address?.formattedAddress}</div>
                                )}
                            </div>
                        </div>

                        {(item?.phoneNumber || item?.email) && (
                            <div className="org-map-phone-and-email">
                                {item?.phoneNumber && (
                                    <div className="org-map-info-box" style={{ marginBottom: '8px' }}>
                                        <Svg.CallCalling />
                                        <a href={`tel:+${item?.phoneNumber}`}>
                                            <PhoneRow phone={item?.phoneNumber} />
                                        </a>
                                    </div>
                                )}

                                {item?.email && (
                                    <div className="org-map-info-box">
                                        <Svg.Sms />
                                        <a href={`mailto:${item?.email}`}>{item?.email}</a>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </InfoWindow>
            </Map>
        </div>
    );
};

export default GoogleApiWrapper({
    apiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY,
})(SearchedMapView);
