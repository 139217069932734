import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { TagsCards } from 'fragments';
import { userInfo } from 'utils';
import { authActions } from 'store';

export const TagBox = ({ params }) => {
    const dispatch = useDispatch();
    const { myProfile } = useSelector((state) => ({
        myProfile: state.auth.myProfile,
    }));

    const user = myProfile || userInfo;
    const [selectedTags, setSelectedTags] = useState([]);

    useEffect(() => {
        if (user?.prefCategories && !selectedTags.length) {
            setSelectedTags(user?.prefCategories || []);
        }
    }, [user]);

    const handleAddCategory = (cat) => {
        const params = {};
        if (selectedTags?.includes(cat)) {
            const newList = selectedTags.filter((i) => i !== cat);
            setSelectedTags(newList);
            params.prefCategories = newList;
        } else {
            params.prefCategories = [...selectedTags, cat];
            setSelectedTags([...selectedTags, cat]);
        }
        dispatch(authActions.editAccount(params));
    };

    return (
        <div className='tag-box-wrapper'>
            <p className='tag-box-title'>{params?.title}</p>

            <TagsCards
                tagsList={params?.list}
                selectedTags={selectedTags}
                handleAddCategory={handleAddCategory}
            />
        </div>
    );
};
