import { Svg } from 'assets';

export const RsvpEnums = {
    INTERESTED: 'INTERESTED',
    GOING: 'GOING',
    NOTGOING: 'NOTGOING',
};

export const CREATE_RSVP_ACTION = 'CREATE_RSVP';
export const EDIT_RSVP_ACTION = 'EDIT_RSVP';

export const renderButtonText = (type) => {
    return type === 'contactUs'
        ? 'Contact Us'
        : type === 'emailUs'
        ? 'Send Email'
        : type === 'register'
        ? 'Register'
        : type === 'buyTickets'
        ? 'Buy Tickets'
        : type === 'donate'
        ? 'Donate'
        : type === 'moreInfo'
        ? 'More Info'
        : type === 'bookNow'
        ? 'Book Now'
        : '';
};

export const renderButtonIcon = (type) => {
    return type === 'contactUs' ? (
        <Svg.ContactCta />
    ) : type === 'emailUs' ? (
        <Svg.SendEmailCta />
    ) : type === 'register' ? (
        <Svg.RegisterCta />
    ) : type === 'donate' ? (
        <Svg.DonateCta />
    ) : type === 'bookNow' ? (
        <Svg.BookCta />
    ) : (
        ''
    );
};
