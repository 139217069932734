import React from 'react';
import { Breadcrumb } from 'components';
import { Art, Entertainment, TagBox, FoodDining } from './fragments';

export const Recommendations = () => {
    return (
        <div className="recommendations-page">
            <div className="recommendations-wrapper">
                <Breadcrumb list={[{ to: '/', title: 'Home' }, { title: 'All Interests' }]} />
                <p className="page-global-title">Personalized Recommendations Just for You</p>
                <p className="recommendations-page-subtitle">Customize Your Armat Experience by Selecting Your Interests</p>

                <div className="recommendations-tabs-wrapper">
                    <TagBox params={Entertainment} />
                    <TagBox params={Art} />
                    <TagBox params={FoodDining} />
                </div>
            </div>
        </div>
    );
};
