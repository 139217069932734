import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { ButtonMain, CancelBtn, InputMain, AddressInput, PhoneInput } from 'components';
import { FindError, FindSuccess, useModal } from 'utils';
import { httpRequestsOnErrorsActions, httpRequestsOnSuccessActions, organizationActions } from 'store';
import { CategoryItems } from '../createOrg/categoryItems';
import { EDIT_ORG_TYPE } from './constants';

export const OrgDetails = ({ type }) => {
    const { orgCategories, org } = useSelector((state) => ({
        orgCategories: state.orgs.orgCategories,
        org: state.orgs.organizationsById,
    }));
    const dispatch = useDispatch();
    const { close } = useModal(EDIT_ORG_TYPE);
    const backError = FindError(EDIT_ORG_TYPE);
    const successLogin = FindSuccess(EDIT_ORG_TYPE);
    const [categoryArray, setCategoryArray] = useState([]);
    const [newCategList, setNewCategList] = useState([]);
    const [description, setDescription] = useState('');
    const [selected, setSelected] = useState([]);
    const [selectedCategs] = useState(org ? org?.categories && [...org.categories] : '');
    const arr = [];
    const { handleSubmit, control, setError, setValue } = useForm({
        defaultValues: {
            name: org?.name,
            email: org?.email,
            phoneNumber: org?.phoneNumber,
            website: org?.website,
            address: org?.address?.formattedAddress,
        },
    });

    useEffect(() => {
        if (orgCategories?.length) {
            getTree(orgCategories);
        }
    }, [orgCategories]);

    useEffect(() => {
        if (org?.description) {
            setDescription(org?.description);
        }
        if (org?.categories) {
            handleGetTree();
        }
    }, [org]);

    useEffect(() => {
        if (successLogin) {
            close();
            dispatch(httpRequestsOnSuccessActions.removeSuccess(EDIT_ORG_TYPE));
        }
    }, [successLogin]);

    useEffect(() => {
        dispatch(organizationActions.getOrgCategories());
    }, []);

    useEffect(() => {
        if (backError?.type === EDIT_ORG_TYPE) {
            if (backError?.error === 'Such user does not exist in our records') {
                setError('website', {
                    message: 'Website must be a URL address',
                });
            }
            dispatch(httpRequestsOnErrorsActions.removeError(EDIT_ORG_TYPE));
        }
    }, [dispatch, backError]);

    const onSubmit = (data) => {
        const formData = {
            ...data,
            type: type,
        };
        if (data?.email) {
            formData.email = data.email;
        } else {
            delete formData.email;
        }

        if (data?.phoneNumber) {
            formData.phoneNumber = `${parseInt(data?.phoneNumber.replace(/[^0-9]/g, ''))}`;
        } else {
            delete formData.phoneNumber;
        }
        if (newCategList?.length) {
            formData.categories = newCategList;
        }

        dispatch(organizationActions.editOrg(org?.id, formData, true));
    };

    const getTree = (org) => {
        org.length &&
            org.map((it) => {
                arr.push(it);
                if (it.items) {
                    return getTree(it.items);
                }
                setCategoryArray(arr);
            });
    };

    function getParent(model, id) {
        let path,
            item = {
                id: model.id,
                text: model.text,
            };
        if (!model || typeof model !== 'object') return;
        if (model.id === id) return [item];
        (model.items || []).some((child) => (path = getParent(child, id)));
        return path && [item, ...path];
    }

    const handleGetTree = async () => {
        const newItems = (await orgCategories?.length) && org?.categories.map((h) => orgCategories.map((i) => getParent(i, h.id)));
        const arr = [];
        newItems && newItems.filter((k) => k.map((l) => l !== undefined && arr.push(l)));
        setSelected([...arr]);
    };

    return (
        <div className="create-org-box">
            <div className="subtitle-box">
                <h3 className="subtitle">Basic Info</h3>
                <p className="paragraph">Provide some basic organization info to let users know about you.</p>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="org-inputs-box">
                    <InputMain
                        rules={{
                            required: 'This Field is required',
                        }}
                        name="name"
                        type="text"
                        control={control}
                        placeholder="Enter the name of the organization"
                        labelHeader="Organization Name"
                    />
                    <AddressInput
                        name="address"
                        control={control}
                        placeholder="Enter org. address"
                        labelHeader="Organization Address"
                        setValue={setValue}
                    />
                </div>
                <div className="org-inputs-box">
                    <PhoneInput
                        name={'phoneNumber'}
                        control={control}
                        placeholder="Enter the phone number of the organization"
                        labelHeader="Phone Number"
                    />
                    <InputMain name="email" type="email" control={control} placeholder="Enter org. email" labelHeader="Email Address" />
                </div>
                <div className="org-inputs-box">
                    <InputMain name="website" type="text" control={control} placeholder="Enter website URL" labelHeader="Website" />

                    <div style={{ width: '100%' }}>
                        <CategoryItems
                            eventInfo={org}
                            selectedIdInfos={selectedCategs}
                            selectedInfo={selected}
                            categories={categoryArray}
                            allCategories={orgCategories}
                            handleGetNewList={(newArr) => setNewCategList(newArr)}
                            color={'#494949'}
                            title={'Categories'}
                        />
                    </div>
                </div>
                <div className="create-org-basic-info">
                    <div className="subtitle-box" style={{ marginTop: 0 }}>
                        <h3 className="subtitle">Description</h3>
                        <p className="paragraph">Provide short description for your organization.</p>
                    </div>
                    <div className={'rich-container'}>
                        <ReactQuill
                            theme="snow"
                            value={description}
                            onChange={setDescription}
                            placeholder="Enter the Description here..."
                        />
                    </div>
                </div>
                <div className="create-org-btns">
                    <CancelBtn />
                    <ButtonMain actionTye={EDIT_ORG_TYPE} text="Save" type="submit" />
                </div>
            </form>
        </div>
    );
};
