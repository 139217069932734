import { useEffect } from 'react';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { organizationActions } from 'store';
import { OrgInformation, OrgDetailsSection, OrgEvents } from './fragment';
import { FindLoad, token, userInfo } from 'utils';
import { PageLoader } from 'components';
import { SimilarOrgs } from './fragment/similarOrgs';

const ACTION_TYPE = 'GET_ORGANIZATION_BY_ID';
export const OrgDetails = () => {
    const params = useParams();
    const dispatch = useDispatch();
    const loader = FindLoad(ACTION_TYPE);

    useEffect(() => {
        if (token) {
            dispatch(organizationActions.checkClaim(params?.id));
        }
        if (token && userInfo) {
            dispatch(organizationActions.getFollows());
        }
        if (token && userInfo) {
            dispatch(organizationActions.getOrgAdmins(params?.id));
        }
        dispatch(organizationActions.getOrgById(params?.id));
        dispatch(organizationActions.getOrgSocials(params?.id));
        dispatch(organizationActions.orgEvents(params?.id));
    }, [dispatch]);

    if (loader) {
        return <PageLoader />;
    }

    return (
        <div className="org-details-page">
            <OrgInformation />

            <div className="org-details-by-padding">
                <OrgDetailsSection />
                <OrgEvents />
                <SimilarOrgs />
            </div>
        </div>
    );
};
