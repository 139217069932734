import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ButtonMain, CancelBtn } from "components";
import { organizationActions } from "store";
import { Svg } from "assets";
import { EDIT_ORG_TYPE } from "./constants";

const reviews = [
    { icon: Svg.OrgGoogle, name: "googlePlaceId", placeholder: "Add Google Place ID" },
    { icon: Svg.OrgYelp, name: "yelpBusinessId", placeholder: "Add Yelp Place ID" }
];

export const OrgReviews = () => {
    const { orgSocials, org } = useSelector((state) => ({
        orgSocials: state.orgs.orgSocials,
        org: state.orgs.organizationsById
    }));

    const dispatch = useDispatch();
    const [inputs, setInputs] = useState({});

    useEffect(() => {
        if (orgSocials) {
            const socialInfo = {};
            orgSocials?.googleReviews ? (socialInfo.googlePlaceId = orgSocials?.googleReviews?.entityId) : delete socialInfo.googlePlaceId;
            orgSocials?.yelpReviews ? (socialInfo.yelpBusinessId = orgSocials?.yelpReviews?.entityId) : delete socialInfo.yelpBusinessId;
            setInputs(socialInfo);
        }
    }, [orgSocials]);

    const handleChange = (e) => {
        setInputs((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
    };

    const handleDelete = (name) => {
        const newInputs = {
            ...inputs
        };
        newInputs[name] = null;
        setInputs(newInputs);
    };

    const handleSave = () => {
        const formData = {
            phoneNumber: org?.phoneNumber,
            email: org?.email,
            type: org?.type,
            name: org?.name,
            address: org?.address?.formattedAddress,
            ...inputs
        };
        dispatch(organizationActions.editOrg(org?.id, formData));
    };

    return (
        <>
            <div>
                <div className="subtitle-box">
                    <h3 className="subtitle">Connect Reviews</h3>
                    <p className="paragraph">
                        Add the google place ID or the yelp business ID of the organization to display reviews from these platforms.
                    </p>
                </div>
                <div className="reviews-inputs">
                    {reviews?.map((i, j) => (
                        <div className="connect-box" key={j}>
                            <div
                                className={`connect-box-icon_input ${inputs?.[i?.name]?.length > 0 ? "connect-box-icon_input-fill" : ""}`}>
                                <div className="social-icon">
                                    <img src={i?.icon} alt="icon" />
                                </div>

                                <input
                                    type={"text"}
                                    className="social-input"
                                    name={i?.name}
                                    placeholder={i?.placeholder}
                                    value={inputs[i?.name]}
                                    onChange={handleChange}
                                />
                            </div>

                            <div className="social-delete">
                                <button type="button" className="btn-delete" onClick={() => handleDelete(i?.name)}>
                                    <Svg.Close />
                                </button>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className="edit-org-btns">
                <CancelBtn />
                <ButtonMain onClick={handleSave} actionTye={EDIT_ORG_TYPE} text="Save" />
            </div>
        </>
    );
};
