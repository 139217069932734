import { organizationsStyle } from './style';
import { OrganizerCateg } from './accordionTree';

export const Filters = ({ type }) => {
    const classes = organizationsStyle();

    return (
        <div className={classes.filterWrapper}>
            <div className={classes.treeItem}>
                <OrganizerCateg type={type} />
            </div>
        </div>
    );
};
