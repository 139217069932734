import React from 'react';
import { useSelector } from 'react-redux';
import SingleMap from '../../../fragments/map/singleMap';

export const EventMap = () => {
    const event = useSelector((state) => state.events.eventById);

    return (
        <div className="details-white-box" style={{ padding: 0 }}>
            <div className="event-map-section">
                <SingleMap address={event?.address} />
                {event?.address && (
                    <div className="map-details">
                        <p className="community">{event?.address?.city}</p>
                        <p className="formatted-address">{event?.address?.formattedAddress}</p>
                    </div>
                )}
            </div>
        </div>
    );
};
