import { DateRow, PriceRow, TextRow, NotYetScreen } from 'components';
import { Images, Svg } from 'assets';
import { useSelector } from 'react-redux';
import React from 'react';

export const Invoices = () => {
    const { invoices } = useSelector((state) => ({
        invoices: state?.payments?.invoices,
    }));

    return (
        <div>
            <p className="payment-method-title">Invoices</p>
            {invoices?.length ? (
                <div className="invoices-table-wrapper">
                    <div className="invoices-table-header">
                        <div className="table-header-item" style={{ width: '30%' }}>
                            Invoice Date
                        </div>
                        <div className="table-header-item" style={{ width: '30%' }}>
                            Description
                        </div>
                        <div className="table-header-item" style={{ width: '20%' }}>
                            Amount
                        </div>
                        <div className="table-header-item" style={{ width: '20%' }}>
                            Action
                        </div>
                    </div>

                    <div className="invoices-table-body-wrapper">
                        {invoices?.map((i, j) => (
                            <div className="invoices-table-body" key={j}>
                                <div className="invoices-table-body-item" style={{ width: '30%' }}>
                                    <DateRow date={i?.createdAt ? i?.createdAt : ''} format={'ll'} />
                                </div>
                                <div className="invoices-table-body-item" style={{ width: '30%' }}>
                                    <TextRow name={i?.description} />
                                </div>
                                <div className="invoices-table-body-item" style={{ width: '20%' }}>
                                    <PriceRow info={i?.amountPaid ? i?.amountPaid : 0} />
                                </div>
                                <div className="invoices-table-body-item" style={{ width: '20%' }}>
                                    <button className="invoices-table-body-item-action-button">
                                        <Svg.EyeSvg />
                                    </button>
                                    <button className="invoices-table-body-item-action-button">
                                        <Svg.Download />
                                    </button>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            ) : (
                <NotYetScreen
                    image={Images.EmptyInvoices}
                    title={'No Invoices Available'}
                    desc={"It looks like you don't have any invoices yet. Once you make a purchase, your invoices will appear here."}
                />
            )}
        </div>
    );
};
