// import { Svg } from 'assets';

export const unlockExclusive = [
    {
        title: 'Stay Updated with Market Insights',
        // Icon: Svg.Stars,
    },
    {
        title: 'Access Your Reports Anytime, Anywhere',
        // Icon: Svg.Stars,
    },
    {
        title: 'Priority Customer Support',
        // Icon: Svg.Stars,
    },
    {
        title: 'Exclusive Offers and Discounts',
        // Icon: Svg.Stars,
    },
];

export const checkPassword = [
    {
        message: 'Must be at least 8 characters long',
        regex: /.{8,}$/,
    },
];
