import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { EventGrid, EventList, EventFilters, EventListTypes, LOAD_EVENTS } from './fragments';
import { eventActions } from 'store';
import { FindLoad } from 'utils';
import { PageLoader, PaginationItem } from 'components';
import EventMapView from './fragments/eventMapView';

export const Events = () => {
    const { myProfile, allEvents } = useSelector((state) => ({
        myProfile: state.auth.myProfile,
        allEvents: state.events.events,
    }));
    const dispatch = useDispatch();
    const loader = FindLoad(LOAD_EVENTS);
    const location = useLocation();
    const info = location?.state;
    const checkDefaultList = info?.listType ? info?.listType === EventListTypes?.GRID : true;

    useEffect(() => {
        const params = {
            ...info,
            limit: 10,
        };
        delete params.page;
        delete params.dateType;
        delete params.listType;

        params.skip = info?.skip ? info?.skip : 0;

        if (info?.listType === EventListTypes?.MAP) {
            delete params.eventLocation;
            delete params.skip;
            delete params.limit;
        }

        if (info?.categories?.length) {
            params.categories = info.categories;
        } else {
            if (myProfile?.prefCategories?.length > 0) {
                params.categories = myProfile?.prefCategories;
            }
        }

        dispatch(eventActions.getEvents(params));
    }, [dispatch, info]);

    useEffect(() => {
        dispatch(eventActions.getCategories());
    }, [dispatch]);

    return (
        <>
            <div className={info?.listType === EventListTypes?.MAP ? '' : 'events-page'}>
                <div>
                    <div
                        style={info?.listType === EventListTypes?.MAP ? { paddingBottom: 0, minHeight: 'auto' } : {}}
                        className={info?.listType === EventListTypes?.MAP ? 'events-page' : ''}>
                        <EventFilters />
                    </div>
                    {loader ? (
                        <PageLoader height={'60vh'} />
                    ) : (
                        <div>
                            {checkDefaultList && <EventGrid />}
                            {info?.listType === EventListTypes?.LIST && <EventList />}
                            {info?.listType === EventListTypes?.MAP && <EventMapView />}
                        </div>
                    )}
                </div>

                {allEvents?.events?.length > 0 && info?.listType !== EventListTypes?.MAP && (
                    <PaginationItem count={allEvents?.count} link={'/events'} />
                )}
            </div>
        </>
    );
};
