import {
    GET_EVENT_BY_ID_SUCCESS,
    GET_EVENTS_SUCCESS,
    GET_CURRENT_RSVP_SUCCESS,
    GET_EVENT_SPONSORS_SUCCESS,
    GET_CATEGORIES_SUCCESS,
} from './events.types';

const initialState = {
    events: null,
    eventById: null,
    eventRsvp: null,
    eventSponsors: null,
    categories: null,
};

export const eventsReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_EVENTS_SUCCESS:
            return {
                ...state,
                events: action.payload?.data,
            };

        case GET_EVENT_BY_ID_SUCCESS:
            return {
                ...state,
                eventById: action.payload?.data,
            };

        case GET_CURRENT_RSVP_SUCCESS:
            return {
                ...state,
                eventRsvp: action.payload,
            };

        case GET_EVENT_SPONSORS_SUCCESS:
            return {
                ...state,
                eventSponsors: action.payload,
            };

        case GET_CATEGORIES_SUCCESS:
            return {
                ...state,
                categories: action.payload,
            };

        default:
            return state;
    }
};
