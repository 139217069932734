import React from 'react';
import { useLocation, useNavigate } from 'react-router';
import { Pagination } from '@mui/material';
import { paginationStyle } from './style';
import { SendPageSave } from 'utils';

export const PaginationItem = ({ count, link }) => {
    const classes = paginationStyle();
    const limitCountNumber = 10;
    const navigate = useNavigate();
    const location = useLocation();
    const info = location?.state;
    const page = info?.page;
    const handleChangePage = (val) => {
        SendPageSave(navigate, val, info, link);
    };

    return (
        <div className={classes.PaginationWrapper}>
            <Pagination
                onChange={(event, val) => handleChangePage(val)}
                page={page}
                count={count && Math.ceil(count / limitCountNumber)}
                color={'primary'}
            />
        </div>
    );
};
