import React, { useEffect, useState } from 'react';
import { FileUploader } from 'react-drag-drop-files';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { ButtonMain, CancelBtn } from 'components';
import { Svg } from 'assets';
import { httpRequestsOnLoadActions, organizationActions } from 'store';
import { UploadImage } from './core/uploadImage';
import { EDIT_ORG_TYPE } from './constants';

const fileTypes = ['JPEG', 'SVG', 'JPG', 'PNG', 'GIF'];

export const OrgImages = () => {
    const orgById = useSelector((state) => state.orgs.organizationsById);
    const dispatch = useDispatch();
    const [img, setImg] = useState([]);
    const [imgPush, setImgPush] = useState([]);
    const [imgIndex, setIndex] = useState(0);
    const [deletedImg, setDeletedImg] = useState([]);
    const [error, setError] = useState([]);
    const [file, setFile] = useState(null);
    const [loaderUpload, setLoaderUpload] = useState([]);
    const [loaderLogo, setLoaderLogo] = useState(false);
    const [logo, setLogo] = useState(null);
    const [remove, setRemove] = useState(false);

    console.log(loaderUpload);
    console.log(orgById);
    console.log(loaderLogo);
    useEffect(() => {
        if (orgById?.mainImage) {
            setIndex(orgById.mainImage);
        }
        if (orgById?.images?.length) {
            setImg(orgById?.images);
        }
        if (orgById?.avatar) {
            setLogo(orgById?.avatar?.url);
        }
    }, [orgById]);

    const handleClearImg = (key, item) => {
        setIndex(0);
        const deletedImages = [...imgPush];
        deletedImages.splice(key, 1);
        setImgPush(deletedImages);
        const deletedImagesFile = [...img];
        deletedImagesFile.splice(key, 1);
        setImg(deletedImagesFile);
        const newArr = [...deletedImg, item];
        setDeletedImg(newArr);
    };

    const handleFileChange = (e) => {
        const newArr = [...img];
        const imageArr = [...imgPush];
        for (let item of e) {
            if (item && item.size > 2097152) {
                setError(true);
            } else {
                setError('');
                newArr.push({
                    url: URL.createObjectURL(new File([item], 'image', { type: 'text/json;charset=utf-8' })),
                    id: newArr.length + 1,
                });
                setImg(newArr);
                imageArr.push(new File([item], `img${newArr.length + 1}`));
                setImgPush(imageArr);
            }
        }
    };

    const handleChangeImages = async (file) => {
        if (file) {
            if (file?.size > 2097152) {
                setError(true);
            } else {
                const formData = new FormData();
                const endpoint = `/files/upload`;
                setLoaderLogo(true);
                formData.append('files', file);
                setLogo(URL.createObjectURL(new File([file], 'image', { type: 'text/json;charset=utf-8' })));
                await axios
                    .post(endpoint, formData, { auth: true })
                    .then((res) => {
                        setFile({
                            ...res.data,
                            thumbUrl: res?.data?.url,
                        });
                        setLoaderLogo(true);
                        return res.data;
                    })
                    .catch(() => setLoaderLogo(true));
                setError('');
            }
        }
    };

    const removeLogo = () => {
        setLogo(null);
        setRemove(true);
    };

    const sendData = async () => {
        const formData = new FormData();
        const endpoint = `/files/uploadMany?includeThumbnail=true`;
        imgPush.length && imgPush.map((i) => formData.append('files', i));
        dispatch(httpRequestsOnLoadActions.appendLoading('EDIT_ORGANIZATION'));
        const uploadedImg =
            imgPush.length &&
            (setLoaderUpload(true),
            await axios
                .post(endpoint, formData, { auth: true })
                .then((res) => {
                    setLoaderUpload(false);
                    return res.data;
                })
                .catch(() => setLoaderUpload(false)));

        const editDate = {
            ...orgById,
            // phoneNumber: orgById?.phoneNumber,
            // email: orgById?.email,
            // type: orgById?.type,
            // name: orgById?.name,
            // address: orgById?.address?.formattedAddress,
        };
        orgById?.address?.formattedAddress ? (editDate.address = orgById?.address?.formattedAddress) : delete editDate.address;

        editDate['mainImage'] = +imgIndex;
        uploadedImg ? (editDate['imagesToAdd'] = [...uploadedImg]) : delete editDate.imagesToAdd;
        deletedImg?.length ? (editDate['imagesToRemove'] = [...deletedImg]) : delete editDate.imagesToRemove;
        if (file) {
            file ? (editDate['changeAvatar'] = file) : delete editDate.changeAvatar;
        }
        if (remove && !file) {
            editDate['removeAvatar'] = true;
        }
        dispatch(organizationActions.editOrg(orgById?.id, editDate));
    };

    return (
        <div className="org-images-section">
            <div className="subtitle-box">
                <h3 className="subtitle">Upload a Logo</h3>
                <p className="paragraph">
                    The logo is displayed in may areas throughout Armat.org and let’s users visually identify the organization.
                </p>
            </div>
            <div className="upload-logo-wrapper">
                <div className="upload-logo-image-wrapper">
                    {logo ? (
                        <div className="no-uploaded-image">
                            <img src={logo} alt="Upload Logo" className={'uploaded-logo'} />
                            <div className="title-box">
                                <button type="button" className="remove-logo" onClick={removeLogo}>
                                    Remove Logo
                                </button>
                                <FileUploader multiple={false} handleChange={handleChangeImages} name="carouselFile" types={fileTypes}>
                                    <button type="button" className="change-logo">
                                        Change Logo
                                    </button>
                                </FileUploader>
                            </div>
                        </div>
                    ) : (
                        <>
                            <div className="title-box">
                                <FileUploader multiple={false} handleChange={handleChangeImages} name="carouselFile" types={fileTypes}>
                                    <div className="no-uploaded-image">
                                        <Svg.UploadOrgUpload />
                                        <div>
                                            <span className="text-black"> Upload Logo</span>
                                            {/*<span className="text-black"> Logo</span>*/}
                                        </div>
                                    </div>
                                </FileUploader>
                            </div>
                            <div className="paragraph-box">
                                <p> Try uploading a high resolution logo that is small in size. </p>
                            </div>
                        </>
                    )}
                </div>
            </div>

            <div className="subtitle-box">
                <h3 className="subtitle">Organization Images</h3>
                <p className="paragraph">
                    Images will be visible in a gallery and in other parts of Armat. The main image is used when displaying the organization
                    in lists and as the face image of the organization.
                </p>
            </div>
            {/*<div className="banner-box">*/}
            {/*    <h4 className="subtitle">Organization Images</h4>*/}
            {/*    <p className="paragraph">*/}
            {/*        Images will be visible in a gallery and in other parts of Armat. The main image is used when*/}
            {/*        displaying the organization*/}
            {/*        in lists and as the face image of the organization.*/}
            {/*    </p>*/}
            {/*</div>*/}
            <div id="graphic">
                <UploadImage
                    imgIndex={imgIndex}
                    loaderUpload={false}
                    eventInfo={null}
                    // eventInfo={eventInfo}
                    handleSelectIndex={(i) => setIndex(i)}
                    error={error}
                    handleClearImg={handleClearImg}
                    handleChange={handleFileChange}
                    imgSrc={img}
                    type={'organization'}
                />
            </div>
            <div className="info-feature">
                <div className="info-brn">
                    <div className="edit-org-btns">
                        <CancelBtn />
                        <ButtonMain onClick={sendData} actionTye={EDIT_ORG_TYPE} text="Save" />
                    </div>
                </div>
            </div>
        </div>
    );
};
