import moment from 'moment/moment';

export const LOAD_EVENT_BY_ID = 'GET_EVENT_BY_ID';
export const FOLLOW = 'FOLLOW';
export const UN_FOLLOW = 'UNFOLLOW';
export const mapStyles = [
    { featureType: 'water', elementType: 'geometry', stylers: [{ color: '#e9e9e9' }, { lightness: 17 }] },
    { featureType: 'landscape', elementType: 'geometry', stylers: [{ color: '#f5f5f5' }, { lightness: 20 }] },
    { featureType: 'road.highway', elementType: 'geometry.fill', stylers: [{ color: '#ffffff' }, { lightness: 17 }] },
    { featureType: 'road.highway', elementType: 'geometry.stroke', stylers: [{ color: '#ffffff' }, { lightness: 29 }, { weight: 0.2 }] },
    { featureType: 'road.arterial', elementType: 'geometry', stylers: [{ color: '#ffffff' }, { lightness: 18 }] },
    { featureType: 'road.local', elementType: 'geometry', stylers: [{ color: '#ffffff' }, { lightness: 16 }] },
    { featureType: 'poi', elementType: 'geometry', stylers: [{ color: '#f5f5f5' }, { lightness: 21 }] },
    { featureType: 'poi.park', elementType: 'geometry', stylers: [{ color: '#dedede' }, { lightness: 21 }] },
    { elementType: 'labels.text.stroke', stylers: [{ visibility: 'on' }, { color: '#ffffff' }, { lightness: 16 }] },
    { elementType: 'labels.text.fill', stylers: [{ saturation: 36 }, { color: '#333333' }, { lightness: 40 }] },
    { elementType: 'labels.icon', stylers: [{ visibility: 'off' }] },
    { featureType: 'transit', elementType: 'geometry', stylers: [{ color: '#f2f2f2' }, { lightness: 19 }] },
    { featureType: 'administrative', elementType: 'geometry.fill', stylers: [{ color: '#fefefe' }, { lightness: 20 }] },
    { featureType: 'administrative', elementType: 'geometry.stroke', stylers: [{ color: '#fefefe' }, { lightness: 17 }, { weight: 1.2 }] },
];

export const renderDiff = (event) => {
    if (event?.startDate) {
        const dateToCheck = moment(event?.startDate);
        const today = moment();

        if (event?.startTime) {
            dateToCheck.hour(event?.startTime.slice(0, 2)); // Sets the hour to 15 (3 PM)
            dateToCheck.minute(event?.startTime.slice(3, 5));
        }

        const isBeforeToday = dateToCheck.isBefore(today);

        const totalSeconds = dateToCheck.diff(today, 'seconds'); // Total difference in seconds

        const days = Math.floor(totalSeconds / (24 * 60 * 60)); // Calculate full days
        const remainingSecondsAfterDays = totalSeconds % (24 * 60 * 60);

        const hours = Math.floor(remainingSecondsAfterDays / (60 * 60)); // Calculate full hours
        const remainingSecondsAfterHours = remainingSecondsAfterDays % (60 * 60);

        const minutes = Math.floor(remainingSecondsAfterHours / 60); // Calculate full minutes
        const seconds = remainingSecondsAfterHours % 60; // Rem

        return { days, hours, minutes, seconds, isBeforeToday };
    } else {
        return { isBeforeToday: true };
    }
};

export const handleGetTicketInfo = (eventTickets) => {
    let minPrice = Infinity;
    let maxPrice = -Infinity;
    let left = 0;

    eventTickets?.tickets?.forEach((item) => {
        if (item?.capacity) {
            left += item?.capacity - item?.soldOut;
        }
        if (item?.price < minPrice) {
            minPrice = item?.price;
        }
        if (item?.price > maxPrice) {
            maxPrice = item?.price;
        }
    });

    return { minPrice, maxPrice, left };
};

export const eventsYouLikeResponse = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
    },
    tablet: {
        breakpoint: { max: 1024, min: 768 },
        items: 2.3,
    },
    mobile: {
        breakpoint: { max: 768, min: 0 },
        items: 1,
    },
};
export let eventYouLikeSettings = {
    infinite: true,
    button: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
        {
            breakpoint: 1440,
            settings: {
                slidesToShow: 2.5,
                slidesToScroll: 2.5,
            },
        },
        {
            breakpoint: 1280,
            settings: {
                slidesToShow: 2.2,
                slidesToScroll: 2.2,
                arrows: false,
            },
        },
        {
            breakpoint: 1000,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                arrows: false,
            },
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 1.2,
                slidesToScroll: 1.2,
                arrows: false,
            },
        },
        {
            breakpoint: 450,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false,
            },
        },
    ],
};
