import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { Svg } from 'assets';
import { ButtonMain, CancelBtnModal, InputMain } from 'components';
import { EmailValidator, FindError, FindSuccess, useModal } from 'utils';
import { authActions, httpRequestsOnErrorsActions, httpRequestsOnSuccessActions } from 'store';
import { InviteSuccess } from './inviteSuccess';

const REQUEST_TYPE = 'INVITE_USER';
export const Invite = () => {
    const dispatch = useDispatch();
    const success = FindSuccess(REQUEST_TYPE);
    const beckError = FindError(REQUEST_TYPE);
    const { close, openModal } = useModal();
    const { handleSubmit, control, setError } = useForm({
        defaultValues: {
            email: '',
            password: '',
        },
    });

    useEffect(() => {
        if (success?.type) {
            dispatch(httpRequestsOnSuccessActions.removeSuccess(REQUEST_TYPE));
            close();
            openModal(<InviteSuccess />);
        }
    }, [success]);

    useEffect(() => {
        if (beckError?.error) {
            setError('email', {
                type: 'required',
                message: 'User already exists',
            });
            dispatch(httpRequestsOnErrorsActions.removeError(REQUEST_TYPE));
        }
    }, [beckError]);

    const onSubmit = (data) => {
        dispatch(authActions.inviteUser({ email: data?.email, role: 'MEMBER' }));
    };

    return (
        <div className="invite-modal">
            <div style={{ marginBottom: '16px' }}>
                <Svg.Invite />
            </div>

            <p className="auth-modal-title">Invite People</p>
            <p className="invite-modal-des" style={{ maxWidth: '412px' }}>
                Invite your friends and family to join Armat and become verified members to create events!
            </p>
            <form className="form-box" onSubmit={handleSubmit(onSubmit)}>
                <InputMain
                    name="email"
                    type="email"
                    control={control}
                    placeholder="Email"
                    labelHeader="Email Address"
                    rules={{
                        required: 'This Field is required',
                        pattern: {
                            value: EmailValidator,
                            message: 'Must be valid email',
                        },
                    }}
                    startAdornment={<Svg.Sms />}
                />
                <div style={{ display: 'flex', gap: '16px' }}>
                    <CancelBtnModal text="Cancel" />
                    <ButtonMain actionTye={REQUEST_TYPE} text="Send Invite" type="submit" />
                </div>
            </form>
        </div>
    );
};
