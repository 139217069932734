import React, { useEffect } from 'react';
import { useLocation } from 'react-router';
import { useDispatch } from 'react-redux';
import { FindLoad, token, useModal, useWindowSize } from 'utils';
import { organizationActions } from 'store';
import { ButtonMain, PageLoader } from 'components';
import { CreateOrganization, LogIn, ORG_TYPES } from 'fragments';
import { LOAD_ORGANIZATIONS, OrganizationFilters, OrganizationListTypes, OrganizationList, OrganizationMap } from './fragments';
import { Svg } from 'assets';

export const Organizations = ({ type }) => {
    const dispatch = useDispatch();
    const loader = FindLoad(LOAD_ORGANIZATIONS);
    const { openModal } = useModal();
    const location = useLocation();
    const info = location?.state;
    const checkDefaultList = info?.listType ? info?.listType === OrganizationListTypes?.LIST : true;
    const [width] = useWindowSize();

    useEffect(() => {
        const params = {
            ...info,
            type: type,
            limit: 10,
        };

        delete params.page;
        // delete params.orgTypes;
        dispatch(organizationActions.getOrg(params));
    }, [dispatch, type, info]);

    useEffect(() => {
        dispatch(organizationActions.getOrgCategories());
    }, [dispatch]);

    const addListing = () => {
        if (token) {
            openModal(<CreateOrganization type={type} />);
        } else {
            openModal(<LogIn />);
        }
    };

    const renderTile = () => {
        if (type === ORG_TYPES?.BUSINESS) {
            return 'Businesses';
        } else {
            if (width < 768) {
                return 'Organizations';
            } else {
                return 'Discover Armenian Organizations';
            }
        }
    };

    return (
        <div className="organizations-page">
            <div className="organizations-box">
                <div className="space-between" style={{ gap: '12px' }}>
                    <p className="page-global-title">{renderTile()}</p>
                    <ButtonMain
                        onClick={addListing}
                        text={
                            <div className="add-button-global-content">
                                <Svg.PlusSvg />
                                <p>Add a listing</p>
                            </div>
                        }
                    />
                </div>
                <OrganizationFilters type={type} />
            </div>

            {loader ? (
                <PageLoader height={'60vh'} />
            ) : (
                <>
                    {checkDefaultList && (
                        <div className="organizations-list-box">
                            <OrganizationList />
                        </div>
                    )}
                    {info?.listType === OrganizationListTypes?.MAP && <OrganizationMap />}
                </>
            )}
        </div>
    );
};
