import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { PageLoader } from 'components';
import { FindLoad } from 'utils';
import { CardInformation, InvoicesMobile } from './fragments';
import { Invoices } from './fragments/invoices';
import { paymentActions } from 'store';

const ACTION_TYPE = 'GET_CURRENT_CARD';
export const Billing = () => {
    const loader = FindLoad(ACTION_TYPE);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(paymentActions.getCurrentCard());
        dispatch(paymentActions.getInvoices());
    }, [dispatch]);

    if (loader) {
        return <PageLoader />;
    }

    return (
        <div className="billing-page">
            <p className="page-global-title">Billing Info</p>
            <div className="card-information-section">
                <CardInformation />
                <div className="tablet-and-desktop-view">
                    <Invoices />
                </div>
            </div>
            <div className="mobile-view">
                <InvoicesMobile />
            </div>
        </div>
    );
};
