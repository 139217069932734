export const TypedButtons = ({ list, setSelected, selected }) => {
    return (
        <div>
            <div className="typed-buttons-box">
                {list?.map((i, j) => (
                    <button key={j} className={selected === i?.value ? 'active-btn' : 'passive-btn'} onClick={() => setSelected(i?.value)}>
                        {i?.icon}
                    </button>
                ))}
            </div>
        </div>
    );
};
