import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { ButtonMain, InputMain } from 'components';
import { EmailValidator, FindError, FindSuccess, useModal } from 'utils';
import { authActions, httpRequestsOnErrorsActions, httpRequestsOnSuccessActions } from 'store';
import { ForgotPassword } from './forgotPassword';
import { Svg } from 'assets';
import { SignUp } from './signUp';

const REQUEST_TYPE = 'LOG_IN';

export const LogIn = () => {
    const [showPassword, setShowPassword] = useState(false);
    const dispatch = useDispatch();
    const { openModal, close } = useModal();
    const backError = FindError(REQUEST_TYPE);
    const successLogin = FindSuccess(REQUEST_TYPE);

    const { handleSubmit, control, setError } = useForm({
        defaultValues: {
            email: '',
            password: '',
        },
    });

    useEffect(() => {
        if (successLogin) {
            close();
            dispatch(httpRequestsOnSuccessActions.removeSuccess(REQUEST_TYPE));
        }
    }, [successLogin]);

    useEffect(() => {
        if (backError?.type === REQUEST_TYPE) {
            if (backError?.error === 'Such user does not exist in our records') {
                setError('email', {
                    type: 'required',
                    message: 'Such user does not exist in our records',
                });
            }
            if (backError?.error === 'User password does not match' || backError?.error === 'user password does not match') {
                setError('password', {
                    type: 'required',
                    message: 'User password does not match',
                });
            }
            dispatch(httpRequestsOnErrorsActions.removeError(REQUEST_TYPE));
        }
    }, [dispatch, backError]);

    const onSubmit = (data) => {
        dispatch(authActions.logIn(data));
    };

    const handleClickIcon = () => {
        setShowPassword((prev) => !prev);
    };

    const openForgotModal = () => {
        openModal(<ForgotPassword />);
    };

    return (
        <div className="login-modal-box">
            <div className="login-svg-block">
                <Svg.LogInSvg />
            </div>

            <h3 className="auth-modal-title">Log in to your account</h3>
            <p className="welcome-back">Welcome back! Please enter your details.</p>
            <form className="form-box" onSubmit={handleSubmit(onSubmit)}>
                <InputMain
                    name="email"
                    type="email"
                    control={control}
                    placeholder="Email"
                    labelHeader="Email Address"
                    rules={{
                        required: 'This Field is required',
                        pattern: {
                            value: EmailValidator,
                            message: 'Must be valid email',
                        },
                    }}
                    startAdornment={<Svg.Sms />}
                />
                <InputMain
                    name="password"
                    type="password"
                    showPassword={showPassword}
                    control={control}
                    placeholder="Password"
                    labelHeader="Password"
                    rules={{
                        required: 'This Field is required',
                    }}
                    onClickIconEnd={handleClickIcon}
                    startAdornment={<Svg.Lock />}
                />
                <ButtonMain actionTye={REQUEST_TYPE} text="Sign In" type="submit" />
            </form>

            <div className="flex-all-center">
                <button onClick={openForgotModal} className="forgot-btn">
                    Forgot Password?
                </button>
            </div>

            <div className="dont-have">
                <span>Don&apos;t have a Armat account?</span>
                <button onClick={() => openModal(<SignUp />)}>Sign Up</button>
            </div>
        </div>
    );
};
