import React from 'react';
import { Svg } from 'assets';
import { ButtonMain } from 'components';
import { useModal } from 'utils';

export const InviteSuccess = () => {
    const { close } = useModal();

    return (
        <div className="invite-success-modal">
            <div className="login-svg-block">
                <Svg.InviteSuccessSvg />
            </div>

            <h3 className="auth-modal-title">Invitation sent successfully!</h3>

            <p className="auth-modal-description" style={{ maxWidth: '412px' }}>
                Your invitation have been successfully sent! Thank you for helping to grow the Armat community .
            </p>

            <ButtonMain text="Close" onClick={close} />
        </div>
    );
};
