import React from 'react';
import { Svg } from 'assets';

export const TagsCards = (
    {
        tagsList,
        selectedTags,
        handleAddCategory,
        allowShowButton = false,
        onClickShowButton,
        buttonText,
    }) => {
    return (
        !!tagsList.length && (
            <div className='tag-box-item'>
                {tagsList.map((i, index) => (
                    <button
                        className={selectedTags?.includes(`${i?.id}`) ? 'active-tag' : 'inactive-tag'}
                        key={index}
                        onClick={() => handleAddCategory(`${i?.id}`)}>
                        {!selectedTags?.includes(`${i?.id}`) && <Svg.Add className='add-svg' />}
                        <p>
                            {i?.Icon}
                            {i?.title}
                        </p>
                        {selectedTags?.includes(`${i?.id}`) && <Svg.Close className='close-svg' />}
                    </button>
                ))}

                {allowShowButton &&
                    <button onClick={onClickShowButton} className='show-all-interests'>
                        <p>{buttonText || 'Show all'}</p>
                        <Svg.ArrowUpRight />
                    </button>
                }

            </div>
        )
    );
};

