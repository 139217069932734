import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { Routers } from './root';
import { Layout, Footer, ResetPassword } from './fragments';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-multi-carousel/lib/styles.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useModal } from './utils';

function App() {
    const { pathname, location } = useLocation();
    const query = new URLSearchParams(location?.search);
    const [resetToken, setResetToken] = useState('');
    const resetTokenQuery = query.get('resetToken');
    const navigate = useNavigate();
    const { openModal } = useModal();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    useEffect(() => {
        if (resetToken) {
            openModal(<ResetPassword token={resetToken} />);
        }
    }, [resetToken]);

    useEffect(() => {
        if (resetTokenQuery) {
            setResetToken(resetTokenQuery);
            navigate('/');
        }
    }, [resetTokenQuery]);

    return (
        <div className="app-box">
            <Layout />
            <Routers />
            <Footer />

            <ToastContainer position="bottom-right" />
        </div>
    );
}

export default App;
