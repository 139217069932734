/** Combined Sagas */

import { fork } from 'redux-saga/effects';
import { watchAuth } from 'store/auth';
import { watchOrders } from '../orders';
import { watchEvents } from '../events';
import { watchOrg } from '../organizations';
import { watchPayments } from '../payments';
import { watchTickets } from '../tickets';
import { watchNotifications } from '../notifications';

export const appSaga = function* startForman() {
    yield fork(watchAuth);
    yield fork(watchOrders);
    yield fork(watchEvents);
    yield fork(watchOrg);
    yield fork(watchPayments);
    yield fork(watchTickets);
    yield fork(watchNotifications);
};
