import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { authActions } from 'store';
import { Svg } from 'assets';
import { TitleAndEdit } from './core';
import { CustomSwitch } from 'components';

export const Notifications = ({ user }) => {
    const dispatch = useDispatch();
    const [userNotifications, setUserNotifications] = useState({
        allowEmail: user?.settings?.notificationSettings?.allowEmail,
        allowInApp: user?.settings?.notificationSettings?.allowInApp,
        allowText: user?.settings?.notificationSettings?.allowText,
    });

    const handleSwitch = (ev) => {
        const params = {
            ...userNotifications,
        };
        params[ev.target.name] = ev.target.checked;
        setUserNotifications(params);

        const sendData = {
            ...user,
        };
        sendData.settings.notificationSettings = { ...params };
        dispatch(authActions.editAccount({ ...sendData }));
    };

    return (
        <div className="user-info-inputs-block">
            <TitleAndEdit
                disabled={!(userNotifications.allowInApp || userNotifications.allowText || userNotifications.allowEmail)}
                title={'Notifications'}
                actionBtns={true}
                icon={<Svg.NotificationIcon />}
                desc={'Here you can view and manage the types of notifications you’ll receive.'}
            />

            <div className="user-info-inputs-wrapper">
                <p className="edit-info-desc-mobile">Here you can view and manage the types of notifications you’ll receive.</p>

                <div className="user-password-input-box">
                    <div className="input-info notification-title-svg">
                        <Svg.InAppNotification />
                        <p>In-app notifications</p>
                    </div>

                    <CustomSwitch handleSwitch={handleSwitch} name="allowInApp" checked={userNotifications.allowInApp} />
                </div>
                <div className="user-password-input-box">
                    <div className="input-info notification-title-svg">
                        <Svg.NotificationStatus />
                        <p>Text notifications</p>
                    </div>
                    <CustomSwitch handleSwitch={handleSwitch} name="allowText" checked={userNotifications.allowText} />
                </div>
                <div className="user-password-input-box">
                    <div className="input-info notification-title-svg">
                        <Svg.SmsNotification />
                        <p>Email Notifications</p>
                    </div>
                    <CustomSwitch handleSwitch={handleSwitch} name="allowEmail" checked={userNotifications.allowEmail} />
                </div>
            </div>
        </div>
    );
};
