import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { useParams } from 'react-router';
import Carousel from 'react-multi-carousel';
import { EventGridCard } from 'fragments';
import { Svg } from 'assets';
import { orgEventsLideSettings } from './constants';

export const OrgEvents = () => {
    const [, setIsAtStart] = useState(true);
    const [, setIsAtEnd] = useState(false);
    const orgEvents = useSelector((state) => state.orgs.orgEvents);
    const params = useParams();
    const carouselRef = useRef(null);




    const handleOnChange = (previousSlide, { currentSlide, slidesToShow, totalItems }) => {
        setIsAtStart(currentSlide === 0);
        setIsAtEnd(currentSlide + slidesToShow >= totalItems);
    };

    return (
        <>
            {!!orgEvents?.events?.length && (
                <div className='org-events-box'>
                    <div className='org-sections-box-mb-0'>
                        <p className='org-detail-sections-title'>Events</p>
                        <NavLink to={`/organization/${params?.id}/events`}>
                            See All
                            <Svg.ArrowUpRight />
                        </NavLink>
                    </div>
                        <div className='org-slider-container'>
                            <Carousel
                                ref={carouselRef}
                                arrows={false}
                                responsive={orgEventsLideSettings}
                                afterChange={handleOnChange}
                            >
                                {orgEvents?.events?.map((item, j) => (
                                    <div key={j}>
                                        <EventGridCard
                                            slide={true}
                                            boxShadow='0px -8px 80px 0px rgba(0, 0, 0, 0.07), 0px -2.92px 29.201px 0px rgba(0, 0, 0, 0.05), 0px -1.418px 14.177px 0px rgba(0, 0, 0, 0.04), 0px -0.695px 6.95px 0px rgba(0, 0, 0, 0.03), 0px -0.275px 2.748px 0px rgba(0, 0, 0, 0.02)'
                                            event={item}
                                            detailBtn={true}
                                            containerClass='carousel-container'
                                            itemClass='carousel-item'
                                        />
                                    </div>
                                ))}
                            </Carousel>
                        </div>
                </div>
            )}
        </>
    );
};
