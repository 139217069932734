import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { Drawer } from '@mui/material';
import { RenderScroll, token, useModal, userInfo, useWindowDimensions } from 'utils';
import { Svg } from 'assets';
import { Links, MobileLinks } from './constants';
import { authActions } from 'store';
import { Invite, LogIn, SignUp } from 'fragments';
import { NotificationBox } from './notificationBox';

export const LinksBoxMobile = ({ showSearch, toggleSearch, openingMenu, toggleMenu }) => {
    const { myProfile } = useSelector((state) => ({
        myProfile: state.auth.myProfile,
    }));
    const user = myProfile || userInfo;
    const { openModal } = useModal();
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const { width } = useWindowDimensions();
    const scrollPos = RenderScroll();

    useEffect(() => {
        if (width > 1279 && openingMenu) {
            resetStates();
        }
    }, [width]);

    const handleNavigate = (info) => {
        toggleMenu();

        if (info?.clickFn) {
            if (info?.clickFn === 'invite') {
                openModal(<Invite />);
            }
            if (info?.clickFn === 'logOut') {
                dispatch(authActions.logOut());
            }
        } else {
            navigate(info?.link);
        }
    };

    const signOut = () => {
        dispatch(authActions.logOut());
    };

    const resetStates = () => {
        toggleMenu();
    };

    return (
        <div className="links-box-mobile">
            <div className="notification-box-mobile">
                {scrollPos > 100 && (
                    <button onClick={toggleSearch} className={`show-search-btn ${showSearch ? 'show-search-btn-show' : ''}`}>
                        <Svg.Search />
                    </button>
                )}

                {user && token && <NotificationBox />}

                <button className="burger-btn" onClick={toggleMenu}>
                    {openingMenu ? <Svg.CloseBurger /> : <Svg.BurgerMenu />}
                </button>
            </div>

            <Drawer
                sx={{
                    top: 80,
                    zIndex: 80,
                    width: '100%',
                }}
                transitionDuration={110}
                className="header-menu-drawer"
                anchor="right"
                open={openingMenu}
                onClose={toggleMenu}>
                <div className="links-mobile-box">
                    <div>
                        <div className="links-mobile-box-wrapper">
                            {MobileLinks?.map((i) => (
                                <button
                                    className={`mobile-link-row ${i.link === location.pathname ? 'mobile-link-row-active' : ''}`}
                                    key={i?.link}
                                    onClick={() => handleNavigate(i)}>
                                    {i?.icon}
                                    {i?.name}
                                </button>
                            ))}
                        </div>

                        <div className={`mobile-navigations-wrapper`}>
                            {user && token ? (
                                <div>
                                    <div className="mobile-navigation-bar">
                                        {Links?.map((i) => (
                                            <button
                                                className={`${i?.link === location?.pathname ? 'mobile-navigation-bar-link' : ''}`}
                                                key={i?.link}
                                                onClick={() => handleNavigate(i)}>
                                                {i?.icon}
                                                {i?.name}
                                            </button>
                                        ))}
                                    </div>

                                    <div className="user-info-mobile-wrapper">
                                        <div className="user-info-mobile-box">
                                            <div className="user-info-mobile-icon-block">
                                                <Svg.User />
                                            </div>

                                            <p className="user-info-mobile-full-name">{user?.fullName}</p>
                                        </div>

                                        <button className="sign-out" onClick={signOut}>
                                            <Svg.SignOut />
                                        </button>
                                    </div>
                                </div>
                            ) : (
                                <div className={`mobile-auth-buttons-box  ${!user ? 'mobile-auth-buttons-box-no-user' : ''}`}>
                                    <button
                                        className="sign-in"
                                        onClick={() => {
                                            openModal(<LogIn />);
                                            resetStates();
                                        }}>
                                        Log In
                                    </button>
                                    <button
                                        className="sign-up"
                                        onClick={() => {
                                            openModal(<SignUp />);
                                            resetStates();
                                        }}>
                                        Sign Up
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </Drawer>
        </div>
    );
};
