import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Svg } from 'assets';
import { ORG_TYPES, OrgList } from 'fragments';
import { useDispatch, useSelector } from 'react-redux';
import { organizationActions } from 'store';
import Button from '@mui/material/Button';

export const BusinessNearYou = () => {
    const dispatch = useDispatch();
    const { organizations } = useSelector((state) => state.orgs);
    const [selectedTag, setSelectedTag] = useState('View all');
    const catList = ['View all', 'Food', 'Health', 'Education'];

    useEffect(() => {
        const params = {
            limit: 10,
            type: ORG_TYPES.NON_PROFIT,
        };
        dispatch(organizationActions.getOrg(params));
    }, [dispatch]);

    return (
        <div className='business-near-you-wrapper'>
            <div className="business-near-you container">
                <div className="business-near-you-info">
                    <p className="business-near-you-title">
                        Discover Popular Businesses Near You
                    </p>

                    <p className="business-near-you-desc">
                        Discover and support non-profit organizations dedicated to making a difference in the Armenian community.
                    </p>
                    <div>
                        <div className="view-all-btn desktop-view">
                            <NavLink to={'/businesses'}>
                                Browse All Organizations
                                <Svg.ArrowUpRight />
                            </NavLink>
                        </div>
                    </div>
                </div>

                <div className="business-near-you-cards">
                    <div className="business-near-you-categories">
                        <div className="tag-tabs-box">
                            {catList?.map((i, j) => (
                                <Button key={j} onClick={() => setSelectedTag(i)} className={selectedTag === i ? 'active-tab' : 'passive-tab'}>
                                    {i}
                                </Button>
                            ))}
                        </div>
                    </div>
                    <div className="business-near-you-organizations">
                        {organizations?.orgs?.map((i, j) => (
                            <React.Fragment key={j}>
                                <OrgList viwButton='mapView' org={i} />
                            </React.Fragment>
                        ))}
                    </div>
                </div>

                <div className="flex-end mobile-view">
                    <div className="view-all-btn mobile-view">
                        <NavLink to={'/businesses'}>
                            Browse All Organizations
                            <Svg.ArrowUpRight />
                        </NavLink>
                    </div>
                </div>
            </div>
        </div>
    );
};
