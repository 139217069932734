import { Svg } from 'assets';

export const TabEnums = {
    GENERAL: 'GENERAL',
    PRIVACY: 'PRIVACY',
    NOTIFICATIONS: 'NOTIFICATIONS',
    ACCOUNT: 'ACCOUNT',
};
export const ButtonsTab = [
    { name: 'General Info', icon: <Svg.Setting />, value: TabEnums.GENERAL },
    { name: 'Privacy', icon: <Svg.Privacy />, value: TabEnums.PRIVACY },
    { name: 'Notifications', icon: <Svg.NotificationIcon />, value: TabEnums.NOTIFICATIONS },
    { name: 'Account Deletion', icon: <Svg.ProfileDelete />, value: TabEnums.ACCOUNT },
];

export const DELETE_ACCOUNT = 'DELETE_ACCOUNT_REQUEST';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD_REQUEST';
export const EDIT_ACCOUNT = 'EDIT_MY_ACCOUNT';
export const CHANGE_NOTIFICATIONS = '';
