import { Svg } from 'assets';
import { ReactComponent as CongratsMemberSvg } from '../../../../assets/images/svg/notifications/CongratsMemberSvg.svg';

export const RenderImage = ({ type, className }) => {
    return (
        <div className={className}>
            {type === 'EVENT_APPROVED'
                ? <Svg.NotificationDone />
                : type === 'EVENT_DISAPPROVED'
                    ? <Svg.NoteDisapprovedSvg />
                    : type === 'CLAIM_APPORVED'
                        ? <Svg.NotificationDone />
                        : type === 'CLAIM_REJECTED'
                            ? <Svg.NoteDisapprovedSvg />
                            : type === 'UPGRADE_VERIFITED'
                                ? <Svg.CongratsOrgSvg />
                                : type === 'UPGRADE_ORGANIZATION_ADMIN'
                                    ? <Svg.CongratsOrgSvg />
                                    : type === 'UPGRADE_ORGANIZER'
                                        ? <Svg.CongratsOrgSvg />
                                        : type === 'UPGRADE_ORGANIZATION_ORGANIZER'
                                            ? <Svg.CongratsOrgSvg />
                                            : type === 'DOWNGRADE_ORGANIZATION_MANAGER'
                                                ? <Svg.CongratsOrgSvg />
                                                : type === 'UPGRADE_ORGANIZATION_MEMBER'
                                                    ? <Svg.CongratsOrgSvg />
                                                    : type === 'UPGRADE_ORGANIZATION_MANAGER'
                                                        ? <Svg.CongratsOrgSvg />
                                                        : type === 'DOWNGRADE_MEMBER'
                                                            ? <Svg.CongratsMemberSvg />
                                                            : type === 'DOWNGRADE_ORGANIZATION_MEMBER'
                                                                ? <Svg.CongratsMemberSvg />
                                                                : type === 38
                                                                    ? <Svg.NotificationDone />
                                                                    : type === 82
                                                                        ? <Svg.NotificationDone />
                                                                        : <Svg.NotificationDone />}
        </div>
    );
};
