/**Events types */

export const GET_EVENT_TICKETS = 'GET_EVENT_TICKETS';
export const GET_EVENT_TICKETS_SUCCESS = 'GET_EVENT_TICKETS_SUCCESS';

export const GET_MY_TICKETS = 'GET_MY_TICKETS';
export const GET_MY_TICKETS_SUCCESS = 'GET_MY_TICKETS_SUCCESS';

export const BUY_TICKET = 'BUY_TICKET';
export const BUY_TICKET_NO_TOKEN = 'BUY_TICKET_NO_TOKEN';

export const REMOVE_TOTAL_AMOUNT = 'REMOVE_TOTAL_AMOUNT';
export const SAVE_TOTAL_AMOUNT = 'SAVE_TOTAL_AMOUNT';
