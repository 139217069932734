import * as React from 'react';
import Box from '@mui/material/Box';
import Popper from '@mui/material/Popper';
import { Svg } from 'assets';

export const CustomSelect = ({ noShowBack, selectIcon, label, body, active, removeBtn }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;

    const handleClick = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    return (
        <div>
            <div className={active ? 'custom-select-btn-active-box' : 'custom-select-btn-box'}>
                <button aria-describedby={id} type="button" onClick={handleClick}>
                    {selectIcon && selectIcon}
                    <p>{label}</p>

                    <Svg.ArrowDownSelect
                        className="arrow-down-select-svg"
                        style={anchorEl ? { transform: 'rotate(-180deg)', transition: 'all .3s' } : {}}
                    />
                </button>
                {removeBtn && active && (
                    <button onClick={removeBtn}>
                        <Svg.RemoveWhite className="remove-white-select" />
                    </button>
                )}
            </div>

            <Popper
                id={id}
                open={open}
                anchorEl={anchorEl}
                className={!noShowBack ? 'custom-select-paper-box' : 'custom-select-paper-box-custom'}
                placement="bottom-start"
                style={{ position: 'absolute', zIndex: 20 }}>
                <Box>{body}</Box>
            </Popper>

            {open && <div className="beck-drop" onClick={() => setAnchorEl(null)} />}
        </div>
    );
};
