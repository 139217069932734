import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { ButtonMain, InputMain } from 'components';
import { LogIn } from './logIn';
import { CheckEmail } from './checkEmail';
import { toast } from 'react-toastify';
import { authActions, httpRequestsOnSuccessActions } from 'store';
import { EmailValidator, FindError, FindSuccess, useModal } from 'utils';
import { Svg } from 'assets';

const ACTION_TYPE = 'GET_RECOVERY_LINK';

export const ForgotPassword = () => {
    const dispatch = useDispatch();
    const { openModal, close } = useModal();
    const recoverySuccess = FindSuccess(ACTION_TYPE);
    const recoveryError = FindError(ACTION_TYPE);

    const { handleSubmit, control, setError, watch } = useForm({
        defaultValues: {
            email: '',
        },
    });

    useEffect(() => {
        if (recoverySuccess) {
            dispatch(httpRequestsOnSuccessActions.removeSuccess(ACTION_TYPE));
            close();
            openModal(<CheckEmail email={watch('email', false)} />);
            toast.success('Recovery link sent to your email');
        }
    }, [recoverySuccess]);

    useEffect(() => {
        if (recoveryError) {
            setError('email', {
                type: 'required',
                message: recoveryError?.error,
            });
        }
    }, [recoveryError]);

    const onSubmit = (data) => {
        dispatch(authActions.getRecoveryLink(data.email));
    };

    return (
        <div className="forgot-modal-box">
            <div className="login-svg-block">
                <Svg.ForgotSvg />
            </div>

            <h3 className="auth-modal-title">Forgot Your Password?</h3>
            <p className="forgot-modal-desc">No worries, enter your email address and we’ll send you reset instructions.</p>

            <form className="form-box-forgot" onSubmit={handleSubmit(onSubmit)}>
                <InputMain
                    name="email"
                    type="email"
                    control={control}
                    placeholder="Email"
                    labelHeader="Email Address"
                    rules={{
                        required: 'This Field is required',
                        pattern: {
                            value: EmailValidator,
                            message: 'Must be valid email',
                        },
                    }}
                    startAdornment={<Svg.Sms />}
                />

                <ButtonMain text="Get Recovery Email" type="submit" actionTye={ACTION_TYPE} />
            </form>

            <button className="beck-to-login-btn" onClick={() => openModal(<LogIn />)}>
                <Svg.ArrowLeftLineSvg />
                <span>Back to log In</span>
            </button>
        </div>
    );
};
