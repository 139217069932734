/**Payment types */

export const ASSIGN_CARD = 'ASSIGN_CARD';
export const UN_ASSIGN_CARD = 'UN_ASSIGN_CARD';
export const UN_ASSIGN_CARD_SUCCESS = 'UN_ASSIGN_CARD_SUCCESS';

export const GET_CURRENT_CARD = 'GET_CURRENT_CARD';
export const GET_CURRENT_CARD_SUCCESS = 'GET_CURRENT_CARD_SUCCESS';

export const GET_PAYMENT_INVOICES = 'GET_PAYMENT_INVOICES';
export const GET_PAYMENT_INVOICES_SUCCESS = 'GET_PAYMENT_INVOICES_SUCCESS';
