import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ButtonMain, CancelBtn } from 'components';
import { organizationActions } from 'store';
import { Svg } from 'assets';
import { EDIT_ORG_TYPE } from './constants';

const socialMedia = [
    { icon: Svg.OrgWWW, name: 'website', placeholder: 'Add Website URL' },
    { icon: Svg.OrgFacebook, name: 'facebook', placeholder: 'Add Facebook URL' },
    { icon: Svg.OrgInstagram, name: 'instagram', placeholder: 'Add Instagram URL' },
    { icon: Svg.OrgTwitter, name: 'twitter', placeholder: 'Add Twitter URL' },
    { icon: Svg.OrgYoutube, name: 'youtube', placeholder: 'Add Youtube URL' },
    { icon: Svg.OrgGoogle, name: 'google', placeholder: 'Add Google URL' },
    { icon: Svg.OrgYelp, name: 'yelp', placeholder: 'Add Yelp URL' },
];

export const OrgContacts = () => {
    const { orgSocials, org } = useSelector((state) => ({
        orgSocials: state.orgs.orgSocials,
        org: state.orgs.organizationsById,
    }));
    const dispatch = useDispatch();
    const [inputs, setInputs] = useState({});

    useEffect(() => {
        if (orgSocials) {
            const socialInfo = {};
            orgSocials?.facebookLink ? (socialInfo.facebook = orgSocials?.facebookLink) : delete socialInfo.facebook;
            orgSocials?.instagramLink ? (socialInfo.instagram = orgSocials?.instagramLink) : delete socialInfo.instagram;
            orgSocials?.twitterLink ? (socialInfo.twitter = orgSocials?.twitterLink) : delete socialInfo.twitter;
            orgSocials?.youtubeLink ? (socialInfo.youtube = orgSocials?.youtubeLink) : delete socialInfo.youtube;
            orgSocials?.googleLink ? (socialInfo.google = orgSocials?.googleLink) : delete socialInfo.google;
            orgSocials?.yelpLink ? (socialInfo.yelp = orgSocials?.yelpLink) : delete socialInfo.yelp;
            setInputs(socialInfo);
        }
    }, [orgSocials]);

    const handleChange = (e) => {
        setInputs((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
    };

    const handleDelete = (name) => {
        const newInputs = {
            ...inputs,
        };
        newInputs[name] = null;
        setInputs(newInputs);
    };

    const handleSave = () => {
        const formData = {
            phoneNumber: org?.phoneNumber,
            email: org?.email,
            type: org?.type,
            name: org?.name,
            address: org?.address?.formattedAddress,
            ...inputs,
        };
        dispatch(organizationActions.editOrg(org?.id, formData));
    };

    return (
        <>
            <div>
                <div className="subtitle-box">
                    <h3 className="subtitle">Social Media Links</h3>
                    <p className="paragraph">Connect the social media profiles of the organization to Armat by simply copying the links.</p>
                </div>



                <div className="reviews-inputs">
                    {socialMedia?.map((i, j) => (
                        <div className="connect-box" key={j}>
                            <div
                                className={`connect-box-icon_input ${inputs?.[i?.name]?.length > 0 ? "connect-box-icon_input-fill" : ""}`}>
                                <div className="social-icon">
                                    <img src={i?.icon} alt="icon" />
                                </div>

                                <input
                                    type={"text"}
                                    className="social-input"
                                    name={i?.name}
                                    placeholder={i?.placeholder}
                                    value={inputs[i?.name]}
                                    onChange={handleChange}
                                />
                            </div>

                            <div className="social-delete">
                                <button type="button" className="btn-delete" onClick={() => handleDelete(i?.name)}>
                                    <Svg.Close />
                                </button>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className="edit-org-btns">
                <CancelBtn />
                <ButtonMain onClick={handleSave} actionTye={EDIT_ORG_TYPE} text="Save" />
            </div>
        </>
    );
};
